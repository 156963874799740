export const DEFAULT_BRAND_LABEL = 'Sézane';

export const BRAND_CODES = {
    SEZANE: 'sezane',
    OCTOBRE: 'octobre',
} as const;

export const DEFAULT_BRAND_CODE = BRAND_CODES.SEZANE;

export const URL_BRANDS = {
    [DEFAULT_BRAND_CODE]: 'sezane',
    [BRAND_CODES.OCTOBRE]: 'octobre-editions',
};

export const getBrandCodeFromLocation = (url: string) => {
    if (url.includes(URL_BRANDS[BRAND_CODES.OCTOBRE])) {
        return BRAND_CODES.OCTOBRE;
    } else {
        return DEFAULT_BRAND_CODE;
    }
};

export const getBrandUrl = (url: string, brandCode: string) =>
    url.replace(
        new RegExp(`${URL_BRANDS[DEFAULT_BRAND_CODE]}|${URL_BRANDS[BRAND_CODES.OCTOBRE]}`),
        URL_BRANDS[brandCode as keyof typeof URL_BRANDS]
    );

export const BRAND_NAMES: Record<string, string> = {
    octobre: 'Octobre',
    sezane: 'Sézane',
};
