import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import routesNames from '@config/routesNames';

const List = lazy(() => import(/* webpackChunkName: "orders.List" */ '@containers/orders/List'));
const Show = lazy(() => import(/* webpackChunkName: "orders.Show" */ '@containers/orders/Show'));
const Reorder = lazy(() => import(/* webpackChunkName: "orders.Reorder" */ '@containers/orders/Reorder'));
const MassOrdersForm = lazy(
    () => import(/* webpackChunkName: "orders.massOrders" */ '@containers/orders/massOrders/Form')
);
const MassOrdersDetail = lazy(
    () => import(/* webpackChunkName: "orders.massOrdersDetail" */ '@containers/orders/massOrders/Detail')
);

export default [
    <Route path={routesNames.MASS_ORDERS_DETAIL} component={MassOrdersDetail} exact={true} key="massOrdersDetail" />,
    <Route path={routesNames.MASS_ORDERS} component={MassOrdersForm} exact={true} key="massOrders" />,
    <Route path={routesNames.REORDER} component={Reorder} exact={true} key="reorder" />,
    <Route path={routesNames.SHOW_ORDER} component={Show} exact={true} key="show" />,
    <Route path={routesNames.LIST_ORDERS} component={List} strict={true} key="list" />,
];
