/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BrandPopinRead {
    id?: number;
    code?: string;
}

export interface CatalogVariantPopinRead {
    id?: number | null;
    code?: string;
    translations?: Record<string, CatalogVariantTranslationPopinRead>;
    workspaceIds?: number[];
}

export interface CatalogVariantPopinWrite {
    id?: number | null;
}

export interface CatalogVariantTranslationPopinRead {
    id?: number | null;
    locale?: string;
    color?: string | null;
    fullName?: string | null;
    label?: string | null;
}

export interface Category {
    id?: number;
    pimWrapper?: Wrapper | null;
    pimUuid?: string | null;
}

export interface CategoryCatalogItemRead {
    pimUuid?: string | null;
}

export interface CategoryCatalogProductRead {
    id?: number;
    pimWrapper?: WrapperCatalogProductRead | null;
}

export type Collection = object;

export type CollectionMenuItemList = object;

export type CollectionMobileContentList = object;

export interface ColorPopinRead {
    id?: number;
}

export interface ColorPopinWrite {
    id?: number;
}

export interface ColorParameter {
    id?: number;
    value?: string | null;
    /**
     * @default true
     * @example true
     */
    canBeModified?: boolean;
}

export interface ColorParameterBlockRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterBlockWrite {
    id?: number;
}

export interface ColorParameterColorParameterRead {
    id?: number;
    value?: string | null;
    /**
     * @default true
     * @example true
     */
    canBeModified?: boolean;
}

export interface ColorParameterColorParameterWrite {
    id?: number;
    value?: string | null;
}

export interface ColorParameterCompositionRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterCompositionWrite {
    id?: number;
}

export interface ColorParameterFooterItemRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterFooterItemWrite {
    id?: number;
}

export interface ColorParameterMenuItemDropdownRead {
    id?: number;
}

export interface ColorParameterMenuItemRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterMenuItemVersionRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterMenuItemVersionWrite {
    id?: number;
}

export interface ColorParameterPageRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterPageVersionRead {
    id?: number;
    value?: string | null;
}

export interface ColorParameterPageVersionWrite {
    id?: number;
}

export interface ColorParameterPageWrite {
    id?: number;
}

export interface ColorParameterPopinRead {
    id?: number;
}

/** Class Composition */
export interface Composition {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    blocks?: Blocks[];
    compositionTemplate: CompositionTemplates | null;
    /**
     * @default "medium"
     * @example "medium"
     */
    margin?: string | null;
    titlePositionInner?: boolean;
    withTwoMargins?: boolean;
    backgroundColor?: ColorParameter | null;
    backgroundColorOverride?: string | null;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameter | null;
    borderColorOverride?: string | null;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    blockOrder?: string[];
    pageVersion: PageVersions | null;
}

/** Class Composition */
export interface CompositionBlockBatchWrite {
    id?: number;
}

/** Class Composition */
export interface CompositionBlockRead {
    id?: number;
}

/** Class Composition */
export interface CompositionBlockWrite {
    id?: number;
}

/** Class Composition */
export interface CompositionCompositionBatchWrite {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    blockOrder?: string[];
}

/** Class Composition */
export interface CompositionCompositionLayoutRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    blocks?: BlocksCompositionLayoutRead[];
    compositionTemplate: CompositionTemplatesCompositionLayoutRead | null;
    /**
     * @default "medium"
     * @example "medium"
     */
    margin?: string | null;
    titlePositionInner?: boolean;
    withTwoMargins?: boolean;
    /** @format iri-reference */
    backgroundColor?: string | null;
    backgroundColorOverride?: string | null;
    borderColorEnabled?: boolean;
    /** @format iri-reference */
    borderColor?: string | null;
    borderColorOverride?: string | null;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    blockOrder?: string[];
}

/** Class Composition */
export interface CompositionCompositionRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    blocks?: BlocksCompositionRead[];
    compositionTemplate: CompositionTemplatesCompositionRead | null;
    /**
     * @default "medium"
     * @example "medium"
     */
    margin?: string | null;
    titlePositionInner?: boolean;
    withTwoMargins?: boolean;
    backgroundColor?: ColorParameterCompositionRead | null;
    backgroundColorOverride?: string | null;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterCompositionRead | null;
    borderColorOverride?: string | null;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    blockOrder?: string[];
    pageVersion: PageVersionsCompositionRead | null;
}

/** Class Composition */
export interface CompositionCompositionWrite {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    blocks?: BlocksCompositionWrite[];
    compositionTemplate: CompositionTemplatesCompositionWrite | null;
    /**
     * @default "medium"
     * @example "medium"
     */
    margin?: string | null;
    titlePositionInner?: boolean;
    withTwoMargins?: boolean;
    backgroundColor?: ColorParameterCompositionWrite | null;
    backgroundColorOverride?: string | null;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterCompositionWrite | null;
    borderColorOverride?: string | null;
    blockOrder?: string[];
    pageVersion: PageVersionsCompositionWrite | null;
}

/** Class Composition */
export interface CompositionPageVersionRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    blocks?: BlocksPageVersionRead[];
    compositionTemplate: CompositionTemplatesPageVersionRead | null;
    /**
     * @default "medium"
     * @example "medium"
     */
    margin?: string | null;
    titlePositionInner?: boolean;
    withTwoMargins?: boolean;
    backgroundColor?: ColorParameterPageVersionRead | null;
    backgroundColorOverride?: string | null;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterPageVersionRead | null;
    borderColorOverride?: string | null;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    blockOrder?: string[];
}

export interface Country {
    id?: number;
    sites?: Site[];
    code?: string;
    callingCode?: string | null;
    standardVat?: string;
    reducedVat?: string;
    override?: boolean | null;
    /** To know how we handle Vat for given Country */
    vatMode?: string;
    currency?: Currency | null;
    outputCurrencyCode?: string;
    translations?: Record<string, CountryTranslation>;
}

export interface CountryCountryRead {
    id?: number;
    sites?: SiteCountryRead[];
    code?: string;
    callingCode?: string | null;
    standardVat?: string;
    reducedVat?: string;
    override?: boolean | null;
    /** To know how we handle Vat for given Country */
    vatMode?: string;
    currency?: CurrencyCountryRead | null;
    outputCurrencyCode?: string;
    translations?: Record<string, CountryTranslationCountryRead>;
}

export interface CountryCountryWrite {
    id?: number;
    standardVat?: string;
    reducedVat?: string;
    override?: boolean | null;
    /** To know how we handle Vat for given Country */
    vatMode?: string;
    currency?: CurrencyCountryWrite | null;
    outputCurrencyCode?: string;
}

export interface CountryPopinRead {
    id?: number | null;
    code?: string;
    translations?: Record<string, CountryTranslationPopinRead>;
}

export interface CountryPopinWrite {
    id?: number | null;
}

export interface CountrySiteRead {
    id?: number;
    code?: string;
    callingCode?: string | null;
    standardVat?: string;
    reducedVat?: string;
    override?: boolean | null;
    /** To know how we handle Vat for given Country */
    vatMode?: string;
    currency?: CurrencySiteRead | null;
    outputCurrencyCode?: string;
    translations?: Record<string, CountryTranslationSiteRead>;
}

export interface CountrySiteWrite {
    id?: number;
    currency?: CurrencySiteWrite | null;
    outputCurrencyCode?: string;
}

export interface CountryTranslation {
    id?: number;
    label?: string;
}

export interface CountryTranslationCountryRead {
    id?: number;
    label?: string;
}

export interface CountryTranslationPopinRead {
    id?: number | null;
    label?: string | null;
}

export interface CountryTranslationSiteRead {
    id?: number;
    label?: string;
}

export interface CrossSellVariantTranslationCrossSellVariantRead {
    id?: number;
    description?: string;
}

export interface Currency {
    code?: string;
    label?: string;
}

export interface CurrencyCountryRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface CurrencyCountryWrite {
    id?: number;
}

export type CurrencyCurrencyRead = object;

export interface CurrencyCustomisationServiceRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface CurrencySiteRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface CurrencySiteWrite {
    id?: number;
    label?: string;
}

export interface CurrencyWorkspaceRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface CustomisationServiceCustomisationServiceList {
    id?: number;
    sites?: SiteCustomisationServiceList[];
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    /**
     * @default true
     * @example true
     */
    optional?: boolean;
    prices: string[] | null;
    type?: string | null;
    format?: string | null;
    /**
     * @default 1
     * @example 1
     */
    linesCount?: number;
    /**
     * @default [{"limit":10}]
     * @example [{"limit":10}]
     */
    textLines?: string[] | null;
    excludedCharactersPresets?: string[] | null;
    whitelistedSpecialCharacters?: string;
    linkedVariants?: string[];
    translations?: Record<string, CustomisationServiceTranslationCustomisationServiceList>;
    colors?: string[] | null;
    positions?: string[] | null;
    symbols?: string[] | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface CustomisationServiceCustomisationServiceRead {
    id?: number;
    sites?: SiteCustomisationServiceRead[];
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    /**
     * @default true
     * @example true
     */
    optional?: boolean;
    prices: string[] | null;
    type?: string | null;
    format?: string | null;
    /**
     * @default 1
     * @example 1
     */
    linesCount?: number;
    /**
     * @default [{"limit":10}]
     * @example [{"limit":10}]
     */
    textLines?: string[] | null;
    excludedCharactersPresets?: string[] | null;
    whitelistedSpecialCharacters?: string;
    linkedVariants?: CustomisationServiceLinkedVariantCustomisationServiceRead[];
    translations?: Record<string, CustomisationServiceTranslationCustomisationServiceRead>;
    colors?: string[] | null;
    positions?: string[] | null;
    symbols?: string[] | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface CustomisationServiceCustomisationServiceWrite {
    sites?: SiteCustomisationServiceWrite[];
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    /**
     * @default true
     * @example true
     */
    optional?: boolean;
    prices: string[] | null;
    type?: string | null;
    format?: string | null;
    /**
     * @default 1
     * @example 1
     */
    linesCount?: number;
    /**
     * @default [{"limit":10}]
     * @example [{"limit":10}]
     */
    textLines?: string[] | null;
    excludedCharactersPresets?: string[] | null;
    whitelistedSpecialCharacters?: string;
    linkedVariants?: CustomisationServiceLinkedVariantCustomisationServiceWrite[];
    translations?: Record<string, CustomisationServiceTranslationCustomisationServiceWrite>;
    colors?: string[] | null;
    positions?: string[] | null;
    symbols?: string[] | null;
}

export interface CustomisationServiceLinkedVariant {
    id?: number;
    /** @format iri-reference */
    variant?: string;
    /** @format iri-reference */
    media?: string | null;
}

export interface CustomisationServiceLinkedVariantCustomisationServiceRead {
    id?: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant?: CatalogVariantsCustomisationServiceRead;
    media?: MediasCustomisationServiceRead | null;
}

export interface CustomisationServiceLinkedVariantCustomisationServiceWrite {
    id?: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant?: CatalogVariantsCustomisationServiceWrite;
    media?: MediasCustomisationServiceWrite | null;
}

export interface CustomisationServiceTranslationCustomisationServiceList {
    id?: number;
    description?: string | null;
    name?: string | null;
}

export interface CustomisationServiceTranslationCustomisationServiceRead {
    id?: number;
    description?: string | null;
    name?: string | null;
}

export interface CustomisationServiceTranslationCustomisationServiceWrite {
    description?: string | null;
    name?: string | null;
}

export interface DashboardWrite {
    /** Component width (in grid units) */
    w: number;
    /** Component height (in grid units) */
    h: number;
    /** Component position x (in grid units) */
    x: number;
    /** Component position y (in grid units) */
    y: number;
    /** Component key */
    i: string;
    moved?: boolean;
    static?: boolean;
}

export interface EntityHistory {
    id?: number;
    admin?: Admin | null;
    action?: string;
    data?: string[] | null;
    objectId?: number | null;
    /** @format date-time */
    createdAt?: string;
}

export interface EntityHistoryEntityHistoryRead {
    id?: number;
    admin?: AdminEntityHistoryRead | null;
    action?: string;
    data?: string[] | null;
    objectId?: number | null;
    /** @format date-time */
    createdAt?: string;
}

export interface FavoriteWrite {
    title: string;
    route: string;
    search?: string | null;
}

export interface Locale {
    id?: number;
    code?: string;
    label?: string;
}

export interface LocaleCustomisationServiceRead {
    id?: number;
    code?: string;
}

export interface LocaleFooterItemRead {
    id?: number;
    code?: string;
}

export interface LocaleLocaleRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface LocaleMobileContentRead {
    id?: number;
    code?: string;
}

export interface LocaleMobileContentVersionRead {
    id?: number;
    code?: string;
}

export interface LocalePreFooterRead {
    id?: number;
    code?: string;
}

export interface LocalePushProductRead {
    id?: number;
    code?: string;
}

export interface LocaleSaleRead {
    id?: number;
    code?: string;
}

export interface LocaleSiteRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface LocaleSiteWrite {
    id?: number;
}

export interface LocaleWorkspaceRead {
    id?: number;
    code?: string;
    label?: string;
}

export interface LogReport {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    treated?: boolean;
    origin?: string;
    message?: string;
    /** Unmapped fields used for easy serialization. */
    sale?: string | null;
    workspace?: string | null;
    path?: string | null;
    preset?: string | null;
    nbErrors?: number | null;
    /** @format date-time */
    finishedAt?: string | null;
}

export interface LogReportLogReportRead {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    treated?: boolean;
    origin?: string;
    message?: string;
    /** Unmapped fields used for easy serialization. */
    sale?: string | null;
    workspace?: string | null;
    path?: string | null;
    preset?: string | null;
    nbErrors?: number | null;
    /** @format date-time */
    finishedAt?: string | null;
}

/** Class LookProductLink */
export interface LookProductLink {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    price?: number | null;
    comingSoon?: boolean;
    /** @format iri-reference */
    variant?: string | null;
}

/** Class LookProductLink */
export interface LookProductLinkBlockRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    price?: number | null;
    comingSoon?: boolean;
    variant?: CatalogVariantsBlockRead | null;
}

/** Class LookProductLink */
export interface LookProductLinkBlockWrite {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    price?: number | null;
    comingSoon?: boolean;
    variant?: CatalogVariantsBlockWrite | null;
}

/** Class LookProductLink */
export interface LookProductLinkCompositionLayoutRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    price?: number | null;
    comingSoon?: boolean;
    /** @format iri-reference */
    variant?: string | null;
}

export interface MandatoryLogin {
    id?: number;
    enabled?: boolean;
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    /** @format iri-reference */
    media: string | null;
    mediaTransformations?: Record<string, string>;
    translations?: Record<string, MandatoryLoginTranslation>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface MandatoryLoginSiteRead {
    id?: number;
    enabled?: boolean;
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    media: MediasSiteRead | null;
    mediaTransformations?: Record<string, string>;
    translations?: Record<string, MandatoryLoginTranslationSiteRead>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface MandatoryLoginTranslation {
    id?: number;
    content?: string | null;
}

export interface MandatoryLoginTranslationSiteRead {
    id?: number;
    content?: string | null;
}

export interface MediaPopinRead {
    id?: number | null;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

export interface MediaPopinWrite {
    id?: number | null;
}

export interface MenuItemDropDownTranslation {
    id?: number;
    text?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface MenuItemDropDownTranslationMenuItemDropdownRead {
    id?: number;
    text?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface MenuItemDropDownTranslationMenuItemRead {
    id?: number;
    text?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface MenuItemDropDownTranslationMenuItemVersionRead {
    id?: number;
    text?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface MenuItemDropDownTranslationMenuItemVersionWrite {
    id?: number;
    text?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

/** A Model (Mannequin) */
export interface Model {
    id?: number;
    displayName?: string;
    media?: Medias | null;
    /** height in centimeters */
    metricHeight?: string;
    /** height in inches */
    imperialHeight?: string;
    sizes?: string[] | null;
    name?: string;
}

/** A Model (Mannequin) */
export interface ModelMediaRead {
    id?: number;
    displayName?: string;
}

/** A Model (Mannequin) */
export interface ModelModelRead {
    id?: number;
    displayName?: string;
    media?: MediasModelRead | null;
    /** height in centimeters */
    metricHeight?: string;
    /** height in inches */
    imperialHeight?: string;
    sizes?: string[] | null;
    name?: string;
}

/** A Model (Mannequin) */
export interface ModelModelWrite {
    id?: number;
    displayName?: string;
    media?: MediasModelWrite | null;
    /** height in centimeters */
    metricHeight?: string;
    /** height in inches */
    imperialHeight?: string;
    sizes?: string[] | null;
    name?: string;
}

export interface MultiProductTranslation {
    id?: number;
    label?: string;
    description?: string | null;
    bulletPoints?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
}

export interface MultiProductTranslationBlockRead {
    id?: number;
    label?: string;
}

export interface MultiProductTranslationCatalogMultiProductList {
    id?: number;
    label?: string;
    description?: string | null;
    bulletPoints?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
}

export interface MultiProductTranslationCatalogMultiProductRead {
    id?: number;
    label?: string;
    description?: string | null;
    bulletPoints?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
}

export interface MultiProductTranslationCatalogMultiProductWrite {
    id?: number;
    label?: string;
    description?: string | null;
    bulletPoints?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
}

export interface MultiProductVariantLink {
    id?: number;
    position: number;
    /** @format iri-reference */
    variant: string;
}

export interface MultiProductVariantLinkBlockRead {
    id?: number;
    position: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant: CatalogVariantsBlockRead;
}

export interface MultiProductVariantLinkCatalogMultiProductList {
    id?: number;
    position: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant: CatalogVariantsCatalogMultiProductList;
}

export interface MultiProductVariantLinkCatalogMultiProductRead {
    id?: number;
    position: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant: CatalogVariantsCatalogMultiProductRead;
}

export interface MultiProductVariantLinkCatalogMultiProductWrite {
    id?: number;
    position: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant: CatalogVariantsCatalogMultiProductWrite;
}

/** , */
export interface Page {
    id?: number | null;
    rubric: string | null;
    pageVersions?: PageVersions[];
    selectedPageVersion?: PageVersions | null;
    nbMenuItems?: any;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageBlockRead {
    id?: number;
    selectedPageVersion?: PageVersionsBlockRead | null;
    fullPath?: string | null;
    brandCode?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageBlockWrite {
    id?: number;
}

export interface PageFooterItemRead {
    id?: number;
    selectedPageVersion?: PageVersionsFooterItemRead | null;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageFooterItemWrite {
    id?: number;
}

export interface PageMenuItemRead {
    id?: number;
    selectedPageVersion?: PageVersionsMenuItemRead | null;
    fullPath?: string | null;
    brandCode?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageMenuItemVersionRead {
    id?: number;
    selectedPageVersion?: PageVersionsMenuItemVersionRead | null;
    fullPath?: string | null;
    brandCode?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageMenuItemVersionWrite {
    id?: number;
}

export interface PageMobileContentRead {
    id?: number;
    selectedPageVersion?: PageVersionsMobileContentRead | null;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageMobileContentVersionRead {
    id?: number;
    selectedPageVersion?: PageVersionsMobileContentVersionRead | null;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PageMobileContentVersionWrite {
    id?: number;
}

export interface PagePageRead {
    id?: number;
    rubric: string | null;
    selectedPageVersion?: PageVersionsPageRead | null;
    nbMenuItems?: any;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PagePageVersionList {
    id?: number;
    parent?: PagePageVersionList | null;
    rubric: string | null;
    nbMenuItems?: any;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PagePageVersionRead {
    id?: number;
    parent?: PagePageVersionRead | null;
    rubric: string | null;
    nbMenuItems?: any;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PagePageVersionWrite {
    id?: number;
    parent?: PagePageVersionWrite | null;
    rubric: string | null;
}

export interface PagePageWrite {
    id?: number;
    rubric: string | null;
    pageVersions?: PageVersionsPageWrite[];
    selectedPageVersion?: PageVersionsPageWrite | null;
}

export interface PagePopinRead {
    id?: number | null;
    fullPath?: string | null;
    brandCode?: string | null;
    selectedPageVersion?: PageVersionPopinRead | null;
}

export interface PagePopinWrite {
    id?: number | null;
}

export interface PagePreFooterRead {
    id?: number;
    selectedPageVersion?: PageVersionsPreFooterRead | null;
    fullPath?: string | null;
    overrideWorkspaceId?: number | null;
    workspaceIds?: string[];
}

export interface PagePreFooterWrite {
    id?: number;
}

export interface PageWorkspaceRead {
    id?: number;
}

export interface PageVersionPopinRead {
    id?: number | null;
    workspacesIds?: number[];
    translations?: Record<string, PageVersionTranslationPopinRead>;
}

export interface PageVersionTranslationPopinRead {
    id?: number | null;
    title?: string | null;
    path?: string | null;
}

export type ParameterParameterRead = object;

export interface PopinTranslationPopinRead {
    id?: number | null;
    title?: string | null;
    content?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface PopinTranslationPopinWrite {
    id?: number | null;
    title?: string | null;
    content?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface PushProductTranslationPushProductRead {
    id?: number;
    name?: string;
    text?: string | null;
}

export interface PushProductTranslationPushProductWrite {
    id?: number;
    name?: string;
    text?: string | null;
}

export interface RegionPopinRead {
    countryCode?: string | null;
    regionCodes?: string[] | null;
}

export interface RegionPopinWrite {
    countryCode?: string | null;
    regionCodes?: string[] | null;
}

export interface ReinsuranceReinsuranceRead {
    id?: number;
    translations?: Record<string, ReinsuranceTranslationsReinsuranceRead>;
}

export interface ReinsuranceSaleRead {
    id?: number;
    translations?: Record<string, ReinsuranceTranslationsSaleRead>;
}

export interface ReinsuranceSaleVersionWrite {
    id?: number;
    translations?: Record<string, ReinsuranceTranslationsSaleVersionWrite>;
}

export interface ReinsuranceSaleWaitingPageRead {
    id?: number;
    translations?: Record<string, ReinsuranceTranslationsSaleWaitingPageRead>;
}

export interface ReinsuranceSaleWaitingPageWrite {
    id?: number;
    translations?: Record<string, ReinsuranceTranslationsSaleWaitingPageWrite>;
}

export interface RetailSale {
    id?: number;
    label?: string;
    type?: string;
    nbItems?: number;
}

export type RetailSaleCatalogItemRead = object;

export interface Sale {
    id?: number;
    selectedSaleVersion?: SaleVersions | null;
    saleVersions?: SaleVersions[];
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SaleCatalogProductRead {
    id?: number;
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SaleCatalogVariantRead {
    id?: number;
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SaleMenuItemAttach {
    id?: number;
}

export interface SaleMenuItemGenerate {
    id?: number;
}

export interface SaleMenuItemRead {
    id?: number;
    saleVersions?: SaleVersionsMenuItemRead[];
}

export interface SaleMenuItemVersionRead {
    id?: number;
    saleVersions?: SaleVersionsMenuItemVersionRead[];
}

export interface SaleMenuItemVersionWrite {
    id?: number;
}

export interface SaleMobileContentRead {
    id?: number;
    saleVersions?: SaleVersionsMobileContentRead[];
}

export interface SaleMobileContentVersionRead {
    id?: number;
    saleVersions?: SaleVersionsMobileContentVersionRead[];
}

export interface SaleMobileContentVersionWrite {
    id?: number;
}

export interface SalePageVersionList {
    id?: number;
    selectedSaleVersion?: SaleVersionsPageVersionList | null;
}

export interface SalePageVersionRead {
    id?: number;
    saleVersions?: SaleVersionsPageVersionRead[];
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SalePageVersionWrite {
    id?: number;
}

export interface SaleSaleList {
    id?: number;
    saleVersions?: SaleVersionsSaleList[];
}

export interface SaleSaleRead {
    id?: number;
    saleVersions?: SaleVersionsSaleRead[];
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SaleSaleVersionList {
    id?: number;
}

export interface SaleSaleVersionRead {
    id?: number;
}

export interface SaleSaleVersionWrite {
    id?: number;
    archive?: boolean;
}

export interface SaleSaleWaitingPageRead {
    id?: number;
}

export interface SaleStoreVariantRead {
    id?: number;
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SaleWorkspaceRead {
    id?: number;
    archive?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface SequencingItemSequencingItemRead {
    id?: number;
    code?: string | null;
    dates?: string[];
}

export interface ShippingCountry {
    id?: number;
    /** @format iri-reference */
    currency?: string;
    /** @format iri-reference */
    country?: string;
    outputCurrencyCode?: string;
}

export interface ShippingCountrySiteRead {
    id?: number;
    currency?: CurrencySiteRead;
    country?: CountrySiteRead;
    outputCurrencyCode?: string;
}

export interface ShippingCountrySiteWrite {
    id?: number;
    currency?: CurrencySiteWrite;
    country?: CountrySiteWrite;
    outputCurrencyCode?: string;
}

export interface Site {
    id?: number;
    code: string;
    label: string;
    backofficeLabel?: string | null;
    /**
     * @default "website"
     * @example "website"
     */
    type?: string;
    defaultLocale: Locale | null;
    availableLocales?: Locale[];
    defaultShippingCountry?: Country | null;
    shippingCountries?: ShippingCountry[];
    availableCurrencies?: Currency[];
    outputCurrencies?: string[];
    ipAddresses?: string[] | null;
    brand: Brands | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    workspacesDates?: string[];
    workspacesIds?: string[];
    mandatoryLogin?: MandatoryLogin | null;
    brandLabel?: string;
    defaultSizeScale: string | null;
    sizeScales?: string[];
    userAlertCode?: string | null;
    /**
     * @default "right"
     * @example "right"
     */
    currencyPosition?: string;
    currencyWithSpace?: boolean;
    translationLocale?: Locale | null;
    useGlobale?: boolean;
}

export interface SiteCountryRead {
    id?: number;
    code: string;
    label: string;
}

export interface SiteCustomisationServiceList {
    id?: number;
    code: string;
    label: string;
}

export interface SiteCustomisationServiceRead {
    id?: number;
    code: string;
    label: string;
    availableLocales?: LocaleCustomisationServiceRead[];
    shippingCountries?: string[];
    availableCurrencies?: CurrencyCustomisationServiceRead[];
    outputCurrencies?: string[];
    brandLabel?: string;
}

export interface SiteCustomisationServiceWrite {
    id?: number;
}

export interface SiteFooterItemRead {
    id?: number;
    availableLocales?: LocaleFooterItemRead[];
}

export interface SiteFooterItemWrite {
    id?: number;
}

export interface SiteMenuItemRead {
    id?: number;
}

export interface SiteMobileContentRead {
    id?: number;
    availableLocales?: LocaleMobileContentRead[];
}

export interface SiteMobileContentVersionRead {
    id?: number;
    availableLocales?: LocaleMobileContentVersionRead[];
}

export interface SitePopinRead {
    id?: number | null;
    code?: string;
    brand?: BrandPopinRead;
    label?: string;
}

export interface SitePopinWrite {
    id?: number | null;
}

export interface SitePreFooterRead {
    id?: number;
    availableLocales?: LocalePreFooterRead[];
}

export interface SitePreFooterWrite {
    id?: number;
}

export interface SitePushProductRead {
    id?: number;
    code: string;
    label: string;
    availableLocales?: LocalePushProductRead[];
    brandLabel?: string;
}

export interface SitePushProductWrite {
    id?: number;
    brandLabel?: string;
}

export interface SiteSaleRead {
    id?: number;
    availableLocales?: LocaleSaleRead[];
}

export interface SiteSiteRead {
    id?: number;
    code: string;
    label: string;
    backofficeLabel?: string | null;
    /**
     * @default "website"
     * @example "website"
     */
    type?: string;
    defaultLocale: LocaleSiteRead | null;
    availableLocales?: LocaleSiteRead[];
    defaultShippingCountry?: CountrySiteRead | null;
    shippingCountries?: ShippingCountrySiteRead[];
    availableCurrencies?: CurrencySiteRead[];
    outputCurrencies?: string[];
    ipAddresses?: string[] | null;
    brand: BrandsSiteRead | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    workspacesDates?: string[];
    workspacesIds?: string[];
    mandatoryLogin?: MandatoryLoginSiteRead | null;
    brandLabel?: string;
    defaultSizeScale: string | null;
    sizeScales?: string[];
    userAlertCode?: string | null;
    /**
     * @default "right"
     * @example "right"
     */
    currencyPosition?: string;
    currencyWithSpace?: boolean;
    translationLocale?: LocaleSiteRead | null;
    useGlobale?: boolean;
}

export interface SiteSiteWrite {
    id?: number;
    code: string;
    label: string;
    backofficeLabel?: string | null;
    /**
     * @default "website"
     * @example "website"
     */
    type?: string;
    defaultLocale: LocaleSiteWrite | null;
    availableLocales?: LocaleSiteWrite[];
    defaultShippingCountry?: CountrySiteWrite | null;
    shippingCountries?: ShippingCountrySiteWrite[];
    availableCurrencies?: CurrencySiteWrite[];
    outputCurrencies?: string[];
    brand: BrandsSiteWrite | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    brandLabel?: string;
    defaultSizeScale: string | null;
    sizeScales?: string[];
    userAlertCode?: string | null;
    /**
     * @default "right"
     * @example "right"
     */
    currencyPosition?: string;
    currencyWithSpace?: boolean;
    translationLocale?: LocaleSiteWrite | null;
    useGlobale?: boolean;
}

export interface SiteTransactionalEmailRead {
    id?: number;
    label: string;
    brandLabel?: string;
}

export interface SiteTransactionalEmailWrite {
    id?: number;
}

export interface SiteWorkspaceList {
    id?: number;
    label: string;
    workspacesIds?: string[];
}

export interface SiteWorkspaceRead {
    id?: number;
    code: string;
    label: string;
    /**
     * @default "website"
     * @example "website"
     */
    type?: string;
    defaultLocale: LocaleWorkspaceRead | null;
    availableLocales?: LocaleWorkspaceRead[];
    /** @format iri-reference */
    defaultShippingCountry?: string | null;
    shippingCountries?: string[];
    availableCurrencies?: CurrencyWorkspaceRead[];
    outputCurrencies?: string[];
    brandLabel?: string;
}

export interface SiteWorkspaceWrite {
    id?: number;
}

export interface SiteWorkspaceList {
    id?: number;
    label: string;
    workspacesIds?: string[];
}

/** Class Slide */
export interface Slide {
    id?: number;
    arrowColor?: string | null;
    /** @format iri-reference */
    media?: string | null;
    mediaTransformations?: Record<string, string>;
    /** @format iri-reference */
    alternateMedia?: string | null;
    alternateMediaTransformations?: string[] | null;
    position?: number;
    slideProductLinks?: string[];
    hasProduct?: boolean | null;
    translations?: Record<string, string>;
}

/** Class Slide */
export interface SlideBlockRead {
    id?: number;
    arrowColor?: string | null;
    media?: MediasBlockRead | null;
    mediaTransformations?: Record<string, string>;
    alternateMedia?: MediasBlockRead | null;
    alternateMediaTransformations?: string[] | null;
    position?: number;
    slideProductLinks?: SlideProductLinkBlockRead[];
    hasProduct?: boolean | null;
    translations?: Record<string, SlideTranslationsBlockRead>;
}

/** Class Slide */
export interface SlideBlockWrite {
    id?: number;
    arrowColor?: string | null;
    media?: MediasBlockWrite | null;
    mediaTransformations?: Record<string, string>;
    alternateMedia?: MediasBlockWrite | null;
    alternateMediaTransformations?: string[] | null;
    position?: number;
    slideProductLinks?: SlideProductLinkBlockWrite[];
    hasProduct?: boolean | null;
    translations?: Record<string, SlideTranslationsBlockWrite>;
}

/** Class SlideProductLink */
export interface SlideProductLink {
    id?: number;
    position?: number;
    /** @format iri-reference */
    variant?: string | null;
    /** @format iri-reference */
    multiProduct?: string | null;
    price?: number | null;
}

/** Class SlideProductLink */
export interface SlideProductLinkBlockRead {
    id?: number;
    position?: number;
    variant?: CatalogVariantsBlockRead | null;
    multiProduct?: CatalogMultiProductsBlockRead | null;
    price?: number | null;
}

/** Class SlideProductLink */
export interface SlideProductLinkBlockWrite {
    id?: number;
    position?: number;
    variant?: CatalogVariantsBlockWrite | null;
    multiProduct?: CatalogMultiProductsBlockWrite | null;
    price?: number | null;
}

export interface UploadedMediaMediaUpload {
    filename: string | null;
    publicId: string | null;
    secureUrl: string | null;
    tags?: string[] | null;
    video: boolean | null;
    previewUrl?: string | null;
    width: number | null;
    height: number | null;
}

/** Customer User object. */
export interface User {
    id?: number;
    /** @format email */
    email: string;
    /** @format email */
    secondEmail?: string | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    firstName: string | null;
    lastName: string | null;
    otherLastName?: string | null;
    /** @format iri-reference */
    sponsor?: string | null;
    sponsorees?: Collection;
    phone?: string | null;
    /**
     *
     * really useful?
     * @format date-time
     */
    birthDate?: string | null;
    gender?: number | null;
    requiredFlags?: string[];
    privacyFlags?: string[];
    favorites?: string[];
    fullName?: string | null;
    types?: Collection;
    /** @format date-time */
    createdAt?: string | null;
    /**
     * @default "fr"
     * @example "fr"
     */
    language?: string | null;
    /** @format date-time */
    newsletterSubscribedAt?: string | null;
    /** @format iri-reference */
    linkedUser?: string | null;
    /** @format date-time */
    deletedAt?: string | null;
    countryCode?: string | null;
}

/** Store configuration for BO. */
export interface UserConfiguration {
    id?: number;
}

/** Store configuration for BO. */
export interface UserConfigurationConfigurationWrite {
    /**
     * @default "normal"
     * @example "normal"
     */
    theme?: string;
    favorites?: FavoriteWrite[];
    dashboard?: DashboardWrite[];
}

export interface UserType {
    id?: number;
    label: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    nbUsers?: number;
}

export interface UserTypeSaleRead {
    id?: number;
    label: string;
}

export interface UserTypeSaleVersionWrite {
    id?: number;
}

export interface Workspace {
    id?: number;
    code?: string;
    label?: string;
    saleVersions?: SaleVersions[];
    activeSaleVersions?: SaleVersions[];
    activePageVersions?: PageVersions[];
    homepage?: PageVersions | null;
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn?: string | null;
    site?: Site | null;
    /** @format date-time */
    previewOn?: string;
    status?: string;
    /** @format date-time */
    updatedAt?: string | null;
    updatedBy?: Admin | null;
    /** @format date-time */
    deletedAt?: string | null;
    activeSaleVersionsNb?: any;
    variantsNb?: any;
    activePageVersionsNb?: any;
}

export interface WorkspaceMenuItemAttach {
    id?: number;
}

export interface WorkspaceMenuItemRead {
    id?: number;
    site?: SiteMenuItemRead | null;
    status?: string;
}

export interface WorkspaceMenuItemVersionRead {
    id?: number;
    /** @format iri-reference */
    site?: string | null;
    status?: string;
}

export interface WorkspaceMobileContentRead {
    id?: number;
    site?: SiteMobileContentRead | null;
}

export interface WorkspaceMobileContentVersionRead {
    id?: number;
    site?: SiteMobileContentVersionRead | null;
}

export interface WorkspaceSaleRead {
    id?: number;
    label?: string;
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn?: string | null;
    site?: SiteSaleRead | null;
}

export type WorkspaceWorkspaceCreationWrite = object;

export type WorkspaceWorkspaceDuplicatePageVersions = object;

export interface WorkspaceWorkspaceList {
    id?: number;
    code?: string;
    label?: string;
    homepage?: PageVersionsWorkspaceList | null;
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn?: string | null;
    site?: SiteWorkspaceList | null;
    /** @format date-time */
    previewOn?: string;
    status?: string;
    /** @format date-time */
    updatedAt?: string | null;
    activeSaleVersionsNb?: any;
    variantsNb?: any;
    activePageVersionsNb?: any;
}

export interface WorkspaceWorkspaceRead {
    id?: number;
    code?: string;
    label?: string;
    saleVersions?: SaleVersionsWorkspaceRead[];
    activeSaleVersions?: SaleVersionsWorkspaceRead[];
    activePageVersions?: PageVersionsWorkspaceRead[];
    homepage?: PageVersionsWorkspaceRead | null;
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn?: string | null;
    site?: SiteWorkspaceRead | null;
    /** @format date-time */
    previewOn?: string;
    status?: string;
    /** @format date-time */
    updatedAt?: string | null;
    updatedBy?: AdminWorkspaceRead | null;
    /** @format date-time */
    deletedAt?: string | null;
    activeSaleVersionsNb?: any;
    variantsNb?: any;
    activePageVersionsNb?: any;
}

export interface WorkspaceWorkspaceWrite {
    id?: number;
    code?: string;
    label?: string;
    saleVersions?: SaleVersionsWorkspaceWrite[];
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn?: string | null;
    site?: SiteWorkspaceWrite | null;
    /** @format date-time */
    previewOn?: string;
}

export interface WorkspaceWorkspaceList {
    id?: number;
    code?: string;
    label?: string;
    homepage?: PageVersionsWorkspaceList | null;
    /** @format date-time */
    beginOn: string;
    /** @format date-time */
    finishOn?: string | null;
    site?: SiteWorkspaceList | null;
    /** @format date-time */
    previewOn?: string;
    status?: string;
    /** @format date-time */
    updatedAt?: string | null;
    activeSaleVersionsNb?: any;
    variantsNb?: any;
    activePageVersionsNb?: any;
}

export type Wrapper = object;

export type WrapperBlockRead = object;

export type WrapperCatalogItemRead = object;

export type WrapperCatalogMultiProductRead = object;

export type WrapperCatalogProductRead = object;

export type WrapperCatalogVariantRead = object;

export type WrapperCrossSellProductRead = object;

export type WrapperCrossSellVariantRead = object;

export type WrapperCustomisationServiceRead = object;

export type WrapperMediaRead = object;

export type WrapperMenuItemAttach = object;

export type WrapperMenuItemRead = object;

export type WrapperMenuItemVersionRead = object;

export type WrapperMobileContentRead = object;

export type WrapperMobileContentVersionRead = object;

export type WrapperPageVersionRead = object;

export type WrapperPageVersionWrite = object;

export type WrapperStoreMediaVariantRead = object;

export type WrapperStoreVariantRead = object;

export type WrapperSubCategoryRead = object;

export type WrapperTypologyRead = object;

/** Admin User object. */
export interface Admin {
    id?: number;
    /** @format email */
    email: string;
    fullName?: string | null;
}

/** Admin User object. */
export interface AdminAdminRead {
    id?: number;
    /** @format email */
    email: string;
    fullName?: string | null;
}

/** Admin User object. */
export interface AdminEntityHistoryRead {
    id?: number;
    fullName?: string | null;
}

/** Admin User object. */
export interface AdminWorkspaceRead {
    id?: number;
    /** @format email */
    email: string;
    fullName?: string | null;
}

export interface BlockTranslations {
    id?: number;
    locale?: string;
    /** Use Mediumtext */
    htmlContent?: string | null;
    upText?: string | null;
    downText?: string | null;
    leftText?: string | null;
    rightText?: string | null;
    legend?: string | null;
    link?: string | null;
    ctaText?: string | null;
}

export interface BlockTranslationsBlockRead {
    id?: number;
    locale?: string;
    /** Use Mediumtext */
    htmlContent?: string | null;
    upText?: string | null;
    downText?: string | null;
    leftText?: string | null;
    rightText?: string | null;
    legend?: string | null;
    link?: string | null;
    ctaText?: string | null;
}

export interface BlockTranslationsBlockWrite {
    id?: number;
    locale?: string;
    /** Use Mediumtext */
    htmlContent?: string | null;
    upText?: string | null;
    downText?: string | null;
    leftText?: string | null;
    rightText?: string | null;
    legend?: string | null;
    link?: string | null;
    ctaText?: string | null;
}

export interface BlockTranslationsCompositionLayoutRead {
    id?: number;
    locale?: string;
    /** Use Mediumtext */
    htmlContent?: string | null;
    upText?: string | null;
    downText?: string | null;
    leftText?: string | null;
    rightText?: string | null;
    legend?: string | null;
    link?: string | null;
    ctaText?: string | null;
}

export interface BlockTranslationsCompositionRead {
    id?: number;
    locale?: string;
    /** Use Mediumtext */
    htmlContent?: string | null;
    upText?: string | null;
    downText?: string | null;
    leftText?: string | null;
    rightText?: string | null;
    legend?: string | null;
    link?: string | null;
    ctaText?: string | null;
}

/** Class Block */
export interface Blocks {
    id?: number;
    composition: Composition | null;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    type: string;
    media?: Medias | null;
    mediaTransformations?: Record<string, string>;
    alternateMedia?: Medias | null;
    alternateMediaTransformations?: string[] | null;
    htmlColor?: ColorParameter | null;
    htmlColorOverride?: string | null;
    alternateColor?: ColorParameter | null;
    alternateColorOverride?: string | null;
    textColor?: ColorParameter | null;
    textColorOverride?: string | null;
    shifted?: boolean | null;
    productNameInner?: boolean;
    legendColor?: ColorParameter | null;
    legendColorOverride?: string | null;
    ctaColor?: string | null;
    /**
     * @default "left"
     * @example "left"
     */
    ctaAlign?: string;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    /**
     * @default true
     * @example true
     */
    displayCtaOnMobile?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    page?: Page | null;
    linkedSizeValues?: string[] | null;
    staticPage?: string | null;
    target?: string | null;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string;
    autoplay?: boolean;
    loop?: boolean;
    buttonPlay?: boolean;
    buttonSound?: boolean;
    previewImage?: Medias | null;
    previewImageTransformations?: Record<string, string>;
    /**
     * @default "none"
     * @example "none"
     */
    alternativeType?: string;
    lookProductLinks?: LookProductLink[];
    slides?: Slide[];
    catalogVariant?: CatalogVariants | null;
    multiProduct?: CatalogMultiProducts | null;
    multiProductSelectedVariants?: CatalogVariants[];
    translations?: Record<string, BlockTranslations>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/** Class Block */
export interface BlocksBlockBatchWrite {
    id?: number;
    composition: CompositionBlockBatchWrite | null;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    mediaTransformations?: Record<string, string>;
}

/** Class Block */
export interface BlocksBlockRead {
    id?: number;
    composition: CompositionBlockRead | null;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    type: string;
    media?: MediasBlockRead | null;
    mediaTransformations?: Record<string, string>;
    alternateMedia?: MediasBlockRead | null;
    alternateMediaTransformations?: string[] | null;
    htmlColor?: ColorParameterBlockRead | null;
    htmlColorOverride?: string | null;
    alternateColor?: ColorParameterBlockRead | null;
    alternateColorOverride?: string | null;
    textColor?: ColorParameterBlockRead | null;
    textColorOverride?: string | null;
    shifted?: boolean | null;
    productNameInner?: boolean;
    legendColor?: ColorParameterBlockRead | null;
    legendColorOverride?: string | null;
    ctaColor?: string | null;
    /**
     * @default "left"
     * @example "left"
     */
    ctaAlign?: string;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    /**
     * @default true
     * @example true
     */
    displayCtaOnMobile?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    page?: PageBlockRead | null;
    linkedSizeValues?: string[] | null;
    staticPage?: string | null;
    target?: string | null;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string;
    autoplay?: boolean;
    loop?: boolean;
    buttonPlay?: boolean;
    buttonSound?: boolean;
    previewImage?: MediasBlockRead | null;
    previewImageTransformations?: Record<string, string>;
    /**
     * @default "none"
     * @example "none"
     */
    alternativeType?: string;
    lookProductLinks?: LookProductLinkBlockRead[];
    slides?: SlideBlockRead[];
    catalogVariant?: CatalogVariantsBlockRead | null;
    multiProduct?: CatalogMultiProductsBlockRead | null;
    multiProductSelectedVariants?: CatalogVariantsBlockRead[];
    translations?: Record<string, BlockTranslationsBlockRead>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/** Class Block */
export interface BlocksBlockWrite {
    id?: number;
    composition: CompositionBlockWrite | null;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    type: string;
    media?: MediasBlockWrite | null;
    mediaTransformations?: Record<string, string>;
    alternateMedia?: MediasBlockWrite | null;
    alternateMediaTransformations?: string[] | null;
    htmlColor?: ColorParameterBlockWrite | null;
    htmlColorOverride?: string | null;
    alternateColor?: ColorParameterBlockWrite | null;
    alternateColorOverride?: string | null;
    textColor?: ColorParameterBlockWrite | null;
    textColorOverride?: string | null;
    shifted?: boolean | null;
    legendColor?: ColorParameterBlockWrite | null;
    legendColorOverride?: string | null;
    ctaColor?: string | null;
    /**
     * @default "left"
     * @example "left"
     */
    ctaAlign?: string;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    /**
     * @default true
     * @example true
     */
    displayCtaOnMobile?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    page?: PageBlockWrite | null;
    linkedSizeValues?: string[] | null;
    staticPage?: string | null;
    target?: string | null;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string;
    autoplay?: boolean;
    loop?: boolean;
    buttonPlay?: boolean;
    buttonSound?: boolean;
    previewImage?: MediasBlockWrite | null;
    previewImageTransformations?: Record<string, string>;
    /**
     * @default "none"
     * @example "none"
     */
    alternativeType?: string;
    lookProductLinks?: LookProductLinkBlockWrite[];
    slides?: SlideBlockWrite[];
    catalogVariant?: CatalogVariantsBlockWrite | null;
    multiProduct?: CatalogMultiProductsBlockWrite | null;
    multiProductSelectedVariants?: CatalogVariantsBlockWrite[];
    translations?: Record<string, BlockTranslationsBlockWrite>;
}

/** Class Block */
export interface BlocksCompositionLayoutRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    type: string;
    media?: MediasCompositionLayoutRead | null;
    mediaTransformations?: Record<string, string>;
    alternateMedia?: MediasCompositionLayoutRead | null;
    alternateMediaTransformations?: string[] | null;
    /** @format iri-reference */
    htmlColor?: string | null;
    htmlColorOverride?: string | null;
    /** @format iri-reference */
    alternateColor?: string | null;
    alternateColorOverride?: string | null;
    /** @format iri-reference */
    textColor?: string | null;
    textColorOverride?: string | null;
    shifted?: boolean | null;
    /** @format iri-reference */
    legendColor?: string | null;
    legendColorOverride?: string | null;
    ctaColor?: string | null;
    /**
     * @default "left"
     * @example "left"
     */
    ctaAlign?: string;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    /**
     * @default true
     * @example true
     */
    displayCtaOnMobile?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    /** @format iri-reference */
    page?: string | null;
    linkedSizeValues?: string[] | null;
    staticPage?: string | null;
    target?: string | null;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string;
    autoplay?: boolean;
    loop?: boolean;
    buttonPlay?: boolean;
    buttonSound?: boolean;
    previewImage?: MediasCompositionLayoutRead | null;
    previewImageTransformations?: Record<string, string>;
    /**
     * @default "none"
     * @example "none"
     */
    alternativeType?: string;
    lookProductLinks?: LookProductLinkCompositionLayoutRead[];
    slides?: string[];
    /** @format iri-reference */
    catalogVariant?: string | null;
    multiProduct?: CatalogMultiProductsCompositionLayoutRead | null;
    multiProductSelectedVariants?: string[];
    translations?: Record<string, BlockTranslationsCompositionLayoutRead>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/** Class Block */
export interface BlocksCompositionRead {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    type: string;
    /** @format iri-reference */
    media?: string | null;
    mediaTransformations?: Record<string, string>;
    /** @format iri-reference */
    alternateMedia?: string | null;
    alternateMediaTransformations?: string[] | null;
    htmlColor?: ColorParameterCompositionRead | null;
    htmlColorOverride?: string | null;
    alternateColor?: ColorParameterCompositionRead | null;
    alternateColorOverride?: string | null;
    textColor?: ColorParameterCompositionRead | null;
    textColorOverride?: string | null;
    shifted?: boolean | null;
    productNameInner?: boolean;
    legendColor?: ColorParameterCompositionRead | null;
    legendColorOverride?: string | null;
    ctaColor?: string | null;
    /**
     * @default "left"
     * @example "left"
     */
    ctaAlign?: string;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    /**
     * @default true
     * @example true
     */
    displayCtaOnMobile?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    /** @format iri-reference */
    page?: string | null;
    linkedSizeValues?: string[] | null;
    staticPage?: string | null;
    target?: string | null;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string;
    autoplay?: boolean;
    loop?: boolean;
    buttonPlay?: boolean;
    buttonSound?: boolean;
    /** @format iri-reference */
    previewImage?: string | null;
    previewImageTransformations?: Record<string, string>;
    /**
     * @default "none"
     * @example "none"
     */
    alternativeType?: string;
    lookProductLinks?: string[];
    slides?: string[];
    /** @format iri-reference */
    catalogVariant?: string | null;
    /** @format iri-reference */
    multiProduct?: string | null;
    multiProductSelectedVariants?: string[];
    translations?: Record<string, BlockTranslationsCompositionRead>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/** Class Block */
export interface BlocksCompositionWrite {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
}

/** Class Block */
export interface BlocksPageVersionRead {
    id?: number;
}

export interface Brands {
    id?: number;
    code: string;
    label: string;
    domain: string;
    /** @maxLength 1 */
    initial: string;
    media: Medias | null;
}

export interface BrandsBrandRead {
    id?: number;
    code: string;
    label: string;
    domain: string;
    /** @maxLength 1 */
    initial: string;
    media: MediasBrandRead | null;
}

export interface BrandsBrandWrite {
    id?: number;
    code: string;
    label: string;
    domain: string;
    /** @maxLength 1 */
    initial: string;
    media: MediasBrandWrite | null;
}

export interface BrandsCatalogMultiProductList {
    id?: number;
    code: string;
}

export interface BrandsCatalogMultiProductRead {
    id?: number;
    code: string;
}

export interface BrandsCatalogMultiProductWrite {
    id?: number;
}

export interface BrandsCatalogProductRead {
    id?: number;
    code: string;
}

export interface BrandsSiteRead {
    id?: number;
    code: string;
    label: string;
    domain: string;
    /** @maxLength 1 */
    initial: string;
    media: MediasSiteRead | null;
}

export interface BrandsSiteWrite {
    id?: number;
    code: string;
}

export interface BrandsStoreVariantRead {
    code: string;
}

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export interface CatalogItems {
    id?: number;
    ean?: string;
    sizeLabel?: string;
    variant?: CatalogVariants | null;
    retailSales?: RetailSale[];
    stockWeb?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockLogistic?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
}

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export interface CatalogItemsCatalogItemRead {
    id?: number;
    ean?: string;
    sizeLabel?: string;
    variant?: CatalogVariantsCatalogItemRead | null;
    retailSales?: RetailSaleCatalogItemRead[];
    stockWeb?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockLogistic?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
}

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export interface CatalogItemsCatalogItemReadCollection {
    id?: number;
    ean?: string;
    sizeLabel?: string;
    variant?: CatalogVariantsCatalogItemReadCollection | null;
    stockWeb?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockLogistic?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
}

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export type CatalogItemsCatalogItemWrite = object;

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export interface CatalogItemsCatalogProductRead {
    id?: number;
    ean?: string;
    sizeLabel?: string;
    stockWeb?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockLogistic?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
}

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export interface CatalogItemsCatalogVariantRead {
    id?: number;
    ean?: string;
    sizeLabel?: string;
    stockWeb?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockLogistic?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
}

/**
 * A product variant item
 * l'EAN d'une déclinaison
 */
export interface CatalogItemsStoreVariantRead {
    id?: number;
    ean?: string;
    sizeLabel?: string;
    stockWeb?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockLogistic?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
}

export interface CatalogMultiProducts {
    id?: number;
    /** @format date-time */
    updatedAt?: string | null;
    enabled?: boolean;
    brand: Brands;
    translations?: Record<string, MultiProductTranslation>;
    variantLinks?: MultiProductVariantLink[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
}

export interface CatalogMultiProductsBlockRead {
    id?: number;
    enabled?: boolean;
    /** @format iri-reference */
    brand: string;
    translations?: Record<string, MultiProductTranslationBlockRead>;
    variantLinks?: MultiProductVariantLinkBlockRead[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
}

export interface CatalogMultiProductsBlockWrite {
    id?: number;
}

export interface CatalogMultiProductsCatalogMultiProductList {
    id?: number;
    /** @format date-time */
    updatedAt?: string | null;
    enabled?: boolean;
    brand: BrandsCatalogMultiProductList;
    translations?: Record<string, MultiProductTranslationCatalogMultiProductList>;
    variantLinks?: MultiProductVariantLinkCatalogMultiProductList[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    mainMediaUrl?: string | null;
}

export interface CatalogMultiProductsCatalogMultiProductRead {
    id?: number;
    /** @format date-time */
    updatedAt?: string | null;
    enabled?: boolean;
    brand: BrandsCatalogMultiProductRead;
    translations?: Record<string, MultiProductTranslationCatalogMultiProductRead>;
    variantLinks?: MultiProductVariantLinkCatalogMultiProductRead[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
}

export interface CatalogMultiProductsCatalogMultiProductWrite {
    enabled?: boolean;
    brand: BrandsCatalogMultiProductWrite;
    translations?: Record<string, MultiProductTranslationCatalogMultiProductWrite>;
    variantLinks?: MultiProductVariantLinkCatalogMultiProductWrite[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
}

export interface CatalogMultiProductsCompositionLayoutRead {
    id?: number;
}

export interface CatalogProductTranslations {
    id?: number;
    label?: string;
    /**
     *
     * Gedmo\Slug(fields={"label"}) # TODO
     */
    path?: string | null;
}

/** Product. */
export interface CatalogProducts {
    id?: number;
    brand?: Brands | null;
    productBrand?: string | null;
    variants?: CatalogVariants[];
    subCategory?: SubCategories | null;
    typology?: Typologies | null;
    pimWrapper?: Wrapper | null;
    packaging?: boolean;
    category?: Category | null;
}

/** Product. */
export interface CatalogProductsBlockRead {
    id?: number;
    /** @format iri-reference */
    subCategory?: string | null;
}

/** Product. */
export interface CatalogProductsCatalogItemRead {
    id?: number;
    variants?: CatalogVariantsCatalogItemRead[];
    subCategory?: SubCategoriesCatalogItemRead | null;
    typology?: TypologiesCatalogItemRead | null;
    packaging?: boolean;
    category?: CategoryCatalogItemRead | null;
}

/** Product. */
export interface CatalogProductsCatalogProductRead {
    id?: number;
    brand?: BrandsCatalogProductRead | null;
    productBrand?: string | null;
    variants?: CatalogVariantsCatalogProductRead[];
    subCategory?: SubCategoriesCatalogProductRead | null;
    typology?: TypologiesCatalogProductRead | null;
    pimWrapper?: WrapperCatalogProductRead | null;
    packaging?: boolean;
    category?: CategoryCatalogProductRead | null;
}

/** Product. */
export interface CatalogProductsCatalogVariantRead {
    id?: number;
    subCategory?: SubCategoriesCatalogVariantRead | null;
    typology?: TypologiesCatalogVariantRead | null;
}

/** Product. */
export interface CatalogProductsCrossSellProductRead {
    variants?: CatalogVariantsCrossSellProductRead[];
}

/** Product. */
export interface CatalogProductsCrossSellVariantRead {
    pimWrapper?: WrapperCrossSellVariantRead | null;
}

/** Product. */
export interface CatalogProductsMediaRead {
    id?: number;
    /** @format iri-reference */
    subCategory?: string | null;
}

/** Product. */
export interface CatalogProductsMobileContentRead {
    id?: number;
}

/** Product. */
export interface CatalogProductsMobileContentVersionRead {
    id?: number;
}

/** Product. */
export interface CatalogProductsStoreVariantRead {
    id?: number;
    brand?: BrandsStoreVariantRead | null;
    productBrand?: string | null;
    subCategory?: SubCategoriesStoreVariantRead | null;
    typology?: TypologiesStoreVariantRead | null;
}

export interface CatalogVariantTranslations {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

export interface CatalogVariantTranslationsBlockRead {
    id?: number;
    locale?: string;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
}

export interface CatalogVariantTranslationsCatalogItemRead {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

export interface CatalogVariantTranslationsCatalogItemReadCollection {
    label?: string | null;
    color?: string | null;
}

export interface CatalogVariantTranslationsCatalogMultiProductList {
    id?: number;
    locale?: string;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
}

export interface CatalogVariantTranslationsCatalogProductRead {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

export interface CatalogVariantTranslationsCatalogVariantRead {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

export interface CatalogVariantTranslationsCustomisationServiceRead {
    id?: number;
    locale?: string;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
}

export interface CatalogVariantTranslationsMenuItemRead {
    label?: string | null;
    color?: string | null;
}

export interface CatalogVariantTranslationsMobileContentRead {
    id?: number;
    locale?: string;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
}

export interface CatalogVariantTranslationsMobileContentVersionRead {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

export interface CatalogVariantTranslationsPopinRead {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

export interface CatalogVariantTranslationsPushProductRead {
    id?: number;
    locale?: string;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
}

export interface CatalogVariantTranslationsStoreVariantRead {
    id?: number;
    locale?: string;
    workshop?: string | null;
    modelSizing?: string | null;
    sizeAdvice?: string | null;
    generatedModelSizing?: string | null;
    metaTitle?: string | null;
    metaDescription?: string | null;
    label?: string | null;
    color?: string | null;
    fullName?: string | null;
    madeIn?: string | null;
    bulletPoints?: string | null;
    composition?: string[];
    studioEditorialText?: string | null;
    displayComposition?: string | null;
    displayCare?: string | null;
    displayCsr?: string | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariants {
    id?: number;
    prices?: string[];
    code?: string | null;
    translations?: Record<string, CatalogVariantTranslations>;
    storeVariants?: StoreVariants[];
    selectedStoreVariant?: StoreVariants | null;
    mediaVariantTags?: StoreMediaVariantTags[];
    items?: CatalogItems[];
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    /** @format date-time */
    stockDelivery?: string;
    stockToCome?: number;
    /**
     * @default true
     * @example true
     */
    sizingPopin?: boolean;
    pimWrapper?: Wrapper | null;
    /** @format date-time */
    firstSaleDate?: string | null;
    /** @format date-time */
    lastSaleDate?: string | null;
    /** @format date-time */
    implementationDate?: string | null;
    product?: CatalogProducts | null;
    saleVersions?: SaleVersions[];
    /** @format date-time */
    preorderStart?: string | null;
    /** @format date-time */
    preorderEnd?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    modelSizingType?: string;
    /**
     * @default "auto"
     * @example "auto"
     */
    sizeAdviceType?: string;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsBlockRead {
    id?: number;
    code?: string | null;
    translations?: Record<string, CatalogVariantTranslationsBlockRead>;
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    pimWrapper?: WrapperBlockRead | null;
    product?: CatalogProductsBlockRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsBlockWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogItemRead {
    id?: number;
    prices?: string[];
    translations?: Record<string, CatalogVariantTranslationsCatalogItemRead>;
    storeVariants?: StoreVariantsCatalogItemRead[];
    selectedStoreVariant?: StoreVariantsCatalogItemRead | null;
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    /** @format date-time */
    stockDelivery?: string;
    pimWrapper?: WrapperCatalogItemRead | null;
    product?: CatalogProductsCatalogItemRead | null;
    saleVersions?: string[];
    /** @format date-time */
    preorderStart?: string | null;
    /** @format date-time */
    preorderEnd?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    modelSizingType?: string;
    /**
     * @default "auto"
     * @example "auto"
     */
    sizeAdviceType?: string;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogItemReadCollection {
    id?: number;
    code?: string | null;
    translations?: Record<string, CatalogVariantTranslationsCatalogItemReadCollection>;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogMultiProductList {
    id?: number;
    code?: string | null;
    translations?: Record<string, CatalogVariantTranslationsCatalogMultiProductList>;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogMultiProductRead {
    id?: number;
    prices?: string[];
    selectedStoreVariant?: StoreVariantsCatalogMultiProductRead | null;
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    pimWrapper?: WrapperCatalogMultiProductRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogMultiProductWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogProductRead {
    id?: number;
    prices?: string[];
    code?: string | null;
    legacyCode?: string | null;
    legacyModelId?: number | null;
    translations?: Record<string, CatalogVariantTranslationsCatalogProductRead>;
    selectedStoreVariant?: StoreVariantsCatalogProductRead | null;
    items?: CatalogItemsCatalogProductRead[];
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    /**
     * @default true
     * @example true
     */
    sizingPopin?: boolean;
    pimWrapper?: WrapperCatalogProductRead | null;
    /** @format date-time */
    firstSaleDate?: string | null;
    /** @format date-time */
    lastSaleDate?: string | null;
    /** @format date-time */
    implementationDate?: string | null;
    product?: CatalogProductsCatalogProductRead | null;
    saleVersions?: SaleVersionsCatalogProductRead[];
    /** @format date-time */
    preorderStart?: string | null;
    /** @format date-time */
    preorderEnd?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    modelSizingType?: string;
    /**
     * @default "auto"
     * @example "auto"
     */
    sizeAdviceType?: string;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCatalogVariantRead {
    id?: number;
    prices?: string[];
    code?: string | null;
    translations?: Record<string, CatalogVariantTranslationsCatalogVariantRead>;
    selectedStoreVariant?: StoreVariantsCatalogVariantRead | null;
    items?: CatalogItemsCatalogVariantRead[];
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    /** @format date-time */
    stockDelivery?: string;
    stockToCome?: number;
    /**
     * @default true
     * @example true
     */
    sizingPopin?: boolean;
    pimWrapper?: WrapperCatalogVariantRead | null;
    /** @format date-time */
    firstSaleDate?: string | null;
    /** @format date-time */
    lastSaleDate?: string | null;
    /** @format date-time */
    implementationDate?: string | null;
    product?: CatalogProductsCatalogVariantRead | null;
    saleVersions?: SaleVersionsCatalogVariantRead[];
    /** @format date-time */
    preorderStart?: string | null;
    /** @format date-time */
    preorderEnd?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    modelSizingType?: string;
    /**
     * @default "auto"
     * @example "auto"
     */
    sizeAdviceType?: string;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCrossSellProductRead {
    id?: number;
    prices?: string[];
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    pimWrapper?: WrapperCrossSellProductRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCrossSellVariantRead {
    id?: number;
    prices?: string[];
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    pimWrapper?: WrapperCrossSellVariantRead | null;
    product?: CatalogProductsCrossSellVariantRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCustomisationServiceRead {
    id?: number;
    translations?: Record<string, CatalogVariantTranslationsCustomisationServiceRead>;
    pimWrapper?: WrapperCustomisationServiceRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsCustomisationServiceWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMediaRead {
    id?: number;
    pimWrapper?: WrapperMediaRead | null;
    product?: CatalogProductsMediaRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMenuItemDropdownRead {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMenuItemRead {
    id?: number;
    translations?: Record<string, CatalogVariantTranslationsMenuItemRead>;
    items?: string[];
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMenuItemVersionRead {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMenuItemVersionWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMobileContentRead {
    id?: number;
    translations?: Record<string, CatalogVariantTranslationsMobileContentRead>;
    pimWrapper?: WrapperMobileContentRead | null;
    product?: CatalogProductsMobileContentRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMobileContentVersionRead {
    id?: number;
    translations?: Record<string, CatalogVariantTranslationsMobileContentVersionRead>;
    pimWrapper?: WrapperMobileContentVersionRead | null;
    product?: CatalogProductsMobileContentVersionRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsMobileContentVersionWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsPopinRead {
    id?: number;
    code?: string | null;
    translations?: Record<string, CatalogVariantTranslationsPopinRead>;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsPushProductRead {
    id?: number;
    code?: string | null;
    legacyCode?: string | null;
    legacyModelId?: number | null;
    translations?: Record<string, CatalogVariantTranslationsPushProductRead>;
    /** @format iri-reference */
    product?: string | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsPushProductWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsStoreMediaVariantRead {
    id?: number;
    pimWrapper?: WrapperStoreMediaVariantRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsStoreVariantRead {
    id?: number;
    prices?: string[];
    code?: string | null;
    legacyCode?: string | null;
    legacyModelId?: number | null;
    translations?: Record<string, CatalogVariantTranslationsStoreVariantRead>;
    items?: CatalogItemsStoreVariantRead[];
    stockLogistic?: number;
    stockLogisticFr1?: number;
    stockLogisticUs1?: number;
    stockWebAvailable?: number;
    stockWebCart?: number;
    stockWeb?: number;
    imageUrl?: string | null;
    availableSoon?: boolean;
    enabled?: boolean;
    /** @format date-time */
    stockDelivery?: string;
    stockToCome?: number;
    pimWrapper?: WrapperStoreVariantRead | null;
    /** @format date-time */
    firstSaleDate?: string | null;
    /** @format date-time */
    lastSaleDate?: string | null;
    /** @format date-time */
    implementationDate?: string | null;
    product?: CatalogProductsStoreVariantRead | null;
    saleVersions?: SaleVersionsStoreVariantRead[];
    /** @format date-time */
    preorderStart?: string | null;
    /** @format date-time */
    preorderEnd?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    modelSizingType?: string;
    /**
     * @default "auto"
     * @example "auto"
     */
    sizeAdviceType?: string;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface CatalogVariantsWorkspaceWrite {
    id?: number;
}

/** Class CompositionLayout */
export interface CompositionLayoutsCompositionLayoutRead {
    id?: number;
    title?: string;
    compositions?: CompositionCompositionLayoutRead[];
}

/** Class CompositionLayout */
export interface CompositionLayoutsCompositionLayoutWrite {
    id?: number;
    title?: string;
    compositions?: string[];
}

/** Class CompositionTemplate */
export interface CompositionTemplates {
    id?: number;
    kindLabel?: string;
    kindKey?: string;
    compositionLabel?: string;
    compositionKey?: string;
    defaultBlockOrder?: string[];
}

/** Class CompositionTemplate */
export interface CompositionTemplatesCompositionLayoutRead {
    id?: number;
    kindLabel?: string;
    kindKey?: string;
    compositionLabel?: string;
    compositionKey?: string;
    defaultBlockOrder?: string[];
}

/** Class CompositionTemplate */
export interface CompositionTemplatesCompositionRead {
    id?: number;
    kindLabel?: string;
    kindKey?: string;
    compositionLabel?: string;
    compositionKey?: string;
    defaultBlockOrder?: string[];
}

/** Class CompositionTemplate */
export interface CompositionTemplatesCompositionTemplateRead {
    id?: number;
    kindLabel?: string;
    kindKey?: string;
    compositionLabel?: string;
    compositionKey?: string;
    defaultBlockOrder?: string[];
}

/** Class CompositionTemplate */
export interface CompositionTemplatesCompositionWrite {
    id?: number;
}

/** Class CompositionTemplate */
export interface CompositionTemplatesPageVersionRead {
    id?: number;
    kindLabel?: string;
    kindKey?: string;
    compositionLabel?: string;
    compositionKey?: string;
    defaultBlockOrder?: string[];
}

/** Lien entre les produits et la popin de sélection de la variante correspondante (avec des valeurs supplémentaires) */
export interface CrossSellProductsCatalogItemRead {
    id?: number;
    position: number;
    /** Product. */
    product: CatalogProductsCatalogItemRead;
}

/** Lien entre les produits et la popin de sélection de la variante correspondante (avec des valeurs supplémentaires) */
export interface CrossSellProductsCatalogProductRead {
    id?: number;
    position: number;
    /** Product. */
    product: CatalogProductsCatalogProductRead;
}

/** Lien entre les produits et la popin de sélection de la variante correspondante (avec des valeurs supplémentaires) */
export interface CrossSellProductsCatalogVariantRead {
    id?: number;
    position: number;
    /** Product. */
    product: CatalogProductsCatalogVariantRead;
}

/** Lien entre les produits et la popin de sélection de la variante correspondante (avec des valeurs supplémentaires) */
export interface CrossSellProductsCrossSellProductRead {
    id?: number;
    position: number;
    /** Product. */
    product: CatalogProductsCrossSellProductRead;
}

/** Lien entre les crossSells d'un produit et la Variant correspondante (avec des valeurs en plus) */
export interface CrossSellVariantsCatalogProductRead {
    id?: number;
}

/** Lien entre les crossSells d'un produit et la Variant correspondante (avec des valeurs en plus) */
export interface CrossSellVariantsCrossSellVariantRead {
    id?: number;
    position: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant: CatalogVariantsCrossSellVariantRead;
    translations?: Record<string, CrossSellVariantTranslationCrossSellVariantRead>;
}

/** Class EvergreenMedia */
export interface EvergreenMediasEvergreenMediaRead {
    id?: number;
    type?: string;
    pageName?: string;
    pageUrl?: string;
    mediaUrl?: string | null;
    /** @format iri-reference */
    media?: string | null;
    mediaTransformations?: Record<string, string>;
    /** @format date-time */
    updatedAt?: string | null;
}

/** Class EvergreenMedia */
export interface EvergreenMediasEvergreenMediaWrite {
    mediaUrl?: string | null;
    media?: MediasEvergreenMediaWrite | null;
    mediaTransformations?: Record<string, string>;
}

export interface FooterItemTranslations {
    id?: number;
    label?: string;
    link?: string | null;
}

export interface FooterItemTranslationsFooterItemRead {
    id?: number;
    label?: string;
    link?: string | null;
}

export interface FooterItemTranslationsFooterItemWrite {
    id?: number;
    label?: string;
    link?: string | null;
}

export interface FooterItemsFooterItemRead {
    id?: number;
    site?: SiteFooterItemRead | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    translations?: Record<string, FooterItemTranslationsFooterItemRead>;
    type: string;
    page?: PageFooterItemRead | null;
    staticPage?: string | null;
    target?: string | null;
    position?: number;
    level?: number;
    labelColor?: ColorParameterFooterItemRead | null;
    labelColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    labelIsUnderlined?: boolean;
    children?: FooterItemsFooterItemRead[];
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface FooterItemsFooterItemWrite {
    id?: number;
    site?: SiteFooterItemWrite | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    translations?: Record<string, FooterItemTranslationsFooterItemWrite>;
    type: string;
    page?: PageFooterItemWrite | null;
    staticPage?: string | null;
    target?: string | null;
    labelColor?: ColorParameterFooterItemWrite | null;
    labelColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    labelIsUnderlined?: boolean;
    parent?: FooterItemsFooterItemWrite | null;
}

/** A photo linked to a variant */
export interface MediaVariants {
    id?: number;
    originalPhoto: Medias | null;
    originalPhotoTransformations?: string[] | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    variant: StoreVariants | null;
    workspacesIds?: string[];
    catalogVariantId?: number | null;
    originalPhotoId?: any;
}

/** A photo linked to a variant */
export interface MediaVariantsCatalogItemRead {
    id?: number;
    originalPhoto: MediasCatalogItemRead | null;
    originalPhotoTransformations?: string[] | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    variant: StoreVariantsCatalogItemRead | null;
    workspacesIds?: string[];
}

/** A photo linked to a variant */
export interface MediaVariantsCatalogMultiProductRead {
    id?: number;
    originalPhoto: MediasCatalogMultiProductRead | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    workspacesIds?: string[];
}

/** A photo linked to a variant */
export interface MediaVariantsCatalogProductRead {
    id?: number;
    originalPhoto: MediasCatalogProductRead | null;
    originalPhotoTransformations?: string[] | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    workspacesIds?: string[];
}

/** A photo linked to a variant */
export interface MediaVariantsMediaRead {
    id?: number;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    variant: StoreVariantsMediaRead | null;
    workspacesIds?: string[];
    catalogVariantId?: number | null;
    originalPhotoId?: any;
}

/** A photo linked to a variant */
export interface MediaVariantsStoreMediaVariantRead {
    id?: number;
    originalPhoto: MediasStoreMediaVariantRead | null;
    originalPhotoTransformations?: string[] | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    variant: StoreVariantsStoreMediaVariantRead | null;
    workspacesIds?: string[];
    catalogVariantId?: number | null;
    originalPhotoId?: any;
}

/** A photo linked to a variant */
export interface MediaVariantsStoreMediaVariantWrite {
    originalPhoto: MediasStoreMediaVariantWrite | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    variant: StoreVariantsStoreMediaVariantWrite | null;
}

/** A photo linked to a variant */
export interface MediaVariantsStoreVariantRead {
    id?: number;
    originalPhoto: MediasStoreVariantRead | null;
    originalPhotoTransformations?: string[] | null;
    /**
     * Position to order photos inside product page
     * @default 1
     * @example 1
     */
    position: number;
    /**
     * @default true
     * @example true
     */
    enabled: boolean;
    workspacesIds?: string[];
    catalogVariantId?: number | null;
}

/** A media linked to a product variant */
export interface Medias {
    id?: number;
    mediaVariantTags?: StoreMediaVariantTags[];
    models?: Model[];
    mediaVariants?: MediaVariants[];
    name?: string;
    location?: string | null;
    /** @format date-time */
    shootedAt?: string | null;
    /** @format date-time */
    createdAt?: string;
    hardVisual?: boolean;
    newsVisual?: boolean;
    moodVisual?: boolean;
    /** @format date-time */
    lastActivatedAt?: string | null;
    /** @format duration */
    activeFor?: string;
    /** @format date-time */
    firstUsedAt?: string | null;
    enabled?: boolean;
    cdnPublicId?: string;
    width?: number | null;
    height?: number | null;
    aspect?: string | null;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasBlockRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasBlockWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasBrandRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasBrandWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasCatalogItemRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasCatalogMultiProductRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasCatalogProductRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasCompositionLayoutRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasCustomisationServiceRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasCustomisationServiceWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasEvergreenMediaWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasMediaRead {
    id?: number;
    mediaVariantTags?: StoreMediaVariantTagsMediaRead[];
    models?: ModelMediaRead[];
    mediaVariants?: MediaVariantsMediaRead[];
    name?: string;
    location?: string | null;
    /** @format date-time */
    shootedAt?: string | null;
    /** @format date-time */
    createdAt?: string;
    hardVisual?: boolean;
    newsVisual?: boolean;
    moodVisual?: boolean;
    /** @format date-time */
    lastActivatedAt?: string | null;
    /** @format duration */
    activeFor?: string;
    /** @format date-time */
    firstUsedAt?: string | null;
    enabled?: boolean;
    cdnPublicId?: string;
    aspect?: string | null;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMenuItemDropdownRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMenuItemRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMenuItemVersionRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMenuItemVersionWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasMobileContentRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMobileContentVersionRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMobileContentVersionWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasModelRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasModelWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasPageVersionList {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasPageVersionRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasPageVersionWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasPopinRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasPushProductRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasPushProductWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasSaleRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasSaleVersionWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasSaleWaitingPageRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasSaleWaitingPageWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasSiteRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasStoreMediaVariantRead {
    id?: number;
    name?: string;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasStoreMediaVariantWrite {
    id?: number;
}

/** A media linked to a product variant */
export interface MediasStoreVariantRead {
    id?: number;
    cdnPublicId?: string;
    cdnThumbnails?: string[];
    cdnSecureUrl?: string;
    video?: boolean;
    previewUrl?: string | null;
}

/** A media linked to a product variant */
export interface MediasMediaUploadMediaUpload {
    linkProducts: boolean | null;
    hardVisual?: boolean | null;
    newsVisual?: boolean | null;
    moodVisual?: boolean | null;
    workspaceId?: number | null;
    importedMediasActive?: boolean | null;
    oldMediasActive?: boolean | null;
    medias: UploadedMediaMediaUpload[] | null;
    modelTags?: string[] | null;
    variantTags?: string[] | null;
}

export interface MenuItemDropdowns {
    id?: number;
    staticPage?: string | null;
    media?: Medias | null;
    product?: CatalogVariants | null;
    catalogVariant?: CatalogVariants | null;
    /** @format iri-reference */
    page?: string | null;
    position?: number;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    target?: string | null;
    textColor?: ColorParameter | null;
    textColorOverride?: string | null;
    ctaColor?: ColorParameter | null;
    ctaColorOverride?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    mediaTransformations?: Record<string, string>;
    cardBlockOuter?: boolean;
    cardBlockType?: string;
    translations?: Record<string, MenuItemDropDownTranslation>;
}

export interface MenuItemDropdownsMenuItemDropdownRead {
    id?: number;
    staticPage?: string | null;
    media?: MediasMenuItemDropdownRead | null;
    product?: CatalogVariantsMenuItemDropdownRead | null;
    catalogVariant?: CatalogVariantsMenuItemDropdownRead | null;
    /** @format iri-reference */
    page?: string | null;
    position?: number;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    target?: string | null;
    textColor?: ColorParameterMenuItemDropdownRead | null;
    textColorOverride?: string | null;
    ctaColor?: ColorParameterMenuItemDropdownRead | null;
    ctaColorOverride?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    mediaTransformations?: Record<string, string>;
    cardBlockOuter?: boolean;
    cardBlockType?: string;
    translations?: Record<string, MenuItemDropDownTranslationMenuItemDropdownRead>;
}

export interface MenuItemDropdownsMenuItemRead {
    id?: number;
    staticPage?: string | null;
    media?: MediasMenuItemRead | null;
    product?: CatalogVariantsMenuItemRead | null;
    catalogVariant?: CatalogVariantsMenuItemRead | null;
    page?: PageMenuItemRead | null;
    position?: number;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    target?: string | null;
    textColor?: ColorParameterMenuItemRead | null;
    textColorOverride?: string | null;
    ctaColor?: ColorParameterMenuItemRead | null;
    ctaColorOverride?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    mediaTransformations?: Record<string, string>;
    cardBlockOuter?: boolean;
    cardBlockType?: string;
    translations?: Record<string, MenuItemDropDownTranslationMenuItemRead>;
}

export interface MenuItemDropdownsMenuItemVersionRead {
    id?: number;
    staticPage?: string | null;
    media?: MediasMenuItemVersionRead | null;
    product?: CatalogVariantsMenuItemVersionRead | null;
    catalogVariant?: CatalogVariantsMenuItemVersionRead | null;
    page?: PageMenuItemVersionRead | null;
    position?: number;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    target?: string | null;
    textColor?: ColorParameterMenuItemVersionRead | null;
    textColorOverride?: string | null;
    ctaColor?: ColorParameterMenuItemVersionRead | null;
    ctaColorOverride?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    mediaTransformations?: Record<string, string>;
    cardBlockOuter?: boolean;
    cardBlockType?: string;
    translations?: Record<string, MenuItemDropDownTranslationMenuItemVersionRead>;
}

export interface MenuItemDropdownsMenuItemVersionWrite {
    id?: number;
    staticPage?: string | null;
    media?: MediasMenuItemVersionWrite | null;
    product?: CatalogVariantsMenuItemVersionWrite | null;
    catalogVariant?: CatalogVariantsMenuItemVersionWrite | null;
    page?: PageMenuItemVersionWrite | null;
    position?: number;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    target?: string | null;
    textColor?: ColorParameterMenuItemVersionWrite | null;
    textColorOverride?: string | null;
    ctaColor?: ColorParameterMenuItemVersionWrite | null;
    ctaColorOverride?: string | null;
    /**
     * @default "none"
     * @example "none"
     */
    btnType?: string;
    mediaTransformations?: Record<string, string>;
    cardBlockOuter?: boolean;
    cardBlockType?: string;
    translations?: Record<string, MenuItemDropDownTranslationMenuItemVersionWrite>;
}

export interface MenuItemVersionTranslations {
    label?: string | null;
    mediaLabel?: string | null;
    link?: string | null;
}

export interface MenuItemVersionTranslationsMenuItemAttach {
    id?: number;
    label?: string | null;
    mediaLabel?: string | null;
    link?: string | null;
}

export interface MenuItemVersionTranslationsMenuItemRead {
    id?: number;
    label?: string | null;
    mediaLabel?: string | null;
    link?: string | null;
}

export interface MenuItemVersionTranslationsMenuItemVersionRead {
    id?: number;
    label?: string | null;
    mediaLabel?: string | null;
    link?: string | null;
}

export type MenuItemVersionTranslationsMenuItemVersionTranslationRead = object;

export interface MenuItemVersionTranslationsMenuItemVersionWrite {
    id?: number;
    label?: string | null;
    mediaLabel?: string | null;
    link?: string | null;
}

export interface MenuItemVersions {
    id?: number;
    menuItem?: MenuItems | null;
    workspaces?: Workspace[];
    workspacesIds?: string[];
    subCategories?: SubCategories[];
    typologies?: Typologies[];
    position?: number;
    level?: number;
    labelColor?: ColorParameter | null;
    labelColorOverride?: string | null;
    labelHoverColor?: ColorParameter | null;
    labelHoverColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    /**
     * @default "cms"
     * @example "cms"
     */
    type: string;
    target?: string | null;
    page?: Page | null;
    staticPage?: string | null;
    sale?: Sale | null;
    translations?: Record<string, MenuItemVersionTranslations>;
    divider?: boolean;
    /**
     * @default true
     * @example true
     */
    navigation?: boolean;
    landingMedia?: Medias | null;
    landingMediaTransformations?: string[] | null;
    fullWidthOnLanding?: boolean;
    animation?: boolean;
    logoType?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
    /**
     * @default true
     * @example true
     */
    showAll?: boolean;
    /**
     * @default "block_0"
     * @example "block_0"
     */
    blockType?: string;
    menuItemDropDowns?: MenuItemDropdowns[];
}

export interface MenuItemVersionsMenuItemAttach {
    id?: number;
    workspaces?: WorkspaceMenuItemAttach[];
    workspacesIds?: string[];
    subCategories?: SubCategoriesMenuItemAttach[];
    typologies?: TypologiesMenuItemAttach[];
    position?: number;
    level?: number;
    /** @format iri-reference */
    labelColor?: string | null;
    labelColorOverride?: string | null;
    /** @format iri-reference */
    labelHoverColor?: string | null;
    labelHoverColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    /**
     * @default "cms"
     * @example "cms"
     */
    type: string;
    target?: string | null;
    /** @format iri-reference */
    page?: string | null;
    staticPage?: string | null;
    sale?: SaleMenuItemAttach | null;
    translations?: Record<string, MenuItemVersionTranslationsMenuItemAttach>;
    divider?: boolean;
    /**
     * @default true
     * @example true
     */
    navigation?: boolean;
    /** @format iri-reference */
    landingMedia?: string | null;
    landingMediaTransformations?: string[] | null;
    fullWidthOnLanding?: boolean;
    animation?: boolean;
    logoType?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
    /**
     * @default true
     * @example true
     */
    showAll?: boolean;
    /**
     * @default "block_0"
     * @example "block_0"
     */
    blockType?: string;
    menuItemDropDowns?: string[];
}

export interface MenuItemVersionsMenuItemRead {
    id?: number;
    workspaces?: WorkspaceMenuItemRead[];
    workspacesIds?: string[];
    subCategories?: SubCategoriesMenuItemRead[];
    typologies?: TypologiesMenuItemRead[];
    position?: number;
    level?: number;
    labelColor?: ColorParameterMenuItemRead | null;
    labelColorOverride?: string | null;
    labelHoverColor?: ColorParameterMenuItemRead | null;
    labelHoverColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    /**
     * @default "cms"
     * @example "cms"
     */
    type: string;
    target?: string | null;
    page?: PageMenuItemRead | null;
    staticPage?: string | null;
    sale?: SaleMenuItemRead | null;
    translations?: Record<string, MenuItemVersionTranslationsMenuItemRead>;
    divider?: boolean;
    /**
     * @default true
     * @example true
     */
    navigation?: boolean;
    landingMedia?: MediasMenuItemRead | null;
    landingMediaTransformations?: string[] | null;
    fullWidthOnLanding?: boolean;
    animation?: boolean;
    logoType?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
    /**
     * @default true
     * @example true
     */
    showAll?: boolean;
    /**
     * @default "block_0"
     * @example "block_0"
     */
    blockType?: string;
    menuItemDropDowns?: MenuItemDropdownsMenuItemRead[];
}

export interface MenuItemVersionsMenuItemVersionRead {
    id?: number;
    menuItem?: MenuItemsMenuItemVersionRead | null;
    workspaces?: WorkspaceMenuItemVersionRead[];
    workspacesIds?: string[];
    subCategories?: SubCategoriesMenuItemVersionRead[];
    typologies?: TypologiesMenuItemVersionRead[];
    position?: number;
    level?: number;
    labelColor?: ColorParameterMenuItemVersionRead | null;
    labelColorOverride?: string | null;
    labelHoverColor?: ColorParameterMenuItemVersionRead | null;
    labelHoverColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    /**
     * @default "cms"
     * @example "cms"
     */
    type: string;
    target?: string | null;
    page?: PageMenuItemVersionRead | null;
    staticPage?: string | null;
    sale?: SaleMenuItemVersionRead | null;
    translations?: Record<string, MenuItemVersionTranslationsMenuItemVersionRead>;
    divider?: boolean;
    /**
     * @default true
     * @example true
     */
    navigation?: boolean;
    landingMedia?: MediasMenuItemVersionRead | null;
    landingMediaTransformations?: string[] | null;
    fullWidthOnLanding?: boolean;
    animation?: boolean;
    logoType?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
    /**
     * @default true
     * @example true
     */
    showAll?: boolean;
    /**
     * @default "block_0"
     * @example "block_0"
     */
    blockType?: string;
    menuItemDropDowns?: MenuItemDropdownsMenuItemVersionRead[];
}

export interface MenuItemVersionsMenuItemVersionWrite {
    id?: number;
    menuItem?: MenuItemsMenuItemVersionWrite | null;
    position?: number;
    labelColor?: ColorParameterMenuItemVersionWrite | null;
    labelColorOverride?: string | null;
    labelHoverColor?: ColorParameterMenuItemVersionWrite | null;
    labelHoverColorOverride?: string | null;
    labelIsBold?: boolean;
    labelIsItalic?: boolean;
    /**
     * @default "cms"
     * @example "cms"
     */
    type: string;
    target?: string | null;
    page?: PageMenuItemVersionWrite | null;
    staticPage?: string | null;
    sale?: SaleMenuItemVersionWrite | null;
    translations?: Record<string, MenuItemVersionTranslationsMenuItemVersionWrite>;
    divider?: boolean;
    /**
     * @default true
     * @example true
     */
    navigation?: boolean;
    landingMedia?: MediasMenuItemVersionWrite | null;
    landingMediaTransformations?: string[] | null;
    fullWidthOnLanding?: boolean;
    animation?: boolean;
    logoType?: string | null;
    /**
     * @default true
     * @example true
     */
    showAll?: boolean;
    /**
     * @default "block_0"
     * @example "block_0"
     */
    blockType?: string;
    menuItemDropDowns?: MenuItemDropdownsMenuItemVersionWrite[];
}

export interface MenuItems {
    id?: number;
    level?: number;
    parentsIds?: string[];
    parent?: MenuItems | null;
    children?: MenuItems[];
    /**
     *
     *
     * Unmapped field used for list serialization only
     */
    menuItemVersionsForWorkspace?: Collection;
    menuItemVersions?: MenuItemVersions[];
}

export interface MenuItemsMenuItemAttach {
    id?: number;
    level?: number;
    parentsIds?: string[];
    children?: MenuItemsMenuItemAttach[];
    menuItemVersions?: MenuItemVersionsMenuItemAttach[];
}

export interface MenuItemsMenuItemList {
    id?: number;
    level?: number;
    parentsIds?: string[];
    children?: MenuItemsMenuItemList[];
    /**
     *
     *
     * Unmapped field used for list serialization only
     */
    menuItemVersionsForWorkspace?: CollectionMenuItemList;
}

export interface MenuItemsMenuItemRead {
    id?: number;
    level?: number;
    parentsIds?: string[];
    parent?: MenuItemsMenuItemRead | null;
    menuItemVersions?: MenuItemVersionsMenuItemRead[];
}

export interface MenuItemsMenuItemVersionRead {
    id?: number;
    level?: number;
    parentsIds?: string[];
    parent?: MenuItemsMenuItemVersionRead | null;
}

export interface MenuItemsMenuItemVersionWrite {
    id?: number;
    parent?: MenuItemsMenuItemVersionWrite | null;
}

export interface MobileContentSlideTranslationsMobileContentRead {
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    link?: string | null;
}

export type MobileContentSlideTranslationsMobileContentSlideTranslationRead = object;

export interface MobileContentSlideTranslationsMobileContentVersionRead {
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    link?: string | null;
}

export interface MobileContentSlideTranslationsMobileContentVersionWrite {
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    link?: string | null;
}

export interface MobileContentSlidesMobileContentRead {
    id?: number;
    position?: number;
    media?: MediasMobileContentRead | null;
    mediaTransformations?: Record<string, string>;
    page?: PageMobileContentRead | null;
    variant?: CatalogVariantsMobileContentRead | null;
    translations?: Record<string, MobileContentSlideTranslationsMobileContentRead>;
}

export type MobileContentSlidesMobileContentSlideRead = object;

export interface MobileContentSlidesMobileContentVersionRead {
    id?: number;
    position?: number;
    media?: MediasMobileContentVersionRead | null;
    mediaTransformations?: Record<string, string>;
    page?: PageMobileContentVersionRead | null;
    variant?: CatalogVariantsMobileContentVersionRead | null;
    translations?: Record<string, MobileContentSlideTranslationsMobileContentVersionRead>;
}

export interface MobileContentSlidesMobileContentVersionWrite {
    id?: number;
    position?: number;
    media?: MediasMobileContentVersionWrite | null;
    mediaTransformations?: Record<string, string>;
    page?: PageMobileContentVersionWrite | null;
    variant?: CatalogVariantsMobileContentVersionWrite | null;
    translations?: Record<string, MobileContentSlideTranslationsMobileContentVersionWrite>;
}

export interface MobileContentVersionTranslationsMobileContentRead {
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    ctaText?: string | null;
    ctaLink?: string | null;
    leftLabel?: string | null;
    rightLabel?: string | null;
    landingTitle?: string | null;
    landingDescription?: string | null;
}

export interface MobileContentVersionTranslationsMobileContentVersionRead {
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    ctaText?: string | null;
    ctaLink?: string | null;
    leftLabel?: string | null;
    rightLabel?: string | null;
    landingTitle?: string | null;
    landingDescription?: string | null;
}

export type MobileContentVersionTranslationsMobileContentVersionTranslationRead = object;

export interface MobileContentVersionTranslationsMobileContentVersionWrite {
    id?: number;
    title?: string | null;
    subTitle?: string | null;
    description?: string | null;
    ctaText?: string | null;
    ctaLink?: string | null;
    leftLabel?: string | null;
    rightLabel?: string | null;
    landingTitle?: string | null;
    landingDescription?: string | null;
}

export interface MobileContentVersionsMobileContentRead {
    id?: number;
    workspaces?: WorkspaceMobileContentRead[];
    workspacesIds?: string[];
    position: number;
    type: string;
    homepageType?: string | null;
    redirectionType?: string | null;
    merchType?: string | null;
    titleType?: string | null;
    cmsType?: string | null;
    staticPage?: string | null;
    /** @format date-time */
    displayedDate?: string | null;
    darkBackground?: boolean | null;
    largeBlock?: boolean | null;
    hiddenTitleArrow?: boolean | null;
    sale?: SaleMobileContentRead | null;
    page?: PageMobileContentRead | null;
    variant?: CatalogVariantsMobileContentRead | null;
    media?: MediasMobileContentRead | null;
    portrait?: boolean;
    mediaTransformations?: Record<string, string>;
    slides?: MobileContentSlidesMobileContentRead[];
    landingMedia?: MediasMobileContentRead | null;
    landingMediaTransformations?: string[] | null;
    translations?: Record<string, MobileContentVersionTranslationsMobileContentRead>;
}

export interface MobileContentVersionsMobileContentVersionRead {
    id?: number;
    mobileContent?: MobileContentsMobileContentVersionRead | null;
    workspaces?: WorkspaceMobileContentVersionRead[];
    workspacesIds?: string[];
    position: number;
    type: string;
    homepageType?: string | null;
    redirectionType?: string | null;
    merchType?: string | null;
    titleType?: string | null;
    cmsType?: string | null;
    staticPage?: string | null;
    /** @format date-time */
    displayedDate?: string | null;
    darkBackground?: boolean | null;
    largeBlock?: boolean | null;
    hiddenTitleArrow?: boolean | null;
    sale?: SaleMobileContentVersionRead | null;
    page?: PageMobileContentVersionRead | null;
    variant?: CatalogVariantsMobileContentVersionRead | null;
    media?: MediasMobileContentVersionRead | null;
    portrait?: boolean;
    mediaTransformations?: Record<string, string>;
    slides?: MobileContentSlidesMobileContentVersionRead[];
    landingMedia?: MediasMobileContentVersionRead | null;
    landingMediaTransformations?: string[] | null;
    translations?: Record<string, MobileContentVersionTranslationsMobileContentVersionRead>;
}

export interface MobileContentVersionsMobileContentVersionWrite {
    id?: number;
    mobileContent?: MobileContentsMobileContentVersionWrite | null;
    position: number;
    type: string;
    homepageType?: string | null;
    redirectionType?: string | null;
    merchType?: string | null;
    titleType?: string | null;
    cmsType?: string | null;
    staticPage?: string | null;
    /** @format date-time */
    displayedDate?: string | null;
    darkBackground?: boolean | null;
    largeBlock?: boolean | null;
    hiddenTitleArrow?: boolean | null;
    sale?: SaleMobileContentVersionWrite | null;
    page?: PageMobileContentVersionWrite | null;
    variant?: CatalogVariantsMobileContentVersionWrite | null;
    media?: MediasMobileContentVersionWrite | null;
    portrait?: boolean;
    mediaTransformations?: Record<string, string>;
    slides?: MobileContentSlidesMobileContentVersionWrite[];
    landingMedia?: MediasMobileContentVersionWrite | null;
    landingMediaTransformations?: string[] | null;
    translations?: Record<string, MobileContentVersionTranslationsMobileContentVersionWrite>;
}

export interface MobileContentsMobileContentList {
    id?: number;
    /**
     *
     *
     * Unmapped field used for list serialization only
     */
    mobileContentVersionsForWorkspace?: CollectionMobileContentList;
}

export interface MobileContentsMobileContentRead {
    id?: number;
    mobileContentVersions?: MobileContentVersionsMobileContentRead[];
}

export interface MobileContentsMobileContentVersionRead {
    id?: number;
}

export interface MobileContentsMobileContentVersionWrite {
    id?: number;
}

export type MobileContentsMobileContentWrite = object;

/** Class PageVersionTranslation */
export interface PageVersionTranslations {
    id?: number;
    title?: string | null;
    path?: string | null;
    titleMeta?: string | null;
    descriptionMeta?: string | null;
    keywordsMeta?: string | null;
    alternateTitleNews?: string | null;
    subTitleNews?: string | null;
    descriptionNews?: string | null;
    legends?: string[] | null;
    labels?: string[] | null;
    accessOptionsHtmlText?: string | null;
    accessOptionsCtaText?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsBlockRead {
    id?: number;
    title?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsFooterItemRead {
    id?: number;
    title?: string | null;
    path?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsMenuItemRead {
    id?: number;
    title?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsMenuItemVersionRead {
    id?: number;
    title?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsMobileContentRead {
    id?: number;
    title?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsMobileContentVersionRead {
    id?: number;
    title?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsPageRead {
    id?: number;
    title?: string | null;
    path?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsPageVersionList {
    id?: number;
    title?: string | null;
    path?: string | null;
    titleMeta?: string | null;
    descriptionMeta?: string | null;
    keywordsMeta?: string | null;
    alternateTitleNews?: string | null;
    subTitleNews?: string | null;
    descriptionNews?: string | null;
    legends?: string[] | null;
    labels?: string[] | null;
    accessOptionsHtmlText?: string | null;
    accessOptionsCtaText?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsPageVersionRead {
    id?: number;
    title?: string | null;
    path?: string | null;
    titleMeta?: string | null;
    descriptionMeta?: string | null;
    keywordsMeta?: string | null;
    alternateTitleNews?: string | null;
    subTitleNews?: string | null;
    descriptionNews?: string | null;
    legends?: string[] | null;
    labels?: string[] | null;
    accessOptionsHtmlText?: string | null;
    accessOptionsCtaText?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsPageVersionWrite {
    id?: number;
    title?: string | null;
    path?: string | null;
    titleMeta?: string | null;
    descriptionMeta?: string | null;
    keywordsMeta?: string | null;
    alternateTitleNews?: string | null;
    subTitleNews?: string | null;
    descriptionNews?: string | null;
    legends?: string[] | null;
    labels?: string[] | null;
    accessOptionsHtmlText?: string | null;
    accessOptionsCtaText?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsPageWrite {
    id?: number;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsPreFooterRead {
    id?: number;
    title?: string | null;
    path?: string | null;
}

/** Class PageVersionTranslation */
export interface PageVersionTranslationsWorkspaceRead {
    id?: number;
    title?: string | null;
}

export interface PageVersions {
    id?: number;
    page: Page | null;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslations>;
    compositions?: Composition[];
    rubric?: string | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    homepage?: boolean;
    hasParent?: any;
    level?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    subCategories?: SubCategories[];
    typologies?: Typologies[];
    sale?: Sale | null;
    draft?: PageVersions | null;
    hasDraft?: boolean;
    media?: Medias | null;
    labelColor?: ColorParameter | null;
    labelColorOverride?: string | null;
    /**
     * @default "auto"
     * @example "auto"
     */
    menuTextColor: string;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameter | null;
    borderColorOverride?: string | null;
    sizeFilterEnabled?: boolean;
    colorFilterEnabled?: boolean;
    typologyFilterEnabled?: boolean;
    /**
     * @default "free"
     * @example "free"
     */
    accessOptionsType?: string;
    accessOptionsMedia?: Medias | null;
    accessOptionsMediaTransformations?: string[] | null;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsCtaColor?: string;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsColor?: string;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface PageVersionsBlockRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsBlockRead>;
}

export interface PageVersionsCompositionRead {
    id?: number;
}

export interface PageVersionsCompositionWrite {
    id?: number;
}

export interface PageVersionsFooterItemRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsFooterItemRead>;
}

export interface PageVersionsMenuItemRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsMenuItemRead>;
}

export interface PageVersionsMenuItemVersionRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsMenuItemVersionRead>;
}

export interface PageVersionsMobileContentRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsMobileContentRead>;
}

export interface PageVersionsMobileContentVersionRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsMobileContentVersionRead>;
}

export interface PageVersionsPageRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsPageRead>;
    compositions?: string[];
    rubric?: string | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    homepage?: boolean;
    draft?: PageVersionsPageRead | null;
    hasDraft?: boolean;
    labelColor?: ColorParameterPageRead | null;
    labelColorOverride?: string | null;
    /**
     * @default "auto"
     * @example "auto"
     */
    menuTextColor: string;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterPageRead | null;
    borderColorOverride?: string | null;
    sizeFilterEnabled?: boolean;
    colorFilterEnabled?: boolean;
    typologyFilterEnabled?: boolean;
    /**
     * @default "free"
     * @example "free"
     */
    accessOptionsType?: string;
    /** @format iri-reference */
    accessOptionsMedia?: string | null;
    accessOptionsMediaTransformations?: string[] | null;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsCtaColor?: string;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsColor?: string;
}

export interface PageVersionsPageVersionList {
    id?: number;
    page: PagePageVersionList | null;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsPageVersionList>;
    rubric?: string | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    homepage?: boolean;
    level?: number;
    /** @format date-time */
    updatedAt?: string | null;
    subCategories?: SubCategoriesPageVersionList[];
    typologies?: TypologiesPageVersionList[];
    sale?: SalePageVersionList | null;
    hasDraft?: boolean;
    /** @format iri-reference */
    labelColor?: string | null;
    labelColorOverride?: string | null;
    /**
     * @default "auto"
     * @example "auto"
     */
    menuTextColor: string;
    borderColorEnabled?: boolean;
    /** @format iri-reference */
    borderColor?: string | null;
    borderColorOverride?: string | null;
    sizeFilterEnabled?: boolean;
    colorFilterEnabled?: boolean;
    typologyFilterEnabled?: boolean;
    /**
     * @default "free"
     * @example "free"
     */
    accessOptionsType?: string;
    accessOptionsMedia?: MediasPageVersionList | null;
    accessOptionsMediaTransformations?: string[] | null;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsCtaColor?: string;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsColor?: string;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
}

export interface PageVersionsPageVersionRead {
    id?: number;
    page: PagePageVersionRead | null;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsPageVersionRead>;
    compositions?: CompositionPageVersionRead[];
    rubric?: string | null;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    homepage?: boolean;
    hasParent?: any;
    level?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    subCategories?: SubCategoriesPageVersionRead[];
    typologies?: TypologiesPageVersionRead[];
    sale?: SalePageVersionRead | null;
    draft?: PageVersionsPageVersionRead | null;
    hasDraft?: boolean;
    media?: MediasPageVersionRead | null;
    labelColor?: ColorParameterPageVersionRead | null;
    labelColorOverride?: string | null;
    /**
     * @default "auto"
     * @example "auto"
     */
    menuTextColor: string;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterPageVersionRead | null;
    borderColorOverride?: string | null;
    sizeFilterEnabled?: boolean;
    colorFilterEnabled?: boolean;
    typologyFilterEnabled?: boolean;
    /**
     * @default "free"
     * @example "free"
     */
    accessOptionsType?: string;
    accessOptionsMedia?: MediasPageVersionRead | null;
    accessOptionsMediaTransformations?: string[] | null;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsCtaColor?: string;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsColor?: string;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface PageVersionsPageVersionWrite {
    id?: number;
    page: PagePageVersionWrite | null;
    translations?: Record<string, PageVersionTranslationsPageVersionWrite>;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    homepage?: boolean;
    subCategories?: SubCategoriesPageVersionWrite[];
    typologies?: TypologiesPageVersionWrite[];
    sale?: SalePageVersionWrite | null;
    media?: MediasPageVersionWrite | null;
    labelColor?: ColorParameterPageVersionWrite | null;
    labelColorOverride?: string | null;
    /**
     * @default "auto"
     * @example "auto"
     */
    menuTextColor: string;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterPageVersionWrite | null;
    borderColorOverride?: string | null;
    sizeFilterEnabled?: boolean;
    colorFilterEnabled?: boolean;
    typologyFilterEnabled?: boolean;
    /**
     * @default "free"
     * @example "free"
     */
    accessOptionsType?: string;
    accessOptionsMedia?: MediasPageVersionWrite | null;
    accessOptionsMediaTransformations?: string[] | null;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsCtaColor?: string;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsColor?: string;
}

export interface PageVersionsPageWrite {
    id?: number;
    translations?: Record<string, PageVersionTranslationsPageWrite>;
    /**
     * @default true
     * @example true
     */
    enabled?: boolean;
    homepage?: boolean;
    labelColor?: ColorParameterPageWrite | null;
    labelColorOverride?: string | null;
    /**
     * @default "auto"
     * @example "auto"
     */
    menuTextColor: string;
    borderColorEnabled?: boolean;
    borderColor?: ColorParameterPageWrite | null;
    borderColorOverride?: string | null;
    sizeFilterEnabled?: boolean;
    colorFilterEnabled?: boolean;
    typologyFilterEnabled?: boolean;
    /**
     * @default "free"
     * @example "free"
     */
    accessOptionsType?: string;
    /** @format iri-reference */
    accessOptionsMedia?: string | null;
    accessOptionsMediaTransformations?: string[] | null;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsCtaColor?: string;
    /**
     * @default "white"
     * @example "white"
     */
    accessOptionsColor?: string;
}

export interface PageVersionsPreFooterRead {
    id?: number;
    workspacesIds?: string[];
    translations?: Record<string, PageVersionTranslationsPreFooterRead>;
}

export interface PageVersionsWorkspaceList {
    id?: number;
}

export interface PageVersionsWorkspaceRead {
    id?: number;
    page: PageWorkspaceRead | null;
    translations?: Record<string, PageVersionTranslationsWorkspaceRead>;
    homepage?: boolean;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface PageVersionsWorkspaceList {
    id?: number;
}

export interface PopinRegion {
    id?: number;
    regionCode: string;
    countryCode: string;
}

export interface PopinRegionPopinRead {
    id?: number;
    regionCode: string;
    countryCode: string;
}

export interface PopinTranslations {
    id?: number;
    title?: string | null;
    content?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface PopinTranslationsPopinRead {
    id?: number;
    title?: string | null;
    content?: string | null;
    ctaText?: string | null;
    link?: string | null;
}

export interface PopinsPopinRead {
    id?: number;
    site?: SitePopinRead;
    sites?: SitePopinRead[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    active?: boolean;
    enabled?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    page?: PagePopinRead | null;
    staticPage?: string | null;
    catalogVariant?: CatalogVariantsPopinRead | null;
    target?: string | null;
    /**
     * @default "popin"
     * @example "popin"
     */
    format: string;
    media?: MediasPopinRead | null;
    mediaTransformations?: Record<string, string>;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string;
    svgName?: string | null;
    colorPrimary?: ColorParameterPopinRead | null;
    colorPrimaryOverride?: string | null;
    colorSecondary?: ColorParameterPopinRead | null;
    colorSecondaryOverride?: string | null;
    oncePerSession?: boolean;
    allPages?: boolean;
    nbPages?: number;
    pages?: PagePopinRead[];
    accountPages?: string[] | null;
    checkoutPages?: string[] | null;
    staticPages?: string[] | null;
    translations?: Record<string, PopinTranslationsPopinRead>;
    countries?: CountryPopinRead[];
    regions?: PopinRegionPopinRead[];
    closeCtaColor?: ColorParameterPopinRead | null;
    closeCtaColorOverride?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
    /**
     *
     *
     * Unmapped field used for list serialization only
     * All pages from sites
     */
    allSitesPages?: PagePopinRead[];
    addToCart?: boolean;
    internalTitle?: string | null;
}

export interface PopinsPopinInputPopinWrite {
    id?: number | null;
    site?: SitePopinWrite | null;
    sites?: SitePopinWrite[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    enabled?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    page?: PagePopinWrite | null;
    staticPage?: string | null;
    catalogVariant?: CatalogVariantPopinWrite | null;
    /**
     * @default "_self"
     * @example "_self"
     */
    target?: string | null;
    /**
     * @default "popin"
     * @example "popin"
     */
    format?: string | null;
    media?: MediaPopinWrite | null;
    mediaTransformations?: Record<string, string>;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string | null;
    svgName?: string | null;
    colorPrimary?: ColorPopinWrite | null;
    colorPrimaryOverride?: string | null;
    colorSecondary?: ColorPopinWrite | null;
    colorSecondaryOverride?: string | null;
    oncePerSession?: boolean;
    allPages?: boolean;
    pages?: PagePopinWrite[];
    accountPages?: string[];
    checkoutPages?: string[];
    staticPages?: string[];
    translations?: Record<string, PopinTranslationPopinWrite>;
    countries?: CountryPopinWrite[];
    regions?: RegionPopinWrite[];
    closeCtaColor?: ColorPopinWrite | null;
    closeCtaColorOverride?: string | null;
    addToCart?: boolean;
    internalTitle?: string | null;
}

export interface PopinsPopinOutputPopinRead {
    id?: number | null;
    site?: SitePopinRead | null;
    sites?: SitePopinRead[];
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    active?: boolean;
    enabled?: boolean;
    /**
     * @default "none"
     * @example "none"
     */
    linkType?: string;
    page?: PagePopinRead | null;
    staticPage?: string | null;
    catalogVariant?: CatalogVariantPopinRead | null;
    /**
     * @default "_self"
     * @example "_self"
     */
    target?: string | null;
    /**
     * @default "popin"
     * @example "popin"
     */
    format?: string | null;
    media?: MediaPopinRead | null;
    mediaTransformations?: Record<string, string>;
    /**
     * @default "image"
     * @example "image"
     */
    mediaType?: string | null;
    svgName?: string | null;
    colorPrimary?: ColorPopinRead | null;
    colorPrimaryOverride?: string | null;
    colorSecondary?: ColorPopinRead | null;
    colorSecondaryOverride?: string | null;
    oncePerSession?: boolean;
    allPages?: boolean;
    nbPages?: number;
    pages?: PagePopinRead[];
    accountPages?: string[];
    checkoutPages?: string[];
    staticPages?: string[];
    translations?: Record<string, PopinTranslationPopinRead>;
    countries?: CountryPopinRead[];
    regions?: RegionPopinRead[];
    closeCtaColor?: ColorPopinRead | null;
    closeCtaColorOverride?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: Record<string, string>;
    translationPropertiesStatus?: Record<string, string>;
    allSitesPages?: PagePopinRead[];
    addToCart?: boolean;
    internalTitle?: string | null;
}

export interface PreFooterItem {
    id?: number;
    translations?: Record<string, string>;
    /**
     * @default "link"
     * @example "link"
     */
    type: string;
    position?: number;
    /**
     * @default "_self"
     * @example "_self"
     */
    target: string | null;
    /** @format iri-reference */
    page?: string | null;
    staticPage?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface PreFooterItemPreFooterRead {
    id?: number;
    translations?: Record<string, PreFooterItemTranslationPreFooterRead>;
    /**
     * @default "link"
     * @example "link"
     */
    type: string;
    position?: number;
    /**
     * @default "_self"
     * @example "_self"
     */
    target: string | null;
    page?: PagePreFooterRead | null;
    staticPage?: string | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface PreFooterItemPreFooterWrite {
    id?: number;
    translations?: Record<string, PreFooterItemTranslationPreFooterWrite>;
    /**
     * @default "link"
     * @example "link"
     */
    type: string;
    position?: number;
    /**
     * @default "_self"
     * @example "_self"
     */
    target: string | null;
    page?: PagePreFooterWrite | null;
    staticPage?: string | null;
}

export interface PreFooterItemTranslation {
    id?: number;
    label?: string | null;
    description?: string | null;
    link?: string | null;
}

export interface PreFooterItemTranslationPreFooterRead {
    id?: number;
    label?: string | null;
    description?: string | null;
    link?: string | null;
}

export interface PreFooterItemTranslationPreFooterWrite {
    id?: number;
    label?: string | null;
    description?: string | null;
    link?: string | null;
}

export interface PreFooterPreFooterRead {
    id?: number;
    site: SitePreFooterRead | null;
    items?: PreFooterItemPreFooterRead[];
}

export interface PreFooterPreFooterWrite {
    id?: number;
    site: SitePreFooterWrite | null;
    items?: PreFooterItemPreFooterWrite[];
}

export interface PushProductsPushProductRead {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    sites?: SitePushProductRead[];
    active?: boolean;
    enabled: boolean;
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    variants?: CatalogVariantsPushProductRead[];
    media?: MediasPushProductRead | null;
    mediaTransformations?: Record<string, string>;
    translations?: Record<string, PushProductTranslationPushProductRead>;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

export interface PushProductsPushProductWrite {
    id?: number;
    sites?: SitePushProductWrite[];
    enabled: boolean;
    /** @format date-time */
    beginOn?: string | null;
    /** @format date-time */
    finishOn?: string | null;
    variants?: CatalogVariantsPushProductWrite[];
    media?: MediasPushProductWrite | null;
    mediaTransformations?: Record<string, string>;
    translations?: Record<string, PushProductTranslationPushProductWrite>;
}

export interface ReinsuranceTranslations {
    id?: number;
    link?: string | null;
    content?: string | null;
}

export interface ReinsuranceTranslationsReinsuranceRead {
    id?: number;
    link?: string | null;
    content?: string | null;
}

export interface ReinsuranceTranslationsSaleRead {
    id?: number;
    link?: string | null;
    content?: string | null;
}

export interface ReinsuranceTranslationsSaleVersionWrite {
    id?: number;
    link?: string | null;
    content?: string | null;
}

export interface ReinsuranceTranslationsSaleWaitingPageRead {
    id?: number;
    link?: string | null;
    content?: string | null;
}

export interface ReinsuranceTranslationsSaleWaitingPageWrite {
    id?: number;
    link?: string | null;
    content?: string | null;
}

/** Role, a group of permissions. */
export interface RoleRoleRead {
    id?: number;
    /** Name of the role (from Keycloak). */
    name?: string;
    permissions?: string[] | null;
}

/** Role, a group of permissions. */
export interface RoleRoleWrite {
    id?: number;
    /** Name of the role (from Keycloak). */
    name?: string;
    permissions?: string[] | null;
}

export interface SaleVersionTranslations {
    id?: number;
    label?: string;
    path?: string;
}

export interface SaleVersionTranslationsCatalogProductRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsMenuItemRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsMenuItemVersionRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsMobileContentRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsMobileContentVersionRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsPageVersionList {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsPageVersionRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsSaleList {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsSaleRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsSaleVersionList {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsSaleVersionRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsSaleVersionWrite {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsStoreVariantRead {
    id?: number;
    label?: string;
}

export interface SaleVersionTranslationsWorkspaceRead {
    id?: number;
    label?: string;
}

export interface SaleVersions {
    id?: number;
    sale?: Sale | null;
    accessRestriction?: boolean;
    translations?: Record<string, SaleVersionTranslations>;
    userTypes?: UserType[];
    workspacesIds?: string[];
    /** @format date-time */
    beginOn?: string | null;
    nbVariants?: any;
    /** @format date-time */
    updatedAt?: string | null;
    /** @format iri-reference */
    saleWaitingPage?: string | null;
    enabled?: boolean;
    creationPending?: boolean;
}

export interface SaleVersionsCatalogProductRead {
    id?: number;
    sale?: SaleCatalogProductRead | null;
    translations?: Record<string, SaleVersionTranslationsCatalogProductRead>;
    workspacesIds?: string[];
    enabled?: boolean;
    path?: string | null;
}

export interface SaleVersionsCatalogVariantRead {
    id?: number;
    sale?: SaleCatalogVariantRead | null;
    workspacesIds?: string[];
}

export interface SaleVersionsMenuItemRead {
    id?: number;
    translations?: Record<string, SaleVersionTranslationsMenuItemRead>;
    workspacesIds?: string[];
}

export interface SaleVersionsMenuItemVersionRead {
    id?: number;
    translations?: Record<string, SaleVersionTranslationsMenuItemVersionRead>;
    workspacesIds?: string[];
}

export interface SaleVersionsMobileContentRead {
    id?: number;
    translations?: Record<string, SaleVersionTranslationsMobileContentRead>;
    workspacesIds?: string[];
}

export interface SaleVersionsMobileContentVersionRead {
    id?: number;
    translations?: Record<string, SaleVersionTranslationsMobileContentVersionRead>;
    workspacesIds?: string[];
}

export interface SaleVersionsPageVersionList {
    translations?: Record<string, SaleVersionTranslationsPageVersionList>;
    workspacesIds?: string[];
    enabled?: boolean;
}

export interface SaleVersionsPageVersionRead {
    translations?: Record<string, SaleVersionTranslationsPageVersionRead>;
    workspacesIds?: string[];
    enabled?: boolean;
}

export interface SaleVersionsSaleList {
    id?: number;
    translations?: Record<string, SaleVersionTranslationsSaleList>;
    workspacesIds?: string[];
    enabled?: boolean;
}

export interface SaleVersionsSaleRead {
    id?: number;
    accessRestriction?: boolean;
    translations?: Record<string, SaleVersionTranslationsSaleRead>;
    userTypes?: UserTypeSaleRead[];
    firstWorkspace?: WorkspaceSaleRead | null;
    workspaces?: WorkspaceSaleRead[];
    workspacesIds?: string[];
    workspacesSiteIds?: string[];
    /** @format date-time */
    beginOn?: string | null;
    nbVariants?: any;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    saleWaitingPage?: SaleWaitingPagesSaleRead | null;
    enabled?: boolean;
    path?: string | null;
}

export interface SaleVersionsSaleVersionList {
    id?: number;
    sale?: SaleSaleVersionList | null;
    translations?: Record<string, SaleVersionTranslationsSaleVersionList>;
    workspacesIds?: string[];
    /** @format date-time */
    beginOn?: string | null;
    nbVariants?: any;
    /** @format date-time */
    updatedAt?: string | null;
    enabled?: boolean;
    creationPending?: boolean;
}

export interface SaleVersionsSaleVersionRead {
    id?: number;
    sale?: SaleSaleVersionRead | null;
    translations?: Record<string, SaleVersionTranslationsSaleVersionRead>;
    workspacesIds?: string[];
    /** @format date-time */
    beginOn?: string | null;
    nbVariants?: any;
    /** @format date-time */
    updatedAt?: string | null;
    enabled?: boolean;
    creationPending?: boolean;
}

export interface SaleVersionsSaleVersionWrite {
    id?: number;
    sale?: SaleSaleVersionWrite | null;
    accessRestriction?: boolean;
    translations?: Record<string, SaleVersionTranslationsSaleVersionWrite>;
    userTypes?: UserTypeSaleVersionWrite[];
    /** @format date-time */
    beginOn?: string | null;
    saleWaitingPage?: SaleWaitingPagesSaleVersionWrite | null;
    enabled?: boolean;
}

export interface SaleVersionsSaleWaitingPageRead {
    id?: number;
    sale?: SaleSaleWaitingPageRead | null;
    workspacesIds?: string[];
}

export interface SaleVersionsSaleWaitingPageWrite {
    id?: number;
}

export interface SaleVersionsStoreVariantRead {
    id?: number;
    sale?: SaleStoreVariantRead | null;
    translations?: Record<string, SaleVersionTranslationsStoreVariantRead>;
    workspacesIds?: string[];
    enabled?: boolean;
    path?: string | null;
}

export interface SaleVersionsWorkspaceRead {
    id?: number;
    sale?: SaleWorkspaceRead | null;
    translations?: Record<string, SaleVersionTranslationsWorkspaceRead>;
    /** @format date-time */
    updatedAt?: string | null;
    path?: string | null;
}

export interface SaleVersionsWorkspaceWrite {
    id?: number;
    variants?: CatalogVariantsWorkspaceWrite[];
}

export interface SaleWaitingPageTranslations {
    id?: number;
    header?: string | null;
    labelCTA?: string | null;
    linkCTA?: string | null;
}

export interface SaleWaitingPageTranslationsSaleRead {
    id?: number;
    header?: string | null;
    labelCTA?: string | null;
    linkCTA?: string | null;
}

export interface SaleWaitingPageTranslationsSaleVersionWrite {
    id?: number;
    header?: string | null;
    labelCTA?: string | null;
    linkCTA?: string | null;
}

export interface SaleWaitingPageTranslationsSaleWaitingPageRead {
    id?: number;
    header?: string | null;
    labelCTA?: string | null;
    linkCTA?: string | null;
}

export interface SaleWaitingPageTranslationsSaleWaitingPageWrite {
    id?: number;
    header?: string | null;
    labelCTA?: string | null;
    linkCTA?: string | null;
}

export interface SaleWaitingPagesSaleRead {
    id?: number;
    media?: MediasSaleRead | null;
    reinsurances?: ReinsuranceSaleRead[];
    translations?: Record<string, SaleWaitingPageTranslationsSaleRead>;
}

export interface SaleWaitingPagesSaleVersionWrite {
    id?: number;
    media?: MediasSaleVersionWrite | null;
    reinsurances?: ReinsuranceSaleVersionWrite[];
    translations?: Record<string, SaleWaitingPageTranslationsSaleVersionWrite>;
}

export interface SaleWaitingPagesSaleWaitingPageRead {
    id?: number;
    media?: MediasSaleWaitingPageRead | null;
    saleVersion?: SaleVersionsSaleWaitingPageRead | null;
    reinsurances?: ReinsuranceSaleWaitingPageRead[];
    translations?: Record<string, SaleWaitingPageTranslationsSaleWaitingPageRead>;
}

export interface SaleWaitingPagesSaleWaitingPageWrite {
    id?: number;
    media?: MediasSaleWaitingPageWrite | null;
    saleVersion?: SaleVersionsSaleWaitingPageWrite | null;
    reinsurances?: ReinsuranceSaleWaitingPageWrite[];
    translations?: Record<string, SaleWaitingPageTranslationsSaleWaitingPageWrite>;
}

export interface SequencingSequencingItemRead {
    items?: SequencingItemSequencingItemRead[];
}

export interface SequencingSequencingRead {
    id?: number;
    name: string | null;
    /**
     * Status of this sequencing (ENABLED, DISABLED)
     * @default "enabled"
     * @example "enabled"
     */
    status?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    /** @format iri-reference */
    brand?: string;
}

export interface SequencingSequencingWrite {
    id?: number;
    name: string | null;
    /**
     * Status of this sequencing (ENABLED, DISABLED)
     * @default "enabled"
     * @example "enabled"
     */
    status?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string | null;
    /** @format iri-reference */
    brand?: string;
}

export interface SlideProductLinkTranslations {
    id?: number;
    link?: string | null;
    name?: string | null;
    declinations?: string | null;
    availabilities?: string | null;
    advices?: string | null;
}

export interface SlideTranslations {
    id?: number;
    htmlTitle?: string | null;
}

export interface SlideTranslationsBlockRead {
    id?: number;
    htmlTitle?: string | null;
}

export interface SlideTranslationsBlockWrite {
    id?: number;
    htmlTitle?: string | null;
}

export type StoreMediaVariantTags = object;

export interface StoreMediaVariantTagsMediaRead {
    id?: number;
    /**
     * A product variant
     * Une déclinaison d'un modèle
     */
    variant?: CatalogVariantsMediaRead;
    position?: number;
}

export type StoreMediaVariantTagsStoreMediaVariantTagRead = object;

export interface StoreVariantTranslations {
    id?: number;
    locale?: string;
    /**
     *
     * Gedmo\Slug(fields={"label"}) # TODO
     */
    path?: string | null;
    additionalDescription?: string | null;
    description?: string | null;
    preorderText?: string | null;
    studioEditorialCTAText?: string | null;
    studioEditorialCTALink?: string | null;
    expertiseText?: string | null;
    leathercraftDetail?: string | null;
    leathercraftSecondaryTitle?: string | null;
    leathercraftSecondaryText?: string | null;
    leathercraftSecondaryCTAText?: string | null;
    leathercraftSecondaryCTALink?: string | null;
    titlePopinCrossSellProduct?: string | null;
    /** @maxLength 700 */
    descriptionPopinCrossSellProduct?: string | null;
}

export interface StoreVariantTranslationsCatalogItemRead {
    locale?: string;
    /**
     *
     * Gedmo\Slug(fields={"label"}) # TODO
     */
    path?: string | null;
    additionalDescription?: string | null;
    description?: string | null;
    preorderText?: string | null;
    studioEditorialCTAText?: string | null;
    studioEditorialCTALink?: string | null;
    expertiseText?: string | null;
    leathercraftDetail?: string | null;
    leathercraftSecondaryTitle?: string | null;
    leathercraftSecondaryText?: string | null;
    leathercraftSecondaryCTAText?: string | null;
    leathercraftSecondaryCTALink?: string | null;
    titlePopinCrossSellProduct?: string | null;
    /** @maxLength 700 */
    descriptionPopinCrossSellProduct?: string | null;
}

export interface StoreVariantTranslationsCatalogProductRead {
    id?: number;
    locale?: string;
    /**
     *
     * Gedmo\Slug(fields={"label"}) # TODO
     */
    path?: string | null;
    additionalDescription?: string | null;
    description?: string | null;
    preorderText?: string | null;
    studioEditorialCTAText?: string | null;
    studioEditorialCTALink?: string | null;
    expertiseText?: string | null;
    leathercraftDetail?: string | null;
    leathercraftSecondaryTitle?: string | null;
    leathercraftSecondaryText?: string | null;
    leathercraftSecondaryCTAText?: string | null;
    leathercraftSecondaryCTALink?: string | null;
    titlePopinCrossSellProduct?: string | null;
    /** @maxLength 700 */
    descriptionPopinCrossSellProduct?: string | null;
}

export interface StoreVariantTranslationsCatalogVariantRead {
    id?: number;
    locale?: string;
    /**
     *
     * Gedmo\Slug(fields={"label"}) # TODO
     */
    path?: string | null;
    additionalDescription?: string | null;
    description?: string | null;
    preorderText?: string | null;
    studioEditorialCTAText?: string | null;
    studioEditorialCTALink?: string | null;
    expertiseText?: string | null;
    leathercraftDetail?: string | null;
    leathercraftSecondaryTitle?: string | null;
    leathercraftSecondaryText?: string | null;
    leathercraftSecondaryCTAText?: string | null;
    leathercraftSecondaryCTALink?: string | null;
    titlePopinCrossSellProduct?: string | null;
    /** @maxLength 700 */
    descriptionPopinCrossSellProduct?: string | null;
}

export interface StoreVariantTranslationsStoreVariantRead {
    id?: number;
    locale?: string;
    /**
     *
     * Gedmo\Slug(fields={"label"}) # TODO
     */
    path?: string | null;
    additionalDescription?: string | null;
    description?: string | null;
    preorderText?: string | null;
    studioEditorialCTAText?: string | null;
    studioEditorialCTALink?: string | null;
    expertiseText?: string | null;
    leathercraftDetail?: string | null;
    leathercraftSecondaryTitle?: string | null;
    leathercraftSecondaryText?: string | null;
    leathercraftSecondaryCTAText?: string | null;
    leathercraftSecondaryCTALink?: string | null;
    titlePopinCrossSellProduct?: string | null;
    /** @maxLength 700 */
    descriptionPopinCrossSellProduct?: string | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariants {
    id?: number;
    catalogVariant?: CatalogVariants | null;
    workspacesIds?: string[];
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    availableSoon?: boolean;
    enabled?: boolean;
    status?: string;
    stockSyncDisabled?: boolean;
    photos?: MediaVariants[];
    expertiseVideo?: Medias | null;
    expertiseLeftVisual?: Medias | null;
    expertiseRightVisual?: Medias | null;
    leathercraftSecondaryVisual?: Medias | null;
    /** Needed for use with getSetObject normalizer */
    hasPreorder?: boolean;
    translations?: Record<string, StoreVariantTranslations>;
    /** Needed for use with getSetObject normalizer */
    hasPreorderActiveOrFuture?: boolean;
    /** Needed for use with getSetObject normalizer */
    isPreorderActive?: boolean;
    mainMedia?: MediaVariants | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsCatalogItemRead {
    id?: number;
    catalogVariant?: CatalogVariantsCatalogItemRead | null;
    workspacesIds?: string[];
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    availableSoon?: boolean;
    enabled?: boolean;
    status?: string;
    stockSyncDisabled?: boolean;
    photos?: MediaVariantsCatalogItemRead[];
    crossSell?: string[];
    crossSellPopin?: string[];
    crossSellProductPopin?: CrossSellProductsCatalogItemRead[];
    leathercraftCareVariants?: string[];
    crossSellOtherSizes?: string[];
    crossSellPopinEnabled?: boolean;
    crossSellProductPopinEnabled?: boolean;
    expertiseVideo?: MediasCatalogItemRead | null;
    expertiseLeftVisual?: MediasCatalogItemRead | null;
    expertiseRightVisual?: MediasCatalogItemRead | null;
    leathercraftSecondaryVisual?: MediasCatalogItemRead | null;
    /** Needed for use with getSetObject normalizer */
    hasPreorder?: boolean;
    translations?: Record<string, StoreVariantTranslationsCatalogItemRead>;
    /** Needed for use with getSetObject normalizer */
    hasPreorderActiveOrFuture?: boolean;
    /** Needed for use with getSetObject normalizer */
    isPreorderActive?: boolean;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsCatalogMultiProductRead {
    id?: number;
    workspacesIds?: string[];
    photos?: MediaVariantsCatalogMultiProductRead[];
    expertiseVideo?: MediasCatalogMultiProductRead | null;
    expertiseLeftVisual?: MediasCatalogMultiProductRead | null;
    expertiseRightVisual?: MediasCatalogMultiProductRead | null;
    leathercraftSecondaryVisual?: MediasCatalogMultiProductRead | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsCatalogProductRead {
    id?: number;
    catalogVariant?: CatalogVariantsCatalogProductRead | null;
    workspacesIds?: string[];
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    availableSoon?: boolean;
    enabled?: boolean;
    status?: string;
    stockSyncDisabled?: boolean;
    photos?: MediaVariantsCatalogProductRead[];
    crossSell?: CrossSellVariantsCatalogProductRead[];
    crossSellPopin?: CrossSellVariantsCatalogProductRead[];
    crossSellProductPopin?: CrossSellProductsCatalogProductRead[];
    leathercraftCareVariants?: CrossSellVariantsCatalogProductRead[];
    crossSellOtherSizes?: CrossSellVariantsCatalogProductRead[];
    crossSellPopinEnabled?: boolean;
    crossSellProductPopinEnabled?: boolean;
    expertiseVideo?: MediasCatalogProductRead | null;
    expertiseLeftVisual?: MediasCatalogProductRead | null;
    expertiseRightVisual?: MediasCatalogProductRead | null;
    leathercraftSecondaryVisual?: MediasCatalogProductRead | null;
    /** Needed for use with getSetObject normalizer */
    hasPreorder?: boolean;
    translations?: Record<string, StoreVariantTranslationsCatalogProductRead>;
    /** Needed for use with getSetObject normalizer */
    hasPreorderActiveOrFuture?: boolean;
    /** Needed for use with getSetObject normalizer */
    isPreorderActive?: boolean;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsCatalogVariantRead {
    id?: number;
    workspacesIds?: string[];
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    availableSoon?: boolean;
    enabled?: boolean;
    status?: string;
    stockSyncDisabled?: boolean;
    crossSell?: string[];
    crossSellPopin?: string[];
    crossSellProductPopin?: CrossSellProductsCatalogVariantRead[];
    leathercraftCareVariants?: string[];
    crossSellOtherSizes?: string[];
    crossSellPopinEnabled?: boolean;
    crossSellProductPopinEnabled?: boolean;
    /** Needed for use with getSetObject normalizer */
    hasPreorder?: boolean;
    translations?: Record<string, StoreVariantTranslationsCatalogVariantRead>;
    /** Needed for use with getSetObject normalizer */
    hasPreorderActiveOrFuture?: boolean;
    /** Needed for use with getSetObject normalizer */
    isPreorderActive?: boolean;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsMediaRead {
    id?: number;
    catalogVariant?: CatalogVariantsMediaRead | null;
    workspacesIds?: string[];
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsStoreMediaVariantRead {
    id?: number;
    catalogVariant?: CatalogVariantsStoreMediaVariantRead | null;
    workspacesIds?: string[];
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsStoreMediaVariantWrite {
    id?: number;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsStoreVariantRead {
    id?: number;
    catalogVariant?: CatalogVariantsStoreVariantRead | null;
    workspacesIds?: string[];
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    availableSoon?: boolean;
    enabled?: boolean;
    status?: string;
    stockSyncDisabled?: boolean;
    photos?: MediaVariantsStoreVariantRead[];
    expertiseVideo?: MediasStoreVariantRead | null;
    expertiseLeftVisual?: MediasStoreVariantRead | null;
    expertiseRightVisual?: MediasStoreVariantRead | null;
    leathercraftSecondaryVisual?: MediasStoreVariantRead | null;
    /** Needed for use with getSetObject normalizer */
    hasPreorder?: boolean;
    translations?: Record<string, StoreVariantTranslationsStoreVariantRead>;
    /** Needed for use with getSetObject normalizer */
    hasPreorderActiveOrFuture?: boolean;
    /** Needed for use with getSetObject normalizer */
    isPreorderActive?: boolean;
    mainMedia?: MediaVariantsStoreVariantRead | null;
    /**
     * @default "not_sent"
     * @example "not_sent"
     */
    calculatedTranslationStatus?: string;
    translationLocalesStatus?: string[] | null;
    translationPropertiesStatus?: string[] | null;
}

/**
 * A product variant
 * Une déclinaison d'un modèle
 */
export interface StoreVariantsStoreVariantWrite {
    id?: number;
    /**
     * @default 1
     * @example 1
     */
    position?: number;
    enabled?: boolean;
}

export interface SubCategories {
    id?: number;
    pimWrapper?: Wrapper | null;
    pimUuid?: string | null;
}

export interface SubCategoriesCatalogItemRead {
    pimUuid?: string | null;
}

export interface SubCategoriesCatalogProductRead {
    id?: number;
    pimWrapper?: WrapperCatalogProductRead | null;
    pimUuid?: string | null;
}

export interface SubCategoriesCatalogVariantRead {
    id?: number;
    pimWrapper?: WrapperCatalogVariantRead | null;
    pimUuid?: string | null;
}

export interface SubCategoriesMenuItemAttach {
    id?: number;
}

export interface SubCategoriesMenuItemRead {
    id?: number;
    pimWrapper?: WrapperMenuItemRead | null;
    pimUuid?: string | null;
}

export interface SubCategoriesMenuItemVersionRead {
    id?: number;
    pimWrapper?: WrapperMenuItemVersionRead | null;
    pimUuid?: string | null;
}

export interface SubCategoriesPageVersionList {
    id?: number;
    pimUuid?: string | null;
}

export interface SubCategoriesPageVersionRead {
    id?: number;
    pimWrapper?: WrapperPageVersionRead | null;
    pimUuid?: string | null;
}

export interface SubCategoriesPageVersionWrite {
    id?: number;
}

export interface SubCategoriesStoreVariantRead {
    id?: number;
    pimWrapper?: WrapperStoreVariantRead | null;
    pimUuid?: string | null;
}

export interface SubCategoriesSubCategoryRead {
    id?: number;
    pimWrapper?: WrapperSubCategoryRead | null;
    pimUuid?: string | null;
}

export interface TransactionalEmailsTransactionalEmailRead {
    id?: number;
    label?: string;
    code?: string;
    definitionKey?: string;
    requiredAttributes?: string[];
    site?: SiteTransactionalEmailRead | null;
    locale?: string;
}

export interface TransactionalEmailsTransactionalEmailWrite {
    id?: number;
    label?: string;
    code?: string;
    definitionKey?: string;
    requiredAttributes?: string[];
    site?: SiteTransactionalEmailWrite | null;
    locale?: string;
}

export interface Typologies {
    id?: number;
    pimWrapper?: Wrapper | null;
    pimUuid?: string | null;
}

export interface TypologiesCatalogItemRead {
    id?: number;
    pimWrapper?: WrapperCatalogItemRead | null;
    pimUuid?: string | null;
}

export interface TypologiesCatalogProductRead {
    id?: number;
    pimWrapper?: WrapperCatalogProductRead | null;
    pimUuid?: string | null;
}

export interface TypologiesCatalogVariantRead {
    id?: number;
    pimWrapper?: WrapperCatalogVariantRead | null;
    pimUuid?: string | null;
}

export interface TypologiesMenuItemAttach {
    id?: number;
    pimWrapper?: WrapperMenuItemAttach | null;
    pimUuid?: string | null;
}

export interface TypologiesMenuItemRead {
    id?: number;
    pimWrapper?: WrapperMenuItemRead | null;
    pimUuid?: string | null;
}

export interface TypologiesMenuItemVersionRead {
    id?: number;
    pimWrapper?: WrapperMenuItemVersionRead | null;
    pimUuid?: string | null;
}

export interface TypologiesPageVersionList {
    id?: number;
    pimUuid?: string | null;
}

export interface TypologiesPageVersionRead {
    id?: number;
    pimWrapper?: WrapperPageVersionRead | null;
    pimUuid?: string | null;
}

export interface TypologiesPageVersionWrite {
    id?: number;
    pimWrapper?: WrapperPageVersionWrite | null;
    pimUuid?: string | null;
}

export interface TypologiesStoreVariantRead {
    id?: number;
    pimWrapper?: WrapperStoreVariantRead | null;
    pimUuid?: string | null;
}

export interface TypologiesTypologyRead {
    id?: number;
    pimWrapper?: WrapperTypologyRead | null;
    pimUuid?: string | null;
}

export interface Analytic {
    dimensionHeaders?: {
        name?: string;
    }[];
    metricHeaders?: {
        name?: string;
        type?: string;
    }[];
    rows?: AnalyticRow[];
    /** @format int32 */
    rowCount?: number;
    metadata?: AnalyticMetadata;
    kind?: string;
    url?: string;
}

export interface AnalyticRow {
    dimensionValues?: {
        value?: string;
    }[];
    metricValues?: {
        value?: string;
    }[];
}

export interface AnalyticMetadata {
    dataLossFromOtherRow?: boolean;
    currencyCode?: string;
    timeZone?: string;
    subjectToThresholding?: boolean;
}

export type EvergreenMedias = EvergreenMedia[];

export interface EvergreenMedia {
    id?: number;
    media?: {
        cdnPublicId?: string;
        cdnSecureUrl?: string;
        cdnThumbnails?: string[];
        previewUrl?: string | null;
        /** @default false */
        video?: boolean;
    };
    mediaUrl?: string | null;
    mediaTransformations?: object;
    pageName?: string;
    pageUrl?: string;
    type?: string;
    /** @format date-time */
    updatedAt?: string;
}

export type PageVersionsCatalogVariant = PageVersion[];

export interface PageVersion {
    id?: number | null;
    page?: Page | null;
    enabled?: boolean;
    homepage?: boolean;
    translations?: PageVersionTranslation[];
}

export interface PageVersionTranslation {
    id?: number | null;
    title?: string | null;
    path?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key]);
        return keys
            .map(key =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async response => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then(data => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch(e => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Sezane BackOffice private API
 * @version 0.0.1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    ecommercePrivate = {
        /**
         * No description
         *
         * @tags UserConfiguration
         * @name GetUserConfigurationUserConfigurationItem
         * @summary Retrieves a Configuration resource.
         * @request GET:/ecommerce-private/v1/admin/configuration
         */
        getUserConfigurationUserConfigurationItem: (params: RequestParams = {}) =>
            this.request<UserConfigurationConfigurationWrite, void>({
                path: `/ecommerce-private/v1/admin/configuration`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags UserConfiguration
         * @name UpdateUserConfigurationUserConfigurationItem
         * @summary Replaces the UserConfiguration resource.
         * @request PUT:/ecommerce-private/v1/admin/configuration
         */
        updateUserConfigurationUserConfigurationItem: (
            data: UserConfigurationConfigurationWrite,
            params: RequestParams = {}
        ) =>
            this.request<UserConfigurationConfigurationWrite, void>({
                path: `/ecommerce-private/v1/admin/configuration`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name GetAdminCollection
         * @summary Retrieves the collection of admin resources.
         * @request GET:/ecommerce-private/v1/admins
         */
        getAdminCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<AdminAdminRead[], any>({
                path: `/ecommerce-private/v1/admins`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name GetByKeycloakIdAdminItem
         * @summary Retrieves a admin resource.
         * @request GET:/ecommerce-private/v1/admins/bykeycloakid/{keycloakId}
         */
        getByKeycloakIdAdminItem: (id: string, keycloakId: string, params: RequestParams = {}) =>
            this.request<AdminAdminRead, void>({
                path: `/ecommerce-private/v1/admins/bykeycloakid/${keycloakId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name GetAdminItem
         * @summary Retrieves a admin resource.
         * @request GET:/ecommerce-private/v1/admins/{id}
         */
        getAdminItem: (id: string, params: RequestParams = {}) =>
            this.request<AdminAdminRead, void>({
                path: `/ecommerce-private/v1/admins/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags block-translations
         * @name GetBlockTranslationsCollection
         * @summary Retrieves the collection of block-translations resources.
         * @request GET:/ecommerce-private/v1/block-translations
         */
        getBlockTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<BlockTranslations[], any>({
                path: `/ecommerce-private/v1/block-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags block-translations
         * @name GetBlockTranslationsItem
         * @summary Retrieves a block-translations resource.
         * @request GET:/ecommerce-private/v1/block-translations/{id}
         */
        getBlockTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<BlockTranslations, void>({
                path: `/ecommerce-private/v1/block-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags blocks
         * @name GetBlocksCollection
         * @summary Retrieves the collection of blocks resources.
         * @request GET:/ecommerce-private/v1/blocks
         */
        getBlocksCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<BlocksBlockRead[], any>({
                path: `/ecommerce-private/v1/blocks`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags blocks
         * @name PostBlocksCollection
         * @summary Creates a blocks resource.
         * @request POST:/ecommerce-private/v1/blocks
         */
        postBlocksCollection: (data: BlocksBlockWrite, params: RequestParams = {}) =>
            this.request<BlocksBlockRead, void>({
                path: `/ecommerce-private/v1/blocks`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags blocks
         * @name BatchUpdateBlocksCollection
         * @summary Replaces the blocks resource.
         * @request PUT:/ecommerce-private/v1/blocks/batch
         */
        batchUpdateBlocksCollection: (id: string, data: BlocksBlockBatchWrite[], params: RequestParams = {}) =>
            this.request<BlocksBlockRead[], void>({
                path: `/ecommerce-private/v1/blocks/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags blocks
         * @name GetBlocksItem
         * @summary Retrieves a blocks resource.
         * @request GET:/ecommerce-private/v1/blocks/{id}
         */
        getBlocksItem: (id: string, params: RequestParams = {}) =>
            this.request<BlocksBlockRead, void>({
                path: `/ecommerce-private/v1/blocks/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags blocks
         * @name PutBlocksItem
         * @summary Replaces the blocks resource.
         * @request PUT:/ecommerce-private/v1/blocks/{id}
         */
        putBlocksItem: (id: string, data: BlocksBlockWrite, params: RequestParams = {}) =>
            this.request<BlocksBlockRead, void>({
                path: `/ecommerce-private/v1/blocks/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags blocks
         * @name DeleteBlocksItem
         * @summary Removes the blocks resource.
         * @request DELETE:/ecommerce-private/v1/blocks/{id}
         */
        deleteBlocksItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/blocks/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags brands
         * @name GetBrandsCollection
         * @summary Retrieves the collection of brands resources.
         * @request GET:/ecommerce-private/v1/brands
         */
        getBrandsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<BrandsBrandRead[], any>({
                path: `/ecommerce-private/v1/brands`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags brands
         * @name PostBrandsCollection
         * @summary Creates a brands resource.
         * @request POST:/ecommerce-private/v1/brands
         */
        postBrandsCollection: (data: BrandsBrandWrite, params: RequestParams = {}) =>
            this.request<BrandsBrandRead, void>({
                path: `/ecommerce-private/v1/brands`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags brands
         * @name GetBrandsItem
         * @summary Retrieves a brands resource.
         * @request GET:/ecommerce-private/v1/brands/{id}
         */
        getBrandsItem: (id: string, params: RequestParams = {}) =>
            this.request<BrandsBrandRead, void>({
                path: `/ecommerce-private/v1/brands/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-items
         * @name GetCatalogItemsCollection
         * @summary Retrieves the collection of catalog-items resources.
         * @request GET:/ecommerce-private/v1/catalog-items
         */
        getCatalogItemsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogItemsCatalogItemRead[], any>({
                path: `/ecommerce-private/v1/catalog-items`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-items
         * @name GetEansByCodeProductCatalogItemsCollection
         * @summary Retrieves the collection of catalog-items resources.
         * @request GET:/ecommerce-private/v1/catalog-items/eans
         */
        getEansByCodeProductCatalogItemsCollection: (
            query: {
                codes: any[];
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogItemsCatalogItemReadCollection[], any>({
                path: `/ecommerce-private/v1/catalog-items/eans`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-items
         * @name GetFromCsvCatalogItemsCollection
         * @summary Creates a catalog-items resource.
         * @request POST:/ecommerce-private/v1/catalog-items/get-from-csv
         */
        getFromCsvCatalogItemsCollection: (data: CatalogItemsCatalogItemWrite, params: RequestParams = {}) =>
            this.request<CatalogItemsCatalogItemRead, void>({
                path: `/ecommerce-private/v1/catalog-items/get-from-csv`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-items
         * @name GetCatalogItemsItem
         * @summary Retrieves a catalog-items resource.
         * @request GET:/ecommerce-private/v1/catalog-items/{id}
         */
        getCatalogItemsItem: (id: string, params: RequestParams = {}) =>
            this.request<CatalogItemsCatalogItemRead, void>({
                path: `/ecommerce-private/v1/catalog-items/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-multi-products
         * @name GetCatalogMultiProductsCollection
         * @summary Retrieves the collection of catalog-multi-products resources.
         * @request GET:/ecommerce-private/v1/catalog-multi-products
         */
        getCatalogMultiProductsCollection: (
            query?: {
                brand?: string;
                enabled?: boolean;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogMultiProductsCatalogMultiProductList[], any>({
                path: `/ecommerce-private/v1/catalog-multi-products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-multi-products
         * @name PostCatalogMultiProductsCollection
         * @summary Creates a catalog-multi-products resource.
         * @request POST:/ecommerce-private/v1/catalog-multi-products
         */
        postCatalogMultiProductsCollection: (
            data: CatalogMultiProductsCatalogMultiProductWrite,
            params: RequestParams = {}
        ) =>
            this.request<CatalogMultiProductsCatalogMultiProductRead, void>({
                path: `/ecommerce-private/v1/catalog-multi-products`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-multi-products
         * @name GetCatalogMultiProductsItem
         * @summary Retrieves a catalog-multi-products resource.
         * @request GET:/ecommerce-private/v1/catalog-multi-products/{id}
         */
        getCatalogMultiProductsItem: (id: number, params: RequestParams = {}) =>
            this.request<CatalogMultiProductsCatalogMultiProductRead, void>({
                path: `/ecommerce-private/v1/catalog-multi-products/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-multi-products
         * @name PutCatalogMultiProductsItem
         * @summary Replaces the catalog-multi-products resource.
         * @request PUT:/ecommerce-private/v1/catalog-multi-products/{id}
         */
        putCatalogMultiProductsItem: (
            id: number,
            data: CatalogMultiProductsCatalogMultiProductWrite,
            params: RequestParams = {}
        ) =>
            this.request<CatalogMultiProductsCatalogMultiProductRead, void>({
                path: `/ecommerce-private/v1/catalog-multi-products/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-multi-products
         * @name DeleteCatalogMultiProductsItem
         * @summary Removes the catalog-multi-products resource.
         * @request DELETE:/ecommerce-private/v1/catalog-multi-products/{id}
         */
        deleteCatalogMultiProductsItem: (id: number, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/catalog-multi-products/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-product-translations
         * @name GetCatalogProductTranslationsCollection
         * @summary Retrieves the collection of catalog-product-translations resources.
         * @request GET:/ecommerce-private/v1/catalog-product-translations
         */
        getCatalogProductTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogProductTranslations[], any>({
                path: `/ecommerce-private/v1/catalog-product-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-product-translations
         * @name GetCatalogProductTranslationsItem
         * @summary Retrieves a catalog-product-translations resource.
         * @request GET:/ecommerce-private/v1/catalog-product-translations/{id}
         */
        getCatalogProductTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<CatalogProductTranslations, void>({
                path: `/ecommerce-private/v1/catalog-product-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-products
         * @name GetCatalogProductsCollection
         * @summary Retrieves the collection of catalog-products resources.
         * @request GET:/ecommerce-private/v1/catalog-products
         */
        getCatalogProductsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogProductsCatalogProductRead[], any>({
                path: `/ecommerce-private/v1/catalog-products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-products
         * @name GetCatalogProductsItem
         * @summary Retrieves a catalog-products resource.
         * @request GET:/ecommerce-private/v1/catalog-products/{id}
         */
        getCatalogProductsItem: (id: string, params: RequestParams = {}) =>
            this.request<CatalogProductsCatalogProductRead, void>({
                path: `/ecommerce-private/v1/catalog-products/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variant-translations
         * @name GetCatalogVariantTranslationsCollection
         * @summary Retrieves the collection of catalog-variant-translations resources.
         * @request GET:/ecommerce-private/v1/catalog-variant-translations
         */
        getCatalogVariantTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogVariantTranslations[], any>({
                path: `/ecommerce-private/v1/catalog-variant-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variant-translations
         * @name GetCatalogVariantTranslationsItem
         * @summary Retrieves a catalog-variant-translations resource.
         * @request GET:/ecommerce-private/v1/catalog-variant-translations/{id}
         */
        getCatalogVariantTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<CatalogVariantTranslations, void>({
                path: `/ecommerce-private/v1/catalog-variant-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variants
         * @name GetCatalogVariantsCollection
         * @summary Retrieves the collection of catalog-variants resources.
         * @request GET:/ecommerce-private/v1/catalog-variants
         */
        getCatalogVariantsCollection: (
            query?: {
                'order[implementationDate]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CatalogVariantsCatalogVariantRead[], any>({
                path: `/ecommerce-private/v1/catalog-variants`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variants
         * @name GetFromCsvCatalogVariantsItem
         * @summary Creates a catalog-variants resource.
         * @request POST:/ecommerce-private/v1/catalog-variants/get-from-csv
         */
        getFromCsvCatalogVariantsItem: (id: string, data: CatalogVariants, params: RequestParams = {}) =>
            this.request<CatalogVariantsCatalogVariantRead, void>({
                path: `/ecommerce-private/v1/catalog-variants/get-from-csv`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variants
         * @name GetCatalogVariantsItem
         * @summary Retrieves a catalog-variants resource.
         * @request GET:/ecommerce-private/v1/catalog-variants/{id}
         */
        getCatalogVariantsItem: (id: string, params: RequestParams = {}) =>
            this.request<CatalogVariantsCatalogVariantRead, void>({
                path: `/ecommerce-private/v1/catalog-variants/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variants
         * @name PatchCatalogVariantsItem
         * @summary Updates the catalog-variants resource.
         * @request PATCH:/ecommerce-private/v1/catalog-variants/{id}
         */
        patchCatalogVariantsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<CatalogVariantsCatalogVariantRead, void>({
                path: `/ecommerce-private/v1/catalog-variants/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variants
         * @name DeleteCatalogVariantsItem
         * @summary Removes the catalog-variants resource.
         * @request DELETE:/ecommerce-private/v1/catalog-variants/{id}
         */
        deleteCatalogVariantsItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/catalog-variants/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags catalog-variants
         * @name AddToWorkspaceCatalogVariantsItem
         * @summary Creates a catalog-variants resource.
         * @request POST:/ecommerce-private/v1/catalog-variants/{id}/add-to-workspace/{workspaceId}/{saleId}
         */
        addToWorkspaceCatalogVariantsItem: (
            id: string,
            workspaceId: string,
            saleId: string,
            data: CatalogVariants,
            params: RequestParams = {}
        ) =>
            this.request<CatalogVariantsCatalogVariantRead, void>({
                path: `/ecommerce-private/v1/catalog-variants/${id}/add-to-workspace/${workspaceId}/${saleId}`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ColorParameter
         * @name GetColorParameterCollection
         * @summary Retrieves the collection of ColorParameter resources.
         * @request GET:/ecommerce-private/v1/color-parameters
         */
        getColorParameterCollection: (
            query?: {
                'exists[value]'?: boolean;
                'exists[updatedAt]'?: boolean;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<ColorParameterColorParameterRead[], any>({
                path: `/ecommerce-private/v1/color-parameters`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ColorParameter
         * @name BatchUpdateColorParameterCollection
         * @summary Replaces the ColorParameter resource.
         * @request PUT:/ecommerce-private/v1/color-parameters/batch
         */
        batchUpdateColorParameterCollection: (
            id: string,
            data: ColorParameterColorParameterWrite[],
            params: RequestParams = {}
        ) =>
            this.request<ColorParameterColorParameterRead[], void>({
                path: `/ecommerce-private/v1/color-parameters/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ColorParameter
         * @name GetColorParameterItem
         * @summary Retrieves a ColorParameter resource.
         * @request GET:/ecommerce-private/v1/color-parameters/{id}
         */
        getColorParameterItem: (id: string, params: RequestParams = {}) =>
            this.request<ColorParameterColorParameterRead, void>({
                path: `/ecommerce-private/v1/color-parameters/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-layouts
         * @name GetCompositionLayoutsCollection
         * @summary Retrieves the collection of composition-layouts resources.
         * @request GET:/ecommerce-private/v1/composition-layouts
         */
        getCompositionLayoutsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CompositionLayoutsCompositionLayoutRead[], any>({
                path: `/ecommerce-private/v1/composition-layouts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-layouts
         * @name PostCompositionLayoutsCollection
         * @summary Creates a composition-layouts resource.
         * @request POST:/ecommerce-private/v1/composition-layouts
         */
        postCompositionLayoutsCollection: (
            data: CompositionLayoutsCompositionLayoutWrite,
            params: RequestParams = {}
        ) =>
            this.request<CompositionLayoutsCompositionLayoutRead, void>({
                path: `/ecommerce-private/v1/composition-layouts`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-layouts
         * @name GetCompositionLayoutsItem
         * @summary Retrieves a composition-layouts resource.
         * @request GET:/ecommerce-private/v1/composition-layouts/{id}
         */
        getCompositionLayoutsItem: (id: string, params: RequestParams = {}) =>
            this.request<CompositionLayoutsCompositionLayoutRead, void>({
                path: `/ecommerce-private/v1/composition-layouts/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-layouts
         * @name PutCompositionLayoutsItem
         * @summary Replaces the composition-layouts resource.
         * @request PUT:/ecommerce-private/v1/composition-layouts/{id}
         */
        putCompositionLayoutsItem: (
            id: string,
            data: CompositionLayoutsCompositionLayoutWrite,
            params: RequestParams = {}
        ) =>
            this.request<CompositionLayoutsCompositionLayoutRead, void>({
                path: `/ecommerce-private/v1/composition-layouts/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-templates
         * @name GetCompositionTemplatesCollection
         * @summary Retrieves the collection of composition-templates resources.
         * @request GET:/ecommerce-private/v1/composition-templates
         */
        getCompositionTemplatesCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CompositionTemplatesCompositionTemplateRead[], any>({
                path: `/ecommerce-private/v1/composition-templates`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-templates
         * @name CompositionTemplateByGroupCompositionTemplatesCollection
         * @summary Retrieves the collection of composition-templates resources.
         * @request GET:/ecommerce-private/v1/composition-templates/grouped-by-kind
         */
        compositionTemplateByGroupCompositionTemplatesCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CompositionTemplatesCompositionTemplateRead[], any>({
                path: `/ecommerce-private/v1/composition-templates/grouped-by-kind`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags composition-templates
         * @name GetCompositionTemplatesItem
         * @summary Retrieves a composition-templates resource.
         * @request GET:/ecommerce-private/v1/composition-templates/{id}
         */
        getCompositionTemplatesItem: (id: string, params: RequestParams = {}) =>
            this.request<CompositionTemplatesCompositionTemplateRead, void>({
                path: `/ecommerce-private/v1/composition-templates/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Composition
         * @name GetCompositionCollection
         * @summary Retrieves the collection of Composition resources.
         * @request GET:/ecommerce-private/v1/compositions
         */
        getCompositionCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CompositionCompositionRead[], any>({
                path: `/ecommerce-private/v1/compositions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Composition
         * @name PostCompositionCollection
         * @summary Creates a Composition resource.
         * @request POST:/ecommerce-private/v1/compositions
         */
        postCompositionCollection: (data: CompositionCompositionWrite, params: RequestParams = {}) =>
            this.request<CompositionCompositionRead, void>({
                path: `/ecommerce-private/v1/compositions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Composition
         * @name BatchUpdateCompositionCollection
         * @summary Replaces the Composition resource.
         * @request PUT:/ecommerce-private/v1/compositions/batch
         */
        batchUpdateCompositionCollection: (
            id: string,
            data: CompositionCompositionBatchWrite[],
            params: RequestParams = {}
        ) =>
            this.request<CompositionCompositionRead[], void>({
                path: `/ecommerce-private/v1/compositions/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Composition
         * @name GetCompositionItem
         * @summary Retrieves a Composition resource.
         * @request GET:/ecommerce-private/v1/compositions/{id}
         */
        getCompositionItem: (id: string, params: RequestParams = {}) =>
            this.request<CompositionCompositionRead, void>({
                path: `/ecommerce-private/v1/compositions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Composition
         * @name PutCompositionItem
         * @summary Replaces the Composition resource.
         * @request PUT:/ecommerce-private/v1/compositions/{id}
         */
        putCompositionItem: (id: string, data: CompositionCompositionWrite, params: RequestParams = {}) =>
            this.request<CompositionCompositionRead, void>({
                path: `/ecommerce-private/v1/compositions/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Composition
         * @name DeleteCompositionItem
         * @summary Removes the Composition resource.
         * @request DELETE:/ecommerce-private/v1/compositions/{id}
         */
        deleteCompositionItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/compositions/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Country
         * @name GetCountryCollection
         * @summary Retrieves the collection of Country resources.
         * @request GET:/ecommerce-private/v1/countries
         */
        getCountryCollection: (
            query?: {
                'translations.label'?: string;
                brand?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 500
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CountryCountryRead[], any>({
                path: `/ecommerce-private/v1/countries`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Country
         * @name GetCountryItem
         * @summary Retrieves a Country resource.
         * @request GET:/ecommerce-private/v1/countries/{id}
         */
        getCountryItem: (id: string, params: RequestParams = {}) =>
            this.request<CountryCountryRead, void>({
                path: `/ecommerce-private/v1/countries/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Country
         * @name PutCountryItem
         * @summary Replaces the Country resource.
         * @request PUT:/ecommerce-private/v1/countries/{id}
         */
        putCountryItem: (id: string, data: CountryCountryWrite, params: RequestParams = {}) =>
            this.request<CountryCountryRead, void>({
                path: `/ecommerce-private/v1/countries/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cross-sell-products
         * @name GetCrossSellProductsCollection
         * @summary Retrieves the collection of cross-sell-products resources.
         * @request GET:/ecommerce-private/v1/cross-sell-products
         */
        getCrossSellProductsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CrossSellProductsCrossSellProductRead[], any>({
                path: `/ecommerce-private/v1/cross-sell-products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cross-sell-products
         * @name GetCrossSellProductsItem
         * @summary Retrieves a cross-sell-products resource.
         * @request GET:/ecommerce-private/v1/cross-sell-products/{id}
         */
        getCrossSellProductsItem: (id: string, params: RequestParams = {}) =>
            this.request<CrossSellProductsCrossSellProductRead, void>({
                path: `/ecommerce-private/v1/cross-sell-products/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cross-sell-variants
         * @name GetCrossSellVariantsCollection
         * @summary Retrieves the collection of cross-sell-variants resources.
         * @request GET:/ecommerce-private/v1/cross-sell-variants
         */
        getCrossSellVariantsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CrossSellVariantsCrossSellVariantRead[], any>({
                path: `/ecommerce-private/v1/cross-sell-variants`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cross-sell-variants
         * @name GetCrossSellVariantsItem
         * @summary Retrieves a cross-sell-variants resource.
         * @request GET:/ecommerce-private/v1/cross-sell-variants/{id}
         */
        getCrossSellVariantsItem: (id: string, params: RequestParams = {}) =>
            this.request<CrossSellVariantsCrossSellVariantRead, void>({
                path: `/ecommerce-private/v1/cross-sell-variants/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Currency
         * @name GetCurrencyCollection
         * @summary Retrieves the collection of Currency resources.
         * @request GET:/ecommerce-private/v1/currencies
         */
        getCurrencyCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CurrencyCurrencyRead[], any>({
                path: `/ecommerce-private/v1/currencies`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Currency
         * @name GetCurrencyItem
         * @summary Retrieves a Currency resource.
         * @request GET:/ecommerce-private/v1/currencies/{id}
         */
        getCurrencyItem: (id: string, params: RequestParams = {}) =>
            this.request<CurrencyCurrencyRead, void>({
                path: `/ecommerce-private/v1/currencies/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationServiceLinkedVariant
         * @name GetCustomisationServiceLinkedVariantCollection
         * @summary Retrieves the collection of CustomisationServiceLinkedVariant resources.
         * @request GET:/ecommerce-private/v1/customisation-service-linked-variants
         */
        getCustomisationServiceLinkedVariantCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CustomisationServiceLinkedVariant[], any>({
                path: `/ecommerce-private/v1/customisation-service-linked-variants`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationServiceLinkedVariant
         * @name PostCustomisationServiceLinkedVariantCollection
         * @summary Creates a CustomisationServiceLinkedVariant resource.
         * @request POST:/ecommerce-private/v1/customisation-service-linked-variants
         */
        postCustomisationServiceLinkedVariantCollection: (
            data: CustomisationServiceLinkedVariant,
            params: RequestParams = {}
        ) =>
            this.request<CustomisationServiceLinkedVariant, void>({
                path: `/ecommerce-private/v1/customisation-service-linked-variants`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationServiceLinkedVariant
         * @name GetCustomisationServiceLinkedVariantItem
         * @summary Retrieves a CustomisationServiceLinkedVariant resource.
         * @request GET:/ecommerce-private/v1/customisation-service-linked-variants/{id}
         */
        getCustomisationServiceLinkedVariantItem: (id: string, params: RequestParams = {}) =>
            this.request<CustomisationServiceLinkedVariant, void>({
                path: `/ecommerce-private/v1/customisation-service-linked-variants/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationServiceLinkedVariant
         * @name DeleteCustomisationServiceLinkedVariantItem
         * @summary Removes the CustomisationServiceLinkedVariant resource.
         * @request DELETE:/ecommerce-private/v1/customisation-service-linked-variants/{id}
         */
        deleteCustomisationServiceLinkedVariantItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/customisation-service-linked-variants/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationServiceLinkedVariant
         * @name PutCustomisationServiceLinkedVariantItem
         * @summary Replaces the CustomisationServiceLinkedVariant resource.
         * @request PUT:/ecommerce-private/v1/customisation-service-linked-variants/{id}
         */
        putCustomisationServiceLinkedVariantItem: (
            id: string,
            data: CustomisationServiceLinkedVariant,
            params: RequestParams = {}
        ) =>
            this.request<CustomisationServiceLinkedVariant, void>({
                path: `/ecommerce-private/v1/customisation-service-linked-variants/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name GetCustomisationServiceCollection
         * @summary Retrieves the collection of CustomisationService resources.
         * @request GET:/ecommerce-private/v1/customisation-services
         */
        getCustomisationServiceCollection: (
            query?: {
                'order[id]'?: 'asc' | 'desc';
                'order[enabled]'?: 'asc' | 'desc';
                'order[optional]'?: 'asc' | 'desc';
                'order[prices]'?: 'asc' | 'desc';
                'order[type]'?: 'asc' | 'desc';
                'order[format]'?: 'asc' | 'desc';
                'order[textLines]'?: 'asc' | 'desc';
                'order[excludedCharactersPresets]'?: 'asc' | 'desc';
                'order[whitelistedSpecialCharacters]'?: 'asc' | 'desc';
                'order[authorizedCharacters]'?: 'asc' | 'desc';
                'order[colors]'?: 'asc' | 'desc';
                'order[positions]'?: 'asc' | 'desc';
                'order[symbols]'?: 'asc' | 'desc';
                'order[calculatedTranslationStatus]'?: 'asc' | 'desc';
                'order[translationLocalesStatus]'?: 'asc' | 'desc';
                'order[translationPropertiesStatus]'?: 'asc' | 'desc';
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<CustomisationServiceCustomisationServiceList[], any>({
                path: `/ecommerce-private/v1/customisation-services`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name PostCustomisationServiceCollection
         * @summary Creates a CustomisationService resource.
         * @request POST:/ecommerce-private/v1/customisation-services
         */
        postCustomisationServiceCollection: (
            data: CustomisationServiceCustomisationServiceWrite,
            params: RequestParams = {}
        ) =>
            this.request<CustomisationServiceCustomisationServiceRead, void>({
                path: `/ecommerce-private/v1/customisation-services`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name GetCustomisationServiceItem
         * @summary Retrieves a CustomisationService resource.
         * @request GET:/ecommerce-private/v1/customisation-services/{id}
         */
        getCustomisationServiceItem: (id: string, params: RequestParams = {}) =>
            this.request<CustomisationServiceCustomisationServiceRead, void>({
                path: `/ecommerce-private/v1/customisation-services/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name PutCustomisationServiceItem
         * @summary Replaces the CustomisationService resource.
         * @request PUT:/ecommerce-private/v1/customisation-services/{id}
         */
        putCustomisationServiceItem: (
            id: string,
            data: CustomisationServiceCustomisationServiceWrite,
            params: RequestParams = {}
        ) =>
            this.request<CustomisationServiceCustomisationServiceRead, void>({
                path: `/ecommerce-private/v1/customisation-services/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name DeleteCustomisationServiceItem
         * @summary Removes the CustomisationService resource.
         * @request DELETE:/ecommerce-private/v1/customisation-services/{id}
         */
        deleteCustomisationServiceItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/customisation-services/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name PatchCustomisationServiceItem
         * @summary Updates the CustomisationService resource.
         * @request PATCH:/ecommerce-private/v1/customisation-services/{id}
         */
        patchCustomisationServiceItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<CustomisationServiceCustomisationServiceRead, void>({
                path: `/ecommerce-private/v1/customisation-services/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags CustomisationService
         * @name ExportCustomisationServiceItem
         * @summary Retrieves a CustomisationService resource.
         * @request GET:/ecommerce-private/v1/customisation-services/{id}/export-variants
         */
        exportCustomisationServiceItem: (id: string, params: RequestParams = {}) =>
            this.request<CustomisationServiceCustomisationServiceRead, void>({
                path: `/ecommerce-private/v1/customisation-services/${id}/export-variants`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags EntityHistory
         * @name GetEntityHistoryCollection
         * @summary Retrieves the collection of EntityHistory resources.
         * @request GET:/ecommerce-private/v1/entity-histories
         */
        getEntityHistoryCollection: (
            query?: {
                objectId?: number;
                'objectId[]'?: number[];
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<EntityHistoryEntityHistoryRead[], any>({
                path: `/ecommerce-private/v1/entity-histories`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags EntityHistory
         * @name GetEntityHistoryItem
         * @summary Retrieves a EntityHistory resource.
         * @request GET:/ecommerce-private/v1/entity-histories/{id}
         */
        getEntityHistoryItem: (id: string, params: RequestParams = {}) =>
            this.request<EntityHistoryEntityHistoryRead, void>({
                path: `/ecommerce-private/v1/entity-histories/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags EntityHistory
         * @name DeleteEntityHistoryItem
         * @summary Removes the EntityHistory resource.
         * @request DELETE:/ecommerce-private/v1/entity-histories/{id}
         */
        deleteEntityHistoryItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/entity-histories/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags EntityHistory
         * @name PutEntityHistoryItem
         * @summary Replaces the EntityHistory resource.
         * @request PUT:/ecommerce-private/v1/entity-histories/{id}
         */
        putEntityHistoryItem: (id: string, data: EntityHistory, params: RequestParams = {}) =>
            this.request<EntityHistoryEntityHistoryRead, void>({
                path: `/ecommerce-private/v1/entity-histories/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags evergreen-medias
         * @name GetEvergreenMediasCollectionGetEvergreenMedias
         * @summary Retrieves the collection of evergreen-medias resources.,Get evergreen medias
         * @request GET:/ecommerce-private/v1/evergreen-medias
         */
        getEvergreenMediasCollectionGetEvergreenMedias: (
            brand: string,
            query: {
                brand?: string;
                /** Enable or disable pagination */
                pagination?: boolean;
                site: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<EvergreenMediasEvergreenMediaRead[], any>({
                path: `/ecommerce-private/v1/evergreen-medias`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags evergreen-medias
         * @name PutEvergreenMediasItem
         * @summary Replaces the evergreen-medias resource.
         * @request PUT:/ecommerce-private/v1/evergreen-medias/{id}
         */
        putEvergreenMediasItem: (id: string, data: EvergreenMediasEvergreenMediaWrite, params: RequestParams = {}) =>
            this.request<EvergreenMediasEvergreenMediaRead, void>({
                path: `/ecommerce-private/v1/evergreen-medias/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-item-translations
         * @name GetFooterItemTranslationsCollection
         * @summary Retrieves the collection of footer-item-translations resources.
         * @request GET:/ecommerce-private/v1/footer-item-translations
         */
        getFooterItemTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<FooterItemTranslations[], any>({
                path: `/ecommerce-private/v1/footer-item-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-item-translations
         * @name GetFooterItemTranslationsItem
         * @summary Retrieves a footer-item-translations resource.
         * @request GET:/ecommerce-private/v1/footer-item-translations/{id}
         */
        getFooterItemTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<FooterItemTranslations, void>({
                path: `/ecommerce-private/v1/footer-item-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-items
         * @name GetFooterItemsCollection
         * @summary Retrieves the collection of footer-items resources.
         * @request GET:/ecommerce-private/v1/footer-items
         */
        getFooterItemsCollection: (
            query?: {
                only_root_filter?: boolean;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<FooterItemsFooterItemRead[], any>({
                path: `/ecommerce-private/v1/footer-items`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-items
         * @name PostFooterItemsCollection
         * @summary Creates a footer-items resource.
         * @request POST:/ecommerce-private/v1/footer-items
         */
        postFooterItemsCollection: (data: FooterItemsFooterItemWrite, params: RequestParams = {}) =>
            this.request<FooterItemsFooterItemRead, void>({
                path: `/ecommerce-private/v1/footer-items`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-items
         * @name BatchUpdateFooterItemsCollection
         * @summary Replaces the footer-items resource.
         * @request PUT:/ecommerce-private/v1/footer-items/batch
         */
        batchUpdateFooterItemsCollection: (
            id: string,
            data: FooterItemsFooterItemWrite[],
            params: RequestParams = {}
        ) =>
            this.request<FooterItemsFooterItemRead[], void>({
                path: `/ecommerce-private/v1/footer-items/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-items
         * @name GetFooterItemsItem
         * @summary Retrieves a footer-items resource.
         * @request GET:/ecommerce-private/v1/footer-items/{id}
         */
        getFooterItemsItem: (id: string, params: RequestParams = {}) =>
            this.request<FooterItemsFooterItemRead, void>({
                path: `/ecommerce-private/v1/footer-items/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags footer-items
         * @name PutFooterItemsItem
         * @summary Replaces the footer-items resource.
         * @request PUT:/ecommerce-private/v1/footer-items/{id}
         */
        putFooterItemsItem: (id: string, data: FooterItemsFooterItemWrite, params: RequestParams = {}) =>
            this.request<FooterItemsFooterItemRead, void>({
                path: `/ecommerce-private/v1/footer-items/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Locale
         * @name GetLocaleCollection
         * @summary Retrieves the collection of Locale resources.
         * @request GET:/ecommerce-private/v1/locales
         */
        getLocaleCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<LocaleLocaleRead[], any>({
                path: `/ecommerce-private/v1/locales`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Locale
         * @name GetLocaleItem
         * @summary Retrieves a Locale resource.
         * @request GET:/ecommerce-private/v1/locales/{id}
         */
        getLocaleItem: (id: string, params: RequestParams = {}) =>
            this.request<LocaleLocaleRead, void>({
                path: `/ecommerce-private/v1/locales/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags LogReport
         * @name GetLogReportCollection
         * @summary Retrieves the collection of LogReport resources.
         * @request GET:/ecommerce-private/v1/log-reports
         */
        getLogReportCollection: (
            query?: {
                'order[id]'?: 'asc' | 'desc';
                'order[createdAt]'?: 'asc' | 'desc';
                'order[finishedAt]'?: 'asc' | 'desc';
                'order[origin]'?: 'asc' | 'desc';
                'order[message]'?: 'asc' | 'desc';
                'order[treated]'?: 'asc' | 'desc';
                'order[data]'?: 'asc' | 'desc';
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<LogReportLogReportRead[], any>({
                path: `/ecommerce-private/v1/log-reports`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags LogReport
         * @name MarkAsTreatedLogReportCollection
         * @summary Replaces the LogReport resource.
         * @request PUT:/ecommerce-private/v1/log-reports/mark-as-treated
         */
        markAsTreatedLogReportCollection: (id: string, data: LogReport[], params: RequestParams = {}) =>
            this.request<LogReportLogReportRead[], void>({
                path: `/ecommerce-private/v1/log-reports/mark-as-treated`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags LogReport
         * @name GetLogReportItem
         * @summary Retrieves a LogReport resource.
         * @request GET:/ecommerce-private/v1/log-reports/{id}
         */
        getLogReportItem: (id: string, params: RequestParams = {}) =>
            this.request<LogReportLogReportRead, void>({
                path: `/ecommerce-private/v1/log-reports/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags LookProductLink
         * @name GetLookProductLinkCollection
         * @summary Retrieves the collection of LookProductLink resources.
         * @request GET:/ecommerce-private/v1/look-product-links
         */
        getLookProductLinkCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<LookProductLink[], any>({
                path: `/ecommerce-private/v1/look-product-links`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags LookProductLink
         * @name GetLookProductLinkItem
         * @summary Retrieves a LookProductLink resource.
         * @request GET:/ecommerce-private/v1/look-product-links/{id}
         */
        getLookProductLinkItem: (id: string, params: RequestParams = {}) =>
            this.request<LookProductLink, void>({
                path: `/ecommerce-private/v1/look-product-links/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MandatoryLogin
         * @name GetMandatoryLoginCollection
         * @summary Retrieves the collection of MandatoryLogin resources.
         * @request GET:/ecommerce-private/v1/mandatory-logins
         */
        getMandatoryLoginCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MandatoryLogin[], any>({
                path: `/ecommerce-private/v1/mandatory-logins`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MandatoryLogin
         * @name PostMandatoryLoginCollection
         * @summary Creates a MandatoryLogin resource.
         * @request POST:/ecommerce-private/v1/mandatory-logins
         */
        postMandatoryLoginCollection: (data: MandatoryLogin, params: RequestParams = {}) =>
            this.request<MandatoryLogin, void>({
                path: `/ecommerce-private/v1/mandatory-logins`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MandatoryLogin
         * @name GetMandatoryLoginItem
         * @summary Retrieves a MandatoryLogin resource.
         * @request GET:/ecommerce-private/v1/mandatory-logins/{id}
         */
        getMandatoryLoginItem: (id: string, params: RequestParams = {}) =>
            this.request<MandatoryLogin, void>({
                path: `/ecommerce-private/v1/mandatory-logins/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MandatoryLogin
         * @name DeleteMandatoryLoginItem
         * @summary Removes the MandatoryLogin resource.
         * @request DELETE:/ecommerce-private/v1/mandatory-logins/{id}
         */
        deleteMandatoryLoginItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/mandatory-logins/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MandatoryLogin
         * @name PutMandatoryLoginItem
         * @summary Replaces the MandatoryLogin resource.
         * @request PUT:/ecommerce-private/v1/mandatory-logins/{id}
         */
        putMandatoryLoginItem: (id: string, data: MandatoryLogin, params: RequestParams = {}) =>
            this.request<MandatoryLogin, void>({
                path: `/ecommerce-private/v1/mandatory-logins/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags media-variants
         * @name GetMediaVariantsCollection
         * @summary Retrieves the collection of media-variants resources.
         * @request GET:/ecommerce-private/v1/media-variants
         */
        getMediaVariantsCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MediaVariantsStoreMediaVariantRead[], any>({
                path: `/ecommerce-private/v1/media-variants`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags media-variants
         * @name PostMediaVariantsCollection
         * @summary Creates a media-variants resource.
         * @request POST:/ecommerce-private/v1/media-variants
         */
        postMediaVariantsCollection: (data: MediaVariantsStoreMediaVariantWrite, params: RequestParams = {}) =>
            this.request<MediaVariantsStoreMediaVariantRead, void>({
                path: `/ecommerce-private/v1/media-variants`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags media-variants
         * @name GetMediaVariantsItem
         * @summary Retrieves a media-variants resource.
         * @request GET:/ecommerce-private/v1/media-variants/{id}
         */
        getMediaVariantsItem: (id: string, params: RequestParams = {}) =>
            this.request<MediaVariantsStoreMediaVariantRead, void>({
                path: `/ecommerce-private/v1/media-variants/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags media-variants
         * @name PatchMediaVariantsItem
         * @summary Updates the media-variants resource.
         * @request PATCH:/ecommerce-private/v1/media-variants/{id}
         */
        patchMediaVariantsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<MediaVariantsStoreMediaVariantRead, void>({
                path: `/ecommerce-private/v1/media-variants/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags media-variants
         * @name DeleteMediaVariantsItem
         * @summary Removes the media-variants resource.
         * @request DELETE:/ecommerce-private/v1/media-variants/{id}
         */
        deleteMediaVariantsItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/media-variants/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags medias
         * @name GetMediasCollection
         * @summary Retrieves the collection of medias resources.
         * @request GET:/ecommerce-private/v1/medias
         */
        getMediasCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MediasMediaRead[], any>({
                path: `/ecommerce-private/v1/medias`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags medias
         * @name PostMediasCollection
         * @summary Creates a medias resource.
         * @request POST:/ecommerce-private/v1/medias
         */
        postMediasCollection: (data: MediasMediaUploadMediaUpload, params: RequestParams = {}) =>
            this.request<MediasMediaRead, void>({
                path: `/ecommerce-private/v1/medias`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags medias
         * @name GetMediasItem
         * @summary Retrieves a medias resource.
         * @request GET:/ecommerce-private/v1/medias/{id}
         */
        getMediasItem: (id: string, params: RequestParams = {}) =>
            this.request<MediasMediaRead, void>({
                path: `/ecommerce-private/v1/medias/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags medias
         * @name DeleteMediasItem
         * @summary Removes the medias resource.
         * @request DELETE:/ecommerce-private/v1/medias/{id}
         */
        deleteMediasItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/medias/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags medias
         * @name PatchMediasItem
         * @summary Updates the medias resource.
         * @request PATCH:/ecommerce-private/v1/medias/{id}
         */
        patchMediasItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<MediasMediaRead, void>({
                path: `/ecommerce-private/v1/medias/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags medias
         * @name GetNbPagesMediasItem
         * @summary Retrieves a medias resource.
         * @request GET:/ecommerce-private/v1/medias/{id}/pages
         */
        getNbPagesMediasItem: (id: string, params: RequestParams = {}) =>
            this.request<MediasMediaRead, void>({
                path: `/ecommerce-private/v1/medias/${id}/pages`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-dropdowns
         * @name GetMenuItemDropdownsCollection
         * @summary Retrieves the collection of menu-item-dropdowns resources.
         * @request GET:/ecommerce-private/v1/menu-item-dropdowns
         */
        getMenuItemDropdownsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MenuItemDropdownsMenuItemDropdownRead[], any>({
                path: `/ecommerce-private/v1/menu-item-dropdowns`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-dropdowns
         * @name GetMenuItemDropdownsItem
         * @summary Retrieves a menu-item-dropdowns resource.
         * @request GET:/ecommerce-private/v1/menu-item-dropdowns/{id}
         */
        getMenuItemDropdownsItem: (id: string, params: RequestParams = {}) =>
            this.request<MenuItemDropdownsMenuItemDropdownRead, void>({
                path: `/ecommerce-private/v1/menu-item-dropdowns/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-version-translations
         * @name GetMenuItemVersionTranslationsCollection
         * @summary Retrieves the collection of menu-item-version-translations resources.
         * @request GET:/ecommerce-private/v1/menu-item-version-translations
         */
        getMenuItemVersionTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MenuItemVersionTranslationsMenuItemVersionTranslationRead[], any>({
                path: `/ecommerce-private/v1/menu-item-version-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-version-translations
         * @name GetMenuItemVersionTranslationsItem
         * @summary Retrieves a menu-item-version-translations resource.
         * @request GET:/ecommerce-private/v1/menu-item-version-translations/{id}
         */
        getMenuItemVersionTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<MenuItemVersionTranslationsMenuItemVersionTranslationRead, void>({
                path: `/ecommerce-private/v1/menu-item-version-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-versions
         * @name GetMenuItemVersionsCollection
         * @summary Retrieves the collection of menu-item-versions resources.
         * @request GET:/ecommerce-private/v1/menu-item-versions
         */
        getMenuItemVersionsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MenuItemVersionsMenuItemVersionRead[], any>({
                path: `/ecommerce-private/v1/menu-item-versions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-versions
         * @name PostMenuItemVersionsCollection
         * @summary Creates a menu-item-versions resource.
         * @request POST:/ecommerce-private/v1/menu-item-versions
         */
        postMenuItemVersionsCollection: (data: MenuItemVersionsMenuItemVersionWrite, params: RequestParams = {}) =>
            this.request<MenuItemVersionsMenuItemVersionRead, void>({
                path: `/ecommerce-private/v1/menu-item-versions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-versions
         * @name GetMenuItemVersionsItem
         * @summary Retrieves a menu-item-versions resource.
         * @request GET:/ecommerce-private/v1/menu-item-versions/{id}
         */
        getMenuItemVersionsItem: (id: string, params: RequestParams = {}) =>
            this.request<MenuItemVersionsMenuItemVersionRead, void>({
                path: `/ecommerce-private/v1/menu-item-versions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-item-versions
         * @name PutMenuItemVersionsItem
         * @summary Replaces the menu-item-versions resource.
         * @request PUT:/ecommerce-private/v1/menu-item-versions/{id}
         */
        putMenuItemVersionsItem: (id: string, data: MenuItemVersionsMenuItemVersionWrite, params: RequestParams = {}) =>
            this.request<MenuItemVersionsMenuItemVersionRead, void>({
                path: `/ecommerce-private/v1/menu-item-versions/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-items
         * @name GetMenuItemsCollection
         * @summary Retrieves the collection of menu-items resources.
         * @request GET:/ecommerce-private/v1/menu-items
         */
        getMenuItemsCollection: (
            query?: {
                only_root_filter?: boolean;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MenuItemsMenuItemList[], any>({
                path: `/ecommerce-private/v1/menu-items`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-items
         * @name BatchUpdateMenuItemsCollection
         * @summary Replaces the menu-items resource.
         * @request PUT:/ecommerce-private/v1/menu-items/batch
         */
        batchUpdateMenuItemsCollection: (id: string, data: MenuItems[], params: RequestParams = {}) =>
            this.request<MenuItemsMenuItemList[], void>({
                path: `/ecommerce-private/v1/menu-items/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-items
         * @name GetMenuItemsItem
         * @summary Retrieves a menu-items resource.
         * @request GET:/ecommerce-private/v1/menu-items/{id}
         */
        getMenuItemsItem: (id: string, params: RequestParams = {}) =>
            this.request<MenuItemsMenuItemRead, void>({
                path: `/ecommerce-private/v1/menu-items/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags menu-items
         * @name AttachMenuItemsMenuItemsItem
         * @summary Creates a menu-items resource.
         * @request POST:/ecommerce-private/v1/menu-items/{id}/attach
         */
        attachMenuItemsMenuItemsItem: (id: string, data: MenuItemsMenuItemAttach, params: RequestParams = {}) =>
            this.request<MenuItemsMenuItemList, void>({
                path: `/ecommerce-private/v1/menu-items/${id}/attach`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-slide-translations
         * @name GetMobileContentSlideTranslationsCollection
         * @summary Retrieves the collection of mobile-content-slide-translations resources.
         * @request GET:/ecommerce-private/v1/mobile-content-slide-translations
         */
        getMobileContentSlideTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentSlideTranslationsMobileContentSlideTranslationRead[], any>({
                path: `/ecommerce-private/v1/mobile-content-slide-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-slide-translations
         * @name GetMobileContentSlideTranslationsItem
         * @summary Retrieves a mobile-content-slide-translations resource.
         * @request GET:/ecommerce-private/v1/mobile-content-slide-translations/{id}
         */
        getMobileContentSlideTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<MobileContentSlideTranslationsMobileContentSlideTranslationRead, void>({
                path: `/ecommerce-private/v1/mobile-content-slide-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-slides
         * @name GetMobileContentSlidesCollection
         * @summary Retrieves the collection of mobile-content-slides resources.
         * @request GET:/ecommerce-private/v1/mobile-content-slides
         */
        getMobileContentSlidesCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentSlidesMobileContentSlideRead[], any>({
                path: `/ecommerce-private/v1/mobile-content-slides`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-slides
         * @name GetMobileContentSlidesItem
         * @summary Retrieves a mobile-content-slides resource.
         * @request GET:/ecommerce-private/v1/mobile-content-slides/{id}
         */
        getMobileContentSlidesItem: (id: string, params: RequestParams = {}) =>
            this.request<MobileContentSlidesMobileContentSlideRead, void>({
                path: `/ecommerce-private/v1/mobile-content-slides/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-version-translations
         * @name GetMobileContentVersionTranslationsCollection
         * @summary Retrieves the collection of mobile-content-version-translations resources.
         * @request GET:/ecommerce-private/v1/mobile-content-version-translations
         */
        getMobileContentVersionTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentVersionTranslationsMobileContentVersionTranslationRead[], any>({
                path: `/ecommerce-private/v1/mobile-content-version-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-version-translations
         * @name GetMobileContentVersionTranslationsItem
         * @summary Retrieves a mobile-content-version-translations resource.
         * @request GET:/ecommerce-private/v1/mobile-content-version-translations/{id}
         */
        getMobileContentVersionTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<MobileContentVersionTranslationsMobileContentVersionTranslationRead, void>({
                path: `/ecommerce-private/v1/mobile-content-version-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-versions
         * @name GetMobileContentVersionsCollection
         * @summary Retrieves the collection of mobile-content-versions resources.
         * @request GET:/ecommerce-private/v1/mobile-content-versions
         */
        getMobileContentVersionsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentVersionsMobileContentVersionRead[], any>({
                path: `/ecommerce-private/v1/mobile-content-versions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-versions
         * @name PostMobileContentVersionsCollection
         * @summary Creates a mobile-content-versions resource.
         * @request POST:/ecommerce-private/v1/mobile-content-versions
         */
        postMobileContentVersionsCollection: (
            data: MobileContentVersionsMobileContentVersionWrite,
            params: RequestParams = {}
        ) =>
            this.request<MobileContentVersionsMobileContentVersionRead, void>({
                path: `/ecommerce-private/v1/mobile-content-versions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-versions
         * @name ExportMobileContentVersionsCollection
         * @summary Retrieves the collection of mobile-content-versions resources.
         * @request GET:/ecommerce-private/v1/mobile-content-versions/export
         */
        exportMobileContentVersionsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentVersionsMobileContentVersionRead[], any>({
                path: `/ecommerce-private/v1/mobile-content-versions/export`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-versions
         * @name ReadExportMobileContentVersionsCollection
         * @summary Retrieves the collection of mobile-content-versions resources.
         * @request GET:/ecommerce-private/v1/mobile-content-versions/read-export
         */
        readExportMobileContentVersionsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentVersionsMobileContentVersionRead[], any>({
                path: `/ecommerce-private/v1/mobile-content-versions/read-export`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-versions
         * @name GetMobileContentVersionsItem
         * @summary Retrieves a mobile-content-versions resource.
         * @request GET:/ecommerce-private/v1/mobile-content-versions/{id}
         */
        getMobileContentVersionsItem: (id: string, params: RequestParams = {}) =>
            this.request<MobileContentVersionsMobileContentVersionRead, void>({
                path: `/ecommerce-private/v1/mobile-content-versions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-content-versions
         * @name PutMobileContentVersionsItem
         * @summary Replaces the mobile-content-versions resource.
         * @request PUT:/ecommerce-private/v1/mobile-content-versions/{id}
         */
        putMobileContentVersionsItem: (
            id: string,
            data: MobileContentVersionsMobileContentVersionWrite,
            params: RequestParams = {}
        ) =>
            this.request<MobileContentVersionsMobileContentVersionRead, void>({
                path: `/ecommerce-private/v1/mobile-content-versions/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-contents
         * @name GetMobileContentsCollection
         * @summary Retrieves the collection of mobile-contents resources.
         * @request GET:/ecommerce-private/v1/mobile-contents
         */
        getMobileContentsCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MobileContentsMobileContentList[], any>({
                path: `/ecommerce-private/v1/mobile-contents`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-contents
         * @name BatchUpdateMobileContentsCollection
         * @summary Replaces the mobile-contents resource.
         * @request PUT:/ecommerce-private/v1/mobile-contents/batch
         */
        batchUpdateMobileContentsCollection: (
            id: string,
            data: MobileContentsMobileContentWrite[],
            params: RequestParams = {}
        ) =>
            this.request<MobileContentsMobileContentList[], void>({
                path: `/ecommerce-private/v1/mobile-contents/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags mobile-contents
         * @name GetMobileContentsItem
         * @summary Retrieves a mobile-contents resource.
         * @request GET:/ecommerce-private/v1/mobile-contents/{id}
         */
        getMobileContentsItem: (id: string, params: RequestParams = {}) =>
            this.request<MobileContentsMobileContentRead, void>({
                path: `/ecommerce-private/v1/mobile-contents/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Model
         * @name GetModelCollection
         * @summary Retrieves the collection of Model resources.
         * @request GET:/ecommerce-private/v1/models
         */
        getModelCollection: (
            query?: {
                displayName?: string;
                brand?: string;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<ModelModelRead[], any>({
                path: `/ecommerce-private/v1/models`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Model
         * @name PostModelCollection
         * @summary Creates a Model resource.
         * @request POST:/ecommerce-private/v1/models
         */
        postModelCollection: (data: ModelModelWrite, params: RequestParams = {}) =>
            this.request<ModelModelRead, void>({
                path: `/ecommerce-private/v1/models`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Model
         * @name GetModelItem
         * @summary Retrieves a Model resource.
         * @request GET:/ecommerce-private/v1/models/{id}
         */
        getModelItem: (id: string, params: RequestParams = {}) =>
            this.request<ModelModelRead, void>({
                path: `/ecommerce-private/v1/models/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Model
         * @name PutModelItem
         * @summary Replaces the Model resource.
         * @request PUT:/ecommerce-private/v1/models/{id}
         */
        putModelItem: (id: string, data: ModelModelWrite, params: RequestParams = {}) =>
            this.request<ModelModelRead, void>({
                path: `/ecommerce-private/v1/models/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Model
         * @name DeleteModelItem
         * @summary Removes the Model resource.
         * @request DELETE:/ecommerce-private/v1/models/{id}
         */
        deleteModelItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/models/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MultiProductVariantLink
         * @name GetMultiProductVariantLinkCollection
         * @summary Retrieves the collection of MultiProductVariantLink resources.
         * @request GET:/ecommerce-private/v1/multi-product-variant-links
         */
        getMultiProductVariantLinkCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<MultiProductVariantLink[], any>({
                path: `/ecommerce-private/v1/multi-product-variant-links`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MultiProductVariantLink
         * @name PostMultiProductVariantLinkCollection
         * @summary Creates a MultiProductVariantLink resource.
         * @request POST:/ecommerce-private/v1/multi-product-variant-links
         */
        postMultiProductVariantLinkCollection: (data: MultiProductVariantLink, params: RequestParams = {}) =>
            this.request<MultiProductVariantLink, void>({
                path: `/ecommerce-private/v1/multi-product-variant-links`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags MultiProductVariantLink
         * @name GetMultiProductVariantLinkItem
         * @summary Retrieves a MultiProductVariantLink resource.
         * @request GET:/ecommerce-private/v1/multi-product-variant-links/{id}
         */
        getMultiProductVariantLinkItem: (id: string, params: RequestParams = {}) =>
            this.request<MultiProductVariantLink, void>({
                path: `/ecommerce-private/v1/multi-product-variant-links/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-version-translations
         * @name GetPageVersionTranslationsCollection
         * @summary Retrieves the collection of page-version-translations resources.
         * @request GET:/ecommerce-private/v1/page-version-translations
         */
        getPageVersionTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageVersionTranslations[], any>({
                path: `/ecommerce-private/v1/page-version-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-version-translations
         * @name GetPageVersionTranslationsItem
         * @summary Retrieves a page-version-translations resource.
         * @request GET:/ecommerce-private/v1/page-version-translations/{id}
         */
        getPageVersionTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<PageVersionTranslations, void>({
                path: `/ecommerce-private/v1/page-version-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name GetPageVersionsCollection
         * @summary Retrieves the collection of page-versions resources.
         * @request GET:/ecommerce-private/v1/page-versions
         */
        getPageVersionsCollection: (
            query?: {
                'order[rubric]'?: string;
                'order[updatedAt]'?: string;
                'order[enabled]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageVersionsPageVersionList[], any>({
                path: `/ecommerce-private/v1/page-versions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name PostPageVersionsCollection
         * @summary Creates a page-versions resource.
         * @request POST:/ecommerce-private/v1/page-versions
         */
        postPageVersionsCollection: (data: PageVersionsPageVersionWrite, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name ExportPageVersionsCollection
         * @summary Retrieves the collection of page-versions resources.
         * @request GET:/ecommerce-private/v1/page-versions/export
         */
        exportPageVersionsCollection: (
            query?: {
                'order[rubric]'?: string;
                'order[updatedAt]'?: string;
                'order[enabled]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageVersionsPageVersionRead[], any>({
                path: `/ecommerce-private/v1/page-versions/export`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name GetPageVersionsByCatalogVariantPageVersionsCollectionGetPageVersionsByCatalogVariant
         * @summary Retrieves the collection of page-versions resources.,Retrieve page versions containing the catalog variant
         * @request GET:/ecommerce-private/v1/page-versions/get-by-catalog-variant/{catalogVariantId}
         */
        getPageVersionsByCatalogVariantPageVersionsCollectionGetPageVersionsByCatalogVariant: (
            catalogVariantId: string,
            query: {
                'order[rubric]'?: string;
                'order[updatedAt]'?: string;
                'order[enabled]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
                /** Brand code */
                brand?: string;
                site: string;
                status?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageVersionsPageVersionRead[], any>({
                path: `/ecommerce-private/v1/page-versions/get-by-catalog-variant/${catalogVariantId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name ReadExportPageVersionsCollection
         * @summary Retrieves the collection of page-versions resources.
         * @request GET:/ecommerce-private/v1/page-versions/read-export
         */
        readExportPageVersionsCollection: (
            query?: {
                'order[rubric]'?: string;
                'order[updatedAt]'?: string;
                'order[enabled]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PageVersionsPageVersionRead[], any>({
                path: `/ecommerce-private/v1/page-versions/read-export`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name GetPageVersionsItem
         * @summary Retrieves a page-versions resource.
         * @request GET:/ecommerce-private/v1/page-versions/{id}
         */
        getPageVersionsItem: (id: string, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name PutPageVersionsItem
         * @summary Replaces the page-versions resource.
         * @request PUT:/ecommerce-private/v1/page-versions/{id}
         */
        putPageVersionsItem: (id: string, data: PageVersionsPageVersionWrite, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name PatchPageVersionsItem
         * @summary Updates the page-versions resource.
         * @request PATCH:/ecommerce-private/v1/page-versions/{id}
         */
        patchPageVersionsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name CreateDraftPageVersionsItem
         * @summary Replaces the page-versions resource.
         * @request PUT:/ecommerce-private/v1/page-versions/{id}/create-draft
         */
        createDraftPageVersionsItem: (id: string, data: PageVersionsPageVersionWrite, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}/create-draft`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name MarkAsHomepagePageVersionsItem
         * @summary Replaces the page-versions resource.
         * @request PUT:/ecommerce-private/v1/page-versions/{id}/mark-as-homepage
         */
        markAsHomepagePageVersionsItem: (id: string, data: PageVersionsPageVersionWrite, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}/mark-as-homepage`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name PublishPageVersionsItem
         * @summary Replaces the page-versions resource.
         * @request PUT:/ecommerce-private/v1/page-versions/{id}/publish
         */
        publishPageVersionsItem: (id: string, data: PageVersionsPageVersionWrite, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}/publish`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags page-versions
         * @name RemoveDraftPageVersionsItem
         * @summary Replaces the page-versions resource.
         * @request PUT:/ecommerce-private/v1/page-versions/{id}/remove-draft
         */
        removeDraftPageVersionsItem: (id: string, data: PageVersionsPageVersionWrite, params: RequestParams = {}) =>
            this.request<PageVersionsPageVersionRead, void>({
                path: `/ecommerce-private/v1/page-versions/${id}/remove-draft`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Page
         * @name GetPageCollection
         * @summary Retrieves the collection of Page resources.
         * @request GET:/ecommerce-private/v1/pages
         */
        getPageCollection: (
            query?: {
                'pageVersions.translations.title'?: string;
                'order[id]'?: 'asc' | 'desc';
                'order[rubric]'?: 'asc' | 'desc';
                'order[createdAt]'?: 'asc' | 'desc';
                'order[updatedAt]'?: 'asc' | 'desc';
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 500
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PagePageRead[], any>({
                path: `/ecommerce-private/v1/pages`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Page
         * @name PostPageCollection
         * @summary Creates a Page resource.
         * @request POST:/ecommerce-private/v1/pages
         */
        postPageCollection: (data: PagePageWrite, params: RequestParams = {}) =>
            this.request<PagePageRead, void>({
                path: `/ecommerce-private/v1/pages`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Page
         * @name PutPageItem
         * @summary Replaces the Page resource.
         * @request PUT:/ecommerce-private/v1/pages/{id}
         */
        putPageItem: (id: string, data: PagePageWrite, params: RequestParams = {}) =>
            this.request<PagePageRead, void>({
                path: `/ecommerce-private/v1/pages/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Page
         * @name GetPageItem
         * @summary Retrieves a Page resource.
         * @request GET:/ecommerce-private/v1/pages/{id}
         */
        getPageItem: (id: string, params: RequestParams = {}) =>
            this.request<PagePageRead, void>({
                path: `/ecommerce-private/v1/pages/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Page
         * @name DeletePageItem
         * @summary Removes the Page resource.
         * @request DELETE:/ecommerce-private/v1/pages/{id}
         */
        deletePageItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/pages/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Page
         * @name DuplicatePageItem
         * @summary Creates a Page resource.
         * @request POST:/ecommerce-private/v1/pages/{id}/duplicate
         */
        duplicatePageItem: (id: string, data: PagePageWrite, params: RequestParams = {}) =>
            this.request<PagePageRead, void>({
                path: `/ecommerce-private/v1/pages/${id}/duplicate`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Parameter
         * @name GetParameterCollection
         * @summary Retrieves the collection of Parameter resources.
         * @request GET:/ecommerce-private/v1/parameters
         */
        getParameterCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<ParameterParameterRead[], any>({
                path: `/ecommerce-private/v1/parameters`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Parameter
         * @name GetParameterItem
         * @summary Retrieves a Parameter resource.
         * @request GET:/ecommerce-private/v1/parameters/{id}
         */
        getParameterItem: (id: string, params: RequestParams = {}) =>
            this.request<ParameterParameterRead, void>({
                path: `/ecommerce-private/v1/parameters/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popin-region
         * @name GetPopinRegionCollection
         * @summary Retrieves the collection of popin-region resources.
         * @request GET:/ecommerce-private/v1/popin-regions
         */
        getPopinRegionCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PopinRegion[], any>({
                path: `/ecommerce-private/v1/popin-regions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popin-region
         * @name GetPopinRegionItem
         * @summary Retrieves a popin-region resource.
         * @request GET:/ecommerce-private/v1/popin-regions/{id}
         */
        getPopinRegionItem: (id: string, params: RequestParams = {}) =>
            this.request<PopinRegion, void>({
                path: `/ecommerce-private/v1/popin-regions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popin-translations
         * @name GetPopinTranslationsCollection
         * @summary Retrieves the collection of popin-translations resources.
         * @request GET:/ecommerce-private/v1/popin-translations
         */
        getPopinTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PopinTranslations[], any>({
                path: `/ecommerce-private/v1/popin-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popin-translations
         * @name GetPopinTranslationsItem
         * @summary Retrieves a popin-translations resource.
         * @request GET:/ecommerce-private/v1/popin-translations/{id}
         */
        getPopinTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<PopinTranslations, void>({
                path: `/ecommerce-private/v1/popin-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popins
         * @name GetPopinsCollection
         * @summary Retrieves the collection of PopinOutput resources.
         * @request GET:/ecommerce-private/v1/popins
         */
        getPopinsCollection: (
            query?: {
                'order[id]'?: 'asc' | 'desc';
                'order[staticPage]'?: 'asc' | 'desc';
                'order[format]'?: 'asc' | 'desc';
                'order[svgName]'?: 'asc' | 'desc';
                'order[colorPrimaryOverride]'?: 'asc' | 'desc';
                'order[colorSecondaryOverride]'?: 'asc' | 'desc';
                'order[oncePerSession]'?: 'asc' | 'desc';
                'order[allPages]'?: 'asc' | 'desc';
                'order[staticPages]'?: 'asc' | 'desc';
                'order[accountPages]'?: 'asc' | 'desc';
                'order[checkoutPages]'?: 'asc' | 'desc';
                'order[closeCtaColorOverride]'?: 'asc' | 'desc';
                'order[addToCart]'?: 'asc' | 'desc';
                'order[internalTitle]'?: 'asc' | 'desc';
                'order[beginOn]'?: 'asc' | 'desc';
                'order[finishOn]'?: 'asc' | 'desc';
                'order[enabled]'?: 'asc' | 'desc';
                'order[linkType]'?: 'asc' | 'desc';
                'order[target]'?: 'asc' | 'desc';
                'order[mediaType]'?: 'asc' | 'desc';
                'order[mediaTransformations]'?: 'asc' | 'desc';
                'order[calculatedTranslationStatus]'?: 'asc' | 'desc';
                'order[translationLocalesStatus]'?: 'asc' | 'desc';
                'order[translationPropertiesStatus]'?: 'asc' | 'desc';
                brand?: string;
                'beginOn[before]'?: string;
                'beginOn[strictly_before]'?: string;
                'beginOn[after]'?: string;
                'beginOn[strictly_after]'?: string;
                format?: string;
                pages?: string;
                accountPages?: string;
                staticPages?: string;
                checkoutPages?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PopinsPopinOutputPopinRead[], any>({
                path: `/ecommerce-private/v1/popins`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popins
         * @name PostPopinsCollection
         * @summary Creates a popins resource.
         * @request POST:/ecommerce-private/v1/popins
         */
        postPopinsCollection: (data: PopinsPopinInputPopinWrite, params: RequestParams = {}) =>
            this.request<PopinsPopinOutputPopinRead, void>({
                path: `/ecommerce-private/v1/popins`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popins
         * @name GetPopinsItem
         * @summary Retrieves a PopinOutput resource.
         * @request GET:/ecommerce-private/v1/popins/{id}
         */
        getPopinsItem: (id: string, params: RequestParams = {}) =>
            this.request<PopinsPopinOutputPopinRead, void>({
                path: `/ecommerce-private/v1/popins/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popins
         * @name DeletePopinsItem
         * @summary Removes the popins resource.
         * @request DELETE:/ecommerce-private/v1/popins/{id}
         */
        deletePopinsItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/popins/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popins
         * @name PutPopinsItem
         * @summary Replaces the popins resource.
         * @request PUT:/ecommerce-private/v1/popins/{id}
         */
        putPopinsItem: (id: string, data: PopinsPopinInputPopinWrite, params: RequestParams = {}) =>
            this.request<PopinsPopinOutputPopinRead, void>({
                path: `/ecommerce-private/v1/popins/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags popins
         * @name PatchPopinsItem
         * @summary Updates the popins resource.
         * @request PATCH:/ecommerce-private/v1/popins/{id}
         */
        patchPopinsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<PopinsPopinRead, void>({
                path: `/ecommerce-private/v1/popins/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer
         * @name PutPreFooterItem
         * @summary Replaces the pre-footer resource.
         * @request PUT:/ecommerce-private/v1/pre-footer
         */
        putPreFooterItem: (id: string, data: PreFooterPreFooterWrite, params: RequestParams = {}) =>
            this.request<PreFooterPreFooterRead, void>({
                path: `/ecommerce-private/v1/pre-footer`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer
         * @name GetPreFooterCollection
         * @summary Retrieves the collection of pre-footer resources.
         * @request GET:/ecommerce-private/v1/pre-footer
         */
        getPreFooterCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PreFooterPreFooterRead[], any>({
                path: `/ecommerce-private/v1/pre-footer`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer-item-translation
         * @name GetPreFooterItemTranslationCollection
         * @summary Retrieves the collection of pre-footer-item-translation resources.
         * @request GET:/ecommerce-private/v1/pre-footer-item-translations
         */
        getPreFooterItemTranslationCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PreFooterItemTranslation[], any>({
                path: `/ecommerce-private/v1/pre-footer-item-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer-item-translation
         * @name GetPreFooterItemTranslationItem
         * @summary Retrieves a pre-footer-item-translation resource.
         * @request GET:/ecommerce-private/v1/pre-footer-item-translations/{id}
         */
        getPreFooterItemTranslationItem: (id: string, params: RequestParams = {}) =>
            this.request<PreFooterItemTranslation, void>({
                path: `/ecommerce-private/v1/pre-footer-item-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer-item
         * @name GetPreFooterItemCollection
         * @summary Retrieves the collection of pre-footer-item resources.
         * @request GET:/ecommerce-private/v1/pre-footer-items
         */
        getPreFooterItemCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PreFooterItem[], any>({
                path: `/ecommerce-private/v1/pre-footer-items`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer-item
         * @name GetPreFooterItemItem
         * @summary Retrieves a pre-footer-item resource.
         * @request GET:/ecommerce-private/v1/pre-footer-items/{id}
         */
        getPreFooterItemItem: (id: string, params: RequestParams = {}) =>
            this.request<PreFooterItem, void>({
                path: `/ecommerce-private/v1/pre-footer-items/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags pre-footer
         * @name GetPreFooterItem
         * @summary Retrieves a pre-footer resource.
         * @request GET:/ecommerce-private/v1/pre-footer/{id}
         */
        getPreFooterItem: (id: string, params: RequestParams = {}) =>
            this.request<PreFooterPreFooterRead, void>({
                path: `/ecommerce-private/v1/pre-footer/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags push-products
         * @name GetPushProductsCollection
         * @summary Retrieves the collection of push-products resources.
         * @request GET:/ecommerce-private/v1/push-products
         */
        getPushProductsCollection: (
            query?: {
                'order[id]'?: 'asc' | 'desc';
                'order[createdAt]'?: 'asc' | 'desc';
                'order[updatedAt]'?: 'asc' | 'desc';
                'order[mediaTransformations]'?: 'asc' | 'desc';
                'order[beginOn]'?: 'asc' | 'desc';
                'order[finishOn]'?: 'asc' | 'desc';
                'order[enabled]'?: 'asc' | 'desc';
                'order[calculatedTranslationStatus]'?: 'asc' | 'desc';
                'order[translationLocalesStatus]'?: 'asc' | 'desc';
                'order[translationPropertiesStatus]'?: 'asc' | 'desc';
                brand?: string;
                'beginOn[before]'?: string;
                'beginOn[strictly_before]'?: string;
                'beginOn[after]'?: string;
                'beginOn[strictly_after]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<PushProductsPushProductRead[], any>({
                path: `/ecommerce-private/v1/push-products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags push-products
         * @name PostPushProductsCollection
         * @summary Creates a push-products resource.
         * @request POST:/ecommerce-private/v1/push-products
         */
        postPushProductsCollection: (data: PushProductsPushProductWrite, params: RequestParams = {}) =>
            this.request<PushProductsPushProductRead, void>({
                path: `/ecommerce-private/v1/push-products`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags push-products
         * @name GetPushProductsItem
         * @summary Retrieves a push-products resource.
         * @request GET:/ecommerce-private/v1/push-products/{id}
         */
        getPushProductsItem: (id: string, params: RequestParams = {}) =>
            this.request<PushProductsPushProductRead, void>({
                path: `/ecommerce-private/v1/push-products/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags push-products
         * @name DeletePushProductsItem
         * @summary Removes the push-products resource.
         * @request DELETE:/ecommerce-private/v1/push-products/{id}
         */
        deletePushProductsItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/push-products/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags push-products
         * @name PutPushProductsItem
         * @summary Replaces the push-products resource.
         * @request PUT:/ecommerce-private/v1/push-products/{id}
         */
        putPushProductsItem: (id: string, data: PushProductsPushProductWrite, params: RequestParams = {}) =>
            this.request<PushProductsPushProductRead, void>({
                path: `/ecommerce-private/v1/push-products/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags reinsurance-translations
         * @name GetReinsuranceTranslationsCollection
         * @summary Retrieves the collection of reinsurance-translations resources.
         * @request GET:/ecommerce-private/v1/reinsurance-translations
         */
        getReinsuranceTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<ReinsuranceTranslations[], any>({
                path: `/ecommerce-private/v1/reinsurance-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags reinsurance-translations
         * @name GetReinsuranceTranslationsItem
         * @summary Retrieves a reinsurance-translations resource.
         * @request GET:/ecommerce-private/v1/reinsurance-translations/{id}
         */
        getReinsuranceTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<ReinsuranceTranslations, void>({
                path: `/ecommerce-private/v1/reinsurance-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Reinsurance
         * @name GetReinsuranceCollection
         * @summary Retrieves the collection of Reinsurance resources.
         * @request GET:/ecommerce-private/v1/reinsurances
         */
        getReinsuranceCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<ReinsuranceReinsuranceRead[], any>({
                path: `/ecommerce-private/v1/reinsurances`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Reinsurance
         * @name GetReinsuranceItem
         * @summary Retrieves a Reinsurance resource.
         * @request GET:/ecommerce-private/v1/reinsurances/{id}
         */
        getReinsuranceItem: (id: string, params: RequestParams = {}) =>
            this.request<ReinsuranceReinsuranceRead, void>({
                path: `/ecommerce-private/v1/reinsurances/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags role
         * @name GetRoleCollection
         * @summary Retrieves the collection of role resources.
         * @request GET:/ecommerce-private/v1/roles
         */
        getRoleCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<RoleRoleRead[], any>({
                path: `/ecommerce-private/v1/roles`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags role
         * @name PostRoleCollection
         * @summary Creates a role resource.
         * @request POST:/ecommerce-private/v1/roles
         */
        postRoleCollection: (data: RoleRoleWrite, params: RequestParams = {}) =>
            this.request<RoleRoleRead, void>({
                path: `/ecommerce-private/v1/roles`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags role
         * @name GetRoleItem
         * @summary Retrieves a role resource.
         * @request GET:/ecommerce-private/v1/roles/{id}
         */
        getRoleItem: (id: string, params: RequestParams = {}) =>
            this.request<RoleRoleRead, void>({
                path: `/ecommerce-private/v1/roles/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags role
         * @name PutRoleItem
         * @summary Replaces the role resource.
         * @request PUT:/ecommerce-private/v1/roles/{id}
         */
        putRoleItem: (id: string, data: RoleRoleWrite, params: RequestParams = {}) =>
            this.request<RoleRoleRead, void>({
                path: `/ecommerce-private/v1/roles/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-version-translations
         * @name GetSaleVersionTranslationsCollection
         * @summary Retrieves the collection of sale-version-translations resources.
         * @request GET:/ecommerce-private/v1/sale-version-translations
         */
        getSaleVersionTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SaleVersionTranslations[], any>({
                path: `/ecommerce-private/v1/sale-version-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-version-translations
         * @name GetSaleVersionTranslationsItem
         * @summary Retrieves a sale-version-translations resource.
         * @request GET:/ecommerce-private/v1/sale-version-translations/{id}
         */
        getSaleVersionTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleVersionTranslations, void>({
                path: `/ecommerce-private/v1/sale-version-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name GetSaleVersionsCollection
         * @summary Retrieves the collection of sale-versions resources.
         * @request GET:/ecommerce-private/v1/sale-versions
         */
        getSaleVersionsCollection: (
            query?: {
                'order[enabled]'?: string;
                'order[label]'?: string;
                'order[sale]'?: string;
                'order[updatedAt]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SaleVersionsSaleVersionList[], any>({
                path: `/ecommerce-private/v1/sale-versions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name PostSaleVersionsCollection
         * @summary Creates a sale-versions resource.
         * @request POST:/ecommerce-private/v1/sale-versions
         */
        postSaleVersionsCollection: (data: SaleVersionsSaleVersionWrite, params: RequestParams = {}) =>
            this.request<SaleVersionsSaleVersionRead, void>({
                path: `/ecommerce-private/v1/sale-versions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name GetSaleVersionsItem
         * @summary Retrieves a sale-versions resource.
         * @request GET:/ecommerce-private/v1/sale-versions/{id}
         */
        getSaleVersionsItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleVersionsSaleVersionRead, void>({
                path: `/ecommerce-private/v1/sale-versions/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name PutSaleVersionsItem
         * @summary Replaces the sale-versions resource.
         * @request PUT:/ecommerce-private/v1/sale-versions/{id}
         */
        putSaleVersionsItem: (id: string, data: SaleVersionsSaleVersionWrite, params: RequestParams = {}) =>
            this.request<SaleVersionsSaleVersionRead, void>({
                path: `/ecommerce-private/v1/sale-versions/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name PatchSaleVersionsItem
         * @summary Updates the sale-versions resource.
         * @request PATCH:/ecommerce-private/v1/sale-versions/{id}
         */
        patchSaleVersionsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<SaleVersionsSaleVersionRead, void>({
                path: `/ecommerce-private/v1/sale-versions/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name AddVariantsFromProductCodesSaleVersionsItem
         * @summary Replaces the sale-versions resource.
         * @request PUT:/ecommerce-private/v1/sale-versions/{id}/add-variants-from-product-codes
         */
        addVariantsFromProductCodesSaleVersionsItem: (
            id: string,
            data: SaleVersionsSaleVersionWrite,
            params: RequestParams = {}
        ) =>
            this.request<SaleVersionsSaleVersionRead, void>({
                path: `/ecommerce-private/v1/sale-versions/${id}/add-variants-from-product-codes`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-versions
         * @name PatchVariantsSaleVersionsItem
         * @summary Updates the sale-versions resource.
         * @request PATCH:/ecommerce-private/v1/sale-versions/{id}/variants
         */
        patchVariantsSaleVersionsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<SaleVersionsSaleVersionRead, void>({
                path: `/ecommerce-private/v1/sale-versions/${id}/variants`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-page-translations
         * @name GetSaleWaitingPageTranslationsCollection
         * @summary Retrieves the collection of sale-waiting-page-translations resources.
         * @request GET:/ecommerce-private/v1/sale-waiting-page-translations
         */
        getSaleWaitingPageTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SaleWaitingPageTranslations[], any>({
                path: `/ecommerce-private/v1/sale-waiting-page-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-page-translations
         * @name GetSaleWaitingPageTranslationsItem
         * @summary Retrieves a sale-waiting-page-translations resource.
         * @request GET:/ecommerce-private/v1/sale-waiting-page-translations/{id}
         */
        getSaleWaitingPageTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleWaitingPageTranslations, void>({
                path: `/ecommerce-private/v1/sale-waiting-page-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-pages
         * @name GetSaleWaitingPagesCollection
         * @summary Retrieves the collection of sale-waiting-pages resources.
         * @request GET:/ecommerce-private/v1/sale-waiting-pages
         */
        getSaleWaitingPagesCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SaleWaitingPagesSaleWaitingPageRead[], any>({
                path: `/ecommerce-private/v1/sale-waiting-pages`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-pages
         * @name PostSaleWaitingPagesCollection
         * @summary Creates a sale-waiting-pages resource.
         * @request POST:/ecommerce-private/v1/sale-waiting-pages
         */
        postSaleWaitingPagesCollection: (data: SaleWaitingPagesSaleWaitingPageWrite, params: RequestParams = {}) =>
            this.request<SaleWaitingPagesSaleWaitingPageRead, void>({
                path: `/ecommerce-private/v1/sale-waiting-pages`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-pages
         * @name GetSaleWaitingPagesItem
         * @summary Retrieves a sale-waiting-pages resource.
         * @request GET:/ecommerce-private/v1/sale-waiting-pages/{id}
         */
        getSaleWaitingPagesItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleWaitingPagesSaleWaitingPageRead, void>({
                path: `/ecommerce-private/v1/sale-waiting-pages/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-pages
         * @name PutSaleWaitingPagesItem
         * @summary Replaces the sale-waiting-pages resource.
         * @request PUT:/ecommerce-private/v1/sale-waiting-pages/{id}
         */
        putSaleWaitingPagesItem: (id: string, data: SaleWaitingPagesSaleWaitingPageWrite, params: RequestParams = {}) =>
            this.request<SaleWaitingPagesSaleWaitingPageRead, void>({
                path: `/ecommerce-private/v1/sale-waiting-pages/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sale-waiting-pages
         * @name DeleteSaleWaitingPagesItem
         * @summary Removes the sale-waiting-pages resource.
         * @request DELETE:/ecommerce-private/v1/sale-waiting-pages/{id}
         */
        deleteSaleWaitingPagesItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/sale-waiting-pages/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Sale
         * @name GetSaleCollection
         * @summary Retrieves the collection of Sale resources.
         * @request GET:/ecommerce-private/v1/sales
         */
        getSaleCollection: (
            query?: {
                'order[enabled]'?: 'asc' | 'desc';
                'order[id]'?: 'asc' | 'desc';
                'order[archive]'?: 'asc' | 'desc';
                'order[createdAt]'?: 'asc' | 'desc';
                'order[updatedAt]'?: 'asc' | 'desc';
                'saleVersions.translations.label'?: string;
                brand?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SaleSaleList[], any>({
                path: `/ecommerce-private/v1/sales`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Sale
         * @name DuplicateSaleItem
         * @summary Creates a Sale resource.
         * @request POST:/ecommerce-private/v1/sales/duplicate-from-version/{saleVersionId}
         */
        duplicateSaleItem: (id: string, saleVersionId: string, data: Sale, params: RequestParams = {}) =>
            this.request<SaleSaleRead, void>({
                path: `/ecommerce-private/v1/sales/duplicate-from-version/${saleVersionId}`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Sale
         * @name GetSaleItem
         * @summary Retrieves a Sale resource.
         * @request GET:/ecommerce-private/v1/sales/{id}
         */
        getSaleItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleSaleRead, void>({
                path: `/ecommerce-private/v1/sales/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Sale
         * @name DeleteSaleItem
         * @summary Removes the Sale resource.
         * @request DELETE:/ecommerce-private/v1/sales/{id}
         */
        deleteSaleItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/sales/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Sale
         * @name GenerateMenuItemsSaleItem
         * @summary Retrieves a Sale resource.
         * @request GET:/ecommerce-private/v1/sales/{id}/generate-menu-items
         */
        generateMenuItemsSaleItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleMenuItemGenerate, void>({
                path: `/ecommerce-private/v1/sales/${id}/generate-menu-items`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Sale
         * @name GetStatusSaleItem
         * @summary Retrieves a Sale resource.
         * @request GET:/ecommerce-private/v1/sales/{id}/status
         */
        getStatusSaleItem: (id: string, params: RequestParams = {}) =>
            this.request<SaleSaleRead, void>({
                path: `/ecommerce-private/v1/sales/${id}/status`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name GetSequencingCollection
         * @summary Retrieves the collection of sequencing resources.
         * @request GET:/ecommerce-private/v1/sequencings
         */
        getSequencingCollection: (
            query?: {
                brand?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SequencingSequencingRead[], any>({
                path: `/ecommerce-private/v1/sequencings`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name PostSequencingCollection
         * @summary Creates a sequencing resource.
         * @request POST:/ecommerce-private/v1/sequencings
         */
        postSequencingCollection: (data: SequencingSequencingWrite, params: RequestParams = {}) =>
            this.request<SequencingSequencingRead, void>({
                path: `/ecommerce-private/v1/sequencings`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name GetSequencingItem
         * @summary Retrieves a sequencing resource.
         * @request GET:/ecommerce-private/v1/sequencings/{id}
         */
        getSequencingItem: (id: string, params: RequestParams = {}) =>
            this.request<SequencingSequencingRead, void>({
                path: `/ecommerce-private/v1/sequencings/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name PutSequencingItem
         * @summary Replaces the sequencing resource.
         * @request PUT:/ecommerce-private/v1/sequencings/{id}
         */
        putSequencingItem: (id: string, data: SequencingSequencingWrite, params: RequestParams = {}) =>
            this.request<SequencingSequencingRead, void>({
                path: `/ecommerce-private/v1/sequencings/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name ExportSequencingItem
         * @summary Retrieves a sequencing resource.
         * @request GET:/ecommerce-private/v1/sequencings/{id}/export
         */
        exportSequencingItem: (id: string, params: RequestParams = {}) =>
            this.request<SequencingSequencingRead, void>({
                path: `/ecommerce-private/v1/sequencings/${id}/export`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name ImportSequencingItem
         * @summary Creates a sequencing resource.
         * @request POST:/ecommerce-private/v1/sequencings/{id}/import
         */
        importSequencingItem: (
            id: string,
            data: {
                /**
                 * File to import
                 * @format binary
                 */
                sequencing_import?: File;
            },
            params: RequestParams = {}
        ) =>
            this.request<SequencingSequencingRead, void>({
                path: `/ecommerce-private/v1/sequencings/${id}/import`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sequencing
         * @name ItemsSequencingItem
         * @summary Retrieves a sequencing resource.
         * @request GET:/ecommerce-private/v1/sequencings/{id}/items
         */
        itemsSequencingItem: (id: string, params: RequestParams = {}) =>
            this.request<SequencingSequencingItemRead, void>({
                path: `/ecommerce-private/v1/sequencings/${id}/items`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ShippingCountry
         * @name GetShippingCountryCollection
         * @summary Retrieves the collection of ShippingCountry resources.
         * @request GET:/ecommerce-private/v1/shipping-countries
         */
        getShippingCountryCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<ShippingCountry[], any>({
                path: `/ecommerce-private/v1/shipping-countries`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ShippingCountry
         * @name PostShippingCountryCollection
         * @summary Creates a ShippingCountry resource.
         * @request POST:/ecommerce-private/v1/shipping-countries
         */
        postShippingCountryCollection: (data: ShippingCountry, params: RequestParams = {}) =>
            this.request<ShippingCountry, void>({
                path: `/ecommerce-private/v1/shipping-countries`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ShippingCountry
         * @name GetShippingCountryItem
         * @summary Retrieves a ShippingCountry resource.
         * @request GET:/ecommerce-private/v1/shipping-countries/{id}
         */
        getShippingCountryItem: (id: string, params: RequestParams = {}) =>
            this.request<ShippingCountry, void>({
                path: `/ecommerce-private/v1/shipping-countries/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Site
         * @name GetSiteCollection
         * @summary Retrieves the collection of Site resources.
         * @request GET:/ecommerce-private/v1/sites
         */
        getSiteCollection: (
            query?: {
                brand?: string;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SiteSiteRead[], any>({
                path: `/ecommerce-private/v1/sites`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Site
         * @name PostSiteCollection
         * @summary Creates a Site resource.
         * @request POST:/ecommerce-private/v1/sites
         */
        postSiteCollection: (data: SiteSiteWrite, params: RequestParams = {}) =>
            this.request<SiteSiteRead, void>({
                path: `/ecommerce-private/v1/sites`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Site
         * @name MandatoryLoginSiteCollection
         * @summary Replaces the Site resource.
         * @request PUT:/ecommerce-private/v1/sites/mandatory-login
         */
        mandatoryLoginSiteCollection: (id: string, data: SiteSiteWrite[], params: RequestParams = {}) =>
            this.request<SiteSiteRead[], void>({
                path: `/ecommerce-private/v1/sites/mandatory-login`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Site
         * @name GetSiteItem
         * @summary Retrieves a Site resource.
         * @request GET:/ecommerce-private/v1/sites/{id}
         */
        getSiteItem: (id: string, params: RequestParams = {}) =>
            this.request<SiteSiteRead, void>({
                path: `/ecommerce-private/v1/sites/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Site
         * @name PutSiteItem
         * @summary Replaces the Site resource.
         * @request PUT:/ecommerce-private/v1/sites/{id}
         */
        putSiteItem: (id: string, data: SiteSiteWrite, params: RequestParams = {}) =>
            this.request<SiteSiteRead, void>({
                path: `/ecommerce-private/v1/sites/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Site
         * @name DeleteSiteItem
         * @summary Removes the Site resource.
         * @request DELETE:/ecommerce-private/v1/sites/{id}
         */
        deleteSiteItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/sites/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags slide-product-link-translations
         * @name GetSlideProductLinkTranslationsCollection
         * @summary Retrieves the collection of slide-product-link-translations resources.
         * @request GET:/ecommerce-private/v1/slide-product-link-translations
         */
        getSlideProductLinkTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SlideProductLinkTranslations[], any>({
                path: `/ecommerce-private/v1/slide-product-link-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags slide-product-link-translations
         * @name GetSlideProductLinkTranslationsItem
         * @summary Retrieves a slide-product-link-translations resource.
         * @request GET:/ecommerce-private/v1/slide-product-link-translations/{id}
         */
        getSlideProductLinkTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<SlideProductLinkTranslations, void>({
                path: `/ecommerce-private/v1/slide-product-link-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags SlideProductLink
         * @name GetSlideProductLinkCollection
         * @summary Retrieves the collection of SlideProductLink resources.
         * @request GET:/ecommerce-private/v1/slide-product-links
         */
        getSlideProductLinkCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SlideProductLink[], any>({
                path: `/ecommerce-private/v1/slide-product-links`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags SlideProductLink
         * @name GetSlideProductLinkItem
         * @summary Retrieves a SlideProductLink resource.
         * @request GET:/ecommerce-private/v1/slide-product-links/{id}
         */
        getSlideProductLinkItem: (id: string, params: RequestParams = {}) =>
            this.request<SlideProductLink, void>({
                path: `/ecommerce-private/v1/slide-product-links/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags slide-translations
         * @name GetSlideTranslationsCollection
         * @summary Retrieves the collection of slide-translations resources.
         * @request GET:/ecommerce-private/v1/slide-translations
         */
        getSlideTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SlideTranslations[], any>({
                path: `/ecommerce-private/v1/slide-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags slide-translations
         * @name GetSlideTranslationsItem
         * @summary Retrieves a slide-translations resource.
         * @request GET:/ecommerce-private/v1/slide-translations/{id}
         */
        getSlideTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<SlideTranslations, void>({
                path: `/ecommerce-private/v1/slide-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Slide
         * @name GetSlideCollection
         * @summary Retrieves the collection of Slide resources.
         * @request GET:/ecommerce-private/v1/slides
         */
        getSlideCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<Slide[], any>({
                path: `/ecommerce-private/v1/slides`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Slide
         * @name GetSlideItem
         * @summary Retrieves a Slide resource.
         * @request GET:/ecommerce-private/v1/slides/{id}
         */
        getSlideItem: (id: string, params: RequestParams = {}) =>
            this.request<Slide, void>({
                path: `/ecommerce-private/v1/slides/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-media-variant-tags
         * @name GetStoreMediaVariantTagsCollection
         * @summary Retrieves the collection of store-media-variant-tags resources.
         * @request GET:/ecommerce-private/v1/store-media-variant-tags
         */
        getStoreMediaVariantTagsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<StoreMediaVariantTagsStoreMediaVariantTagRead[], any>({
                path: `/ecommerce-private/v1/store-media-variant-tags`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-media-variant-tags
         * @name GetStoreMediaVariantTagsItem
         * @summary Retrieves a store-media-variant-tags resource.
         * @request GET:/ecommerce-private/v1/store-media-variant-tags/{id}
         */
        getStoreMediaVariantTagsItem: (id: string, params: RequestParams = {}) =>
            this.request<StoreMediaVariantTagsStoreMediaVariantTagRead, void>({
                path: `/ecommerce-private/v1/store-media-variant-tags/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variant-translations
         * @name GetStoreVariantTranslationsCollection
         * @summary Retrieves the collection of store-variant-translations resources.
         * @request GET:/ecommerce-private/v1/store-variant-translations
         */
        getStoreVariantTranslationsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantTranslations[], any>({
                path: `/ecommerce-private/v1/store-variant-translations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variant-translations
         * @name GetStoreVariantTranslationsItem
         * @summary Retrieves a store-variant-translations resource.
         * @request GET:/ecommerce-private/v1/store-variant-translations/{id}
         */
        getStoreVariantTranslationsItem: (id: string, params: RequestParams = {}) =>
            this.request<StoreVariantTranslations, void>({
                path: `/ecommerce-private/v1/store-variant-translations/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name GetStoreVariantsCollection
         * @summary Retrieves the collection of store-variants resources.
         * @request GET:/ecommerce-private/v1/store-variants
         */
        getStoreVariantsCollection: (
            query?: {
                'order[implementationDate]'?: string;
                'order[preorderStart]'?: string;
                'order[preorderEnd]'?: string;
                'order[stockWeb]'?: string;
                'order[stockLogistic]'?: string;
                'order[status]'?: string;
                'order[fullName]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead[], any>({
                path: `/ecommerce-private/v1/store-variants`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name BatchUpdateStoreVariantsCollection
         * @summary Replaces the store-variants resource.
         * @request PUT:/ecommerce-private/v1/store-variants/batch
         */
        batchUpdateStoreVariantsCollection: (
            id: string,
            data: StoreVariantsStoreVariantWrite[],
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead[], void>({
                path: `/ecommerce-private/v1/store-variants/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name DuplicateOnBrandStoreVariantsCollection
         * @summary Creates a store-variants resource.
         * @request POST:/ecommerce-private/v1/store-variants/duplicate-on-brand
         */
        duplicateOnBrandStoreVariantsCollection: (data: StoreVariantsStoreVariantWrite, params: RequestParams = {}) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/duplicate-on-brand`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name ExportStoreVariantsCollection
         * @summary Retrieves the collection of store-variants resources.
         * @request GET:/ecommerce-private/v1/store-variants/export
         */
        exportStoreVariantsCollection: (
            query?: {
                'order[implementationDate]'?: string;
                'order[preorderStart]'?: string;
                'order[preorderEnd]'?: string;
                'order[stockWeb]'?: string;
                'order[stockLogistic]'?: string;
                'order[status]'?: string;
                'order[fullName]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead[], any>({
                path: `/ecommerce-private/v1/store-variants/export`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name ReadExportStoreVariantsCollection
         * @summary Retrieves the collection of store-variants resources.
         * @request GET:/ecommerce-private/v1/store-variants/read-export
         */
        readExportStoreVariantsCollection: (
            query?: {
                'order[implementationDate]'?: string;
                'order[preorderStart]'?: string;
                'order[preorderEnd]'?: string;
                'order[stockWeb]'?: string;
                'order[stockLogistic]'?: string;
                'order[status]'?: string;
                'order[fullName]'?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead[], any>({
                path: `/ecommerce-private/v1/store-variants/read-export`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name SearchStoreVariantsCollection
         * @summary Creates a store-variants resource.
         * @request POST:/ecommerce-private/v1/store-variants/search
         */
        searchStoreVariantsCollection: (data: StoreVariantsStoreVariantWrite, params: RequestParams = {}) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/search`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name UpdateVariantsDescriptionFromProductCodesStoreVariantsCollection
         * @summary Replaces the store-variants resource.
         * @request PUT:/ecommerce-private/v1/store-variants/update-description-from-product-codes
         */
        updateVariantsDescriptionFromProductCodesStoreVariantsCollection: (
            id: string,
            data: StoreVariantsStoreVariantWrite[],
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead[], void>({
                path: `/ecommerce-private/v1/store-variants/update-description-from-product-codes`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name UpdateVariantsPricesFromProductCodesStoreVariantsCollection
         * @summary Creates a store-variants resource.
         * @request POST:/ecommerce-private/v1/store-variants/update-prices-from-product-codes
         */
        updateVariantsPricesFromProductCodesStoreVariantsCollection: (
            data: StoreVariantsStoreVariantWrite,
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/update-prices-from-product-codes`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name UpdateUrlAliasesStoreVariantsCollection
         * @summary Replaces the store-variants resource.
         * @request PUT:/ecommerce-private/v1/store-variants/update-url-aliases
         */
        updateUrlAliasesStoreVariantsCollection: (
            id: string,
            data: StoreVariantsStoreVariantWrite[],
            params: RequestParams = {}
        ) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/store-variants/update-url-aliases`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name GetStoreVariantsItem
         * @summary Retrieves a store-variants resource.
         * @request GET:/ecommerce-private/v1/store-variants/{id}
         */
        getStoreVariantsItem: (id: string, params: RequestParams = {}) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name PatchStoreVariantsItem
         * @summary Updates the store-variants resource.
         * @request PATCH:/ecommerce-private/v1/store-variants/{id}
         */
        patchStoreVariantsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name PatchPhotosStoreVariantsItem
         * @summary Updates the store-variants resource.
         * @request PATCH:/ecommerce-private/v1/store-variants/{id}/photos
         */
        patchPhotosStoreVariantsItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/${id}/photos`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags store-variants
         * @name SyncPimVariantStoreVariantsCollection
         * @summary Creates a store-variants resource.
         * @request POST:/ecommerce-private/v1/store-variants/{pimUuid}/sync-pim
         */
        syncPimVariantStoreVariantsCollection: (
            pimUuid: string,
            data: StoreVariantsStoreVariantWrite,
            params: RequestParams = {}
        ) =>
            this.request<StoreVariantsStoreVariantRead, void>({
                path: `/ecommerce-private/v1/store-variants/${pimUuid}/sync-pim`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sub-categories
         * @name GetSubCategoriesCollection
         * @summary Retrieves the collection of sub-categories resources.
         * @request GET:/ecommerce-private/v1/sub-categories
         */
        getSubCategoriesCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<SubCategoriesSubCategoryRead[], any>({
                path: `/ecommerce-private/v1/sub-categories`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags sub-categories
         * @name GetSubCategoriesItem
         * @summary Retrieves a sub-categories resource.
         * @request GET:/ecommerce-private/v1/sub-categories/{id}
         */
        getSubCategoriesItem: (id: string, params: RequestParams = {}) =>
            this.request<SubCategoriesSubCategoryRead, void>({
                path: `/ecommerce-private/v1/sub-categories/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags transactional-emails
         * @name GetTransactionalEmailsCollection
         * @summary Retrieves the collection of transactional-emails resources.
         * @request GET:/ecommerce-private/v1/transactional-emails
         */
        getTransactionalEmailsCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<TransactionalEmailsTransactionalEmailRead[], any>({
                path: `/ecommerce-private/v1/transactional-emails`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags transactional-emails
         * @name PostTransactionalEmailsCollection
         * @summary Creates a transactional-emails resource.
         * @request POST:/ecommerce-private/v1/transactional-emails
         */
        postTransactionalEmailsCollection: (
            data: TransactionalEmailsTransactionalEmailWrite,
            params: RequestParams = {}
        ) =>
            this.request<TransactionalEmailsTransactionalEmailRead, void>({
                path: `/ecommerce-private/v1/transactional-emails`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags transactional-emails
         * @name GetTransactionalEmailsItem
         * @summary Retrieves a transactional-emails resource.
         * @request GET:/ecommerce-private/v1/transactional-emails/{id}
         */
        getTransactionalEmailsItem: (id: string, params: RequestParams = {}) =>
            this.request<TransactionalEmailsTransactionalEmailRead, void>({
                path: `/ecommerce-private/v1/transactional-emails/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags transactional-emails
         * @name PutTransactionalEmailsItem
         * @summary Replaces the transactional-emails resource.
         * @request PUT:/ecommerce-private/v1/transactional-emails/{id}
         */
        putTransactionalEmailsItem: (
            id: string,
            data: TransactionalEmailsTransactionalEmailWrite,
            params: RequestParams = {}
        ) =>
            this.request<TransactionalEmailsTransactionalEmailRead, void>({
                path: `/ecommerce-private/v1/transactional-emails/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags typologies
         * @name GetTypologiesCollection
         * @summary Retrieves the collection of typologies resources.
         * @request GET:/ecommerce-private/v1/typologies
         */
        getTypologiesCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<TypologiesTypologyRead[], any>({
                path: `/ecommerce-private/v1/typologies`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags typologies
         * @name GetTypologiesItem
         * @summary Retrieves a typologies resource.
         * @request GET:/ecommerce-private/v1/typologies/{id}
         */
        getTypologiesItem: (id: string, params: RequestParams = {}) =>
            this.request<TypologiesTypologyRead, void>({
                path: `/ecommerce-private/v1/typologies/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags UserConfiguration
         * @name GetUserConfigurationCollection
         * @summary Retrieves the collection of UserConfiguration resources.
         * @request GET:/ecommerce-private/v1/user-configurations
         */
        getUserConfigurationCollection: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<UserConfiguration[], any>({
                path: `/ecommerce-private/v1/user-configurations`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags UserConfiguration
         * @name PostUserConfigurationCollection
         * @summary Creates a UserConfiguration resource.
         * @request POST:/ecommerce-private/v1/user-configurations
         */
        postUserConfigurationCollection: (data: UserConfiguration, params: RequestParams = {}) =>
            this.request<UserConfiguration, void>({
                path: `/ecommerce-private/v1/user-configurations`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags User
         * @name SearchZendeskTicketsUserCollection
         * @summary Retrieves the collection of User resources.
         * @request GET:/ecommerce-private/v1/users/zendesk
         */
        searchZendeskTicketsUserCollection: (
            query?: {
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<User[], any>({
                path: `/ecommerce-private/v1/users/zendesk`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags User
         * @name GetUserItem
         * @summary Retrieves a User resource.
         * @request GET:/ecommerce-private/v1/users/{id}
         */
        getUserItem: (id: string, params: RequestParams = {}) =>
            this.request<User, void>({
                path: `/ecommerce-private/v1/users/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags User
         * @name DeleteUserItem
         * @summary Removes the User resource.
         * @request DELETE:/ecommerce-private/v1/users/{id}
         */
        deleteUserItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/users/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags User
         * @name PutUserItem
         * @summary Replaces the User resource.
         * @request PUT:/ecommerce-private/v1/users/{id}
         */
        putUserItem: (id: string, data: User, params: RequestParams = {}) =>
            this.request<User, void>({
                path: `/ecommerce-private/v1/users/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name GetWorkspaceCollection
         * @summary Retrieves the collection of Workspace resources.
         * @request GET:/ecommerce-private/v1/workspaces
         */
        getWorkspaceCollection: (
            query?: {
                'order[id]'?: 'asc' | 'desc';
                'order[code]'?: 'asc' | 'desc';
                'order[label]'?: 'asc' | 'desc';
                'order[beginOn]'?: 'asc' | 'desc';
                'order[finishOn]'?: 'asc' | 'desc';
                'order[previewOn]'?: 'asc' | 'desc';
                'order[createdAt]'?: 'asc' | 'desc';
                'order[updatedAt]'?: 'asc' | 'desc';
                'order[deletedAt]'?: 'asc' | 'desc';
                brand?: string;
                'beginOn[before]'?: string;
                'beginOn[strictly_before]'?: string;
                'beginOn[after]'?: string;
                'beginOn[strictly_after]'?: string;
                'finishOn[before]'?: string;
                'finishOn[strictly_before]'?: string;
                'finishOn[after]'?: string;
                'finishOn[strictly_after]'?: string;
                label?: string;
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 0
                 * @default 30
                 */
                itemsPerPage?: number;
                /** Enable or disable pagination */
                pagination?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<WorkspaceWorkspaceList[], any>({
                path: `/ecommerce-private/v1/workspaces`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name CreateFromPreviousWorkspaceCollection
         * @summary Creates a Workspace resource.
         * @request POST:/ecommerce-private/v1/workspaces/create-from-previous-one
         */
        createFromPreviousWorkspaceCollection: (data: WorkspaceWorkspaceCreationWrite, params: RequestParams = {}) =>
            this.request<WorkspaceWorkspaceRead, void>({
                path: `/ecommerce-private/v1/workspaces/create-from-previous-one`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name GetWorkspaceItem
         * @summary Retrieves a Workspace resource.
         * @request GET:/ecommerce-private/v1/workspaces/{id}
         */
        getWorkspaceItem: (id: string, params: RequestParams = {}) =>
            this.request<WorkspaceWorkspaceRead, void>({
                path: `/ecommerce-private/v1/workspaces/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name PatchWorkspaceItem
         * @summary Updates the Workspace resource.
         * @request PATCH:/ecommerce-private/v1/workspaces/{id}
         */
        patchWorkspaceItem: (id: string, data: any, params: RequestParams = {}) =>
            this.request<WorkspaceWorkspaceList, void>({
                path: `/ecommerce-private/v1/workspaces/${id}`,
                method: 'PATCH',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name DeleteWorkspaceItem
         * @summary Removes the Workspace resource.
         * @request DELETE:/ecommerce-private/v1/workspaces/{id}
         */
        deleteWorkspaceItem: (id: string, params: RequestParams = {}) =>
            this.request<void, void>({
                path: `/ecommerce-private/v1/workspaces/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name PutWorkspaceItem
         * @summary Replaces the Workspace resource.
         * @request PUT:/ecommerce-private/v1/workspaces/{id}
         */
        putWorkspaceItem: (id: string, data: WorkspaceWorkspaceWrite, params: RequestParams = {}) =>
            this.request<WorkspaceWorkspaceRead, void>({
                path: `/ecommerce-private/v1/workspaces/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name DuplicateWorkspaceItem
         * @summary Creates a Workspace resource.
         * @request POST:/ecommerce-private/v1/workspaces/{id}/duplicate
         */
        duplicateWorkspaceItem: (id: string, data: WorkspaceWorkspaceCreationWrite, params: RequestParams = {}) =>
            this.request<WorkspaceWorkspaceRead, void>({
                path: `/ecommerce-private/v1/workspaces/${id}/duplicate`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name DuplicatePageVersionsWorkspaceCollection
         * @summary Creates a Workspace resource.
         * @request POST:/ecommerce-private/v1/workspaces/{id}/duplicate-pages
         */
        duplicatePageVersionsWorkspaceCollection: (
            id: string,
            data: WorkspaceWorkspaceWrite,
            params: RequestParams = {}
        ) =>
            this.request<WorkspaceWorkspaceDuplicatePageVersions, void>({
                path: `/ecommerce-private/v1/workspaces/${id}/duplicate-pages`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Workspace
         * @name PublishWorkspaceItem
         * @summary Replaces the Workspace resource.
         * @request PUT:/ecommerce-private/v1/workspaces/{id}/publish
         */
        publishWorkspaceItem: (id: string, data: WorkspaceWorkspaceWrite, params: RequestParams = {}) =>
            this.request<WorkspaceWorkspaceRead, void>({
                path: `/ecommerce-private/v1/workspaces/${id}/publish`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags cms
         * @name GetAnalytics
         * @summary Get analytics
         * @request GET:/ecommerce-private/v1/analytics
         */
        getAnalytics: (
            query?: {
                /** Brand code */
                brand?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<Analytic, any>({
                path: `/ecommerce-private/v1/analytics`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name PutEvergreenMedia
         * @summary Put evergreen media
         * @request PUT:/ecommerce-private/v1/evergreen-media/{id}
         */
        putEvergreenMedia: (
            brand: string,
            id: string,
            query: {
                site: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<EvergreenMedia, any>({
                path: `/ecommerce-private/v1/evergreen-media/${id}`,
                method: 'PUT',
                query: query,
                format: 'json',
                ...params,
            }),
    };
}
