import React, { ReactNode } from 'react';
import { useLocation } from 'react-router';
import { Loader } from 'sezane-components';

import NotAuthorized from '@containers/notAuthorized';
import { useRightsContext } from '@contexts/RightsContext';
import { isOmsRoute, useAclOms } from '@utils/acl/useAclOms';
import useAcl from './useAcl';

type AuthorizedRouteProps = {
    children: ReactNode;
};

const AuthorizedRoute = ({ children }: AuthorizedRouteProps) => {
    const location = useLocation();
    const path = location.pathname;

    const { isPathAllowed } = useAcl();
    const { isPathAllowedOms } = useAclOms();
    const { isLoadingOmsRights } = useRightsContext();

    return (
        <>
            {isOmsRoute(path) ? (
                isLoadingOmsRights ? (
                    <Loader className="u-mt(lg)" size="xl" />
                ) : isPathAllowedOms(path) ? (
                    children
                ) : (
                    <NotAuthorized />
                )
            ) : isPathAllowed(path) ? (
                children
            ) : (
                <NotAuthorized />
            )}
        </>
    );
};

export default AuthorizedRoute;
