import { useQuery } from '@tanstack/react-query';
import React, { createContext, ReactNode, useContext } from 'react';

import omsApi from '@api/clients/oms';
import { OMS } from '@types';
import { getRightsList, type OmsRights } from '@utils/acl/omsRights';

interface RightsProviderProps {
    children: ReactNode;
    initialValues?: OMS.ListOfRights; // for testing purposes
}
const RightsContext = createContext({
    isLoadingOmsRights: false,
    rights: {} as ReturnType<typeof getRightsList>,
});

export const RightsProvider = ({ children, initialValues }: RightsProviderProps) => {
    const { data, isLoading: isLoadingOmsRights } = useQuery({
        queryKey: ['omsRights'],
        queryFn: async () => {
            const res = await omsApi.getUserRights();
            return res.json();
        },
    });

    const rights = getRightsList(((initialValues || data?.rights) as OmsRights[]) ?? []);

    return (
        <RightsContext.Provider value={{ isLoadingOmsRights: initialValues ? false : isLoadingOmsRights, rights }}>
            {children}
        </RightsContext.Provider>
    );
};
export const useRightsContext = () => useContext(RightsContext);
