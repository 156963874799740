import { useRightsContext } from '@contexts/RightsContext';
import { ROUTES_ACCESS_RIGHTS_OMS } from '@utils/acl/config';
import { Paths, ReturnedMappedRights } from '../hooks/useOmsRights';

export const isOmsRoute = (path: string) => Object.keys(ROUTES_ACCESS_RIGHTS_OMS).includes(path);

export const useAclOms = () => {
    const { rights } = useRightsContext();

    const isGranted = (accessRight: Paths<ReturnedMappedRights>) => {
        const [group, key] = accessRight.split('.');
        const sectionRights = rights[group as keyof typeof rights];
        if (sectionRights) {
            return !!sectionRights[key as keyof typeof sectionRights];
        }
        return false;
    };

    const isPathAllowedOms = (path: string) => {
        const accessRight = ROUTES_ACCESS_RIGHTS_OMS[path];

        if (!accessRight) {
            if (process.env.NODE_ENV === 'development' && path !== '/') {
                // eslint-disable-next-line no-console
                console.log('No access rights defined for path', path);
            }
            return true;
        }

        return isGranted(accessRight);
    };

    return { isPathAllowedOms };
};
