import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import routesNames from '@config/routesNames';

const Page = lazy(
    () => import(/* webpackChunkName: "logisticCountrySettings" */ '@containers/logisticCountrySettings')
);

export default [<Route path={routesNames.LOGISTIC_COUNTRY_SETTINGS} component={Page} strict={true} key="page" />];
