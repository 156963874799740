import ecommerceApiClient from '@api/clients/ecommerce';
import { RadioInput, SegmentedRadioButtons } from '@components/form/input/Radio';
import { useQuery } from '@tanstack/react-query';
import { zipObject } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Case } from 'sezane-components';
import useResizeObserver from 'use-resize-observer';
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLegend,
    VictoryLine,
    VictoryTheme,
    VictoryThemeDefinition,
    VictoryVoronoiContainer,
} from 'victory';

type Point = {
    hour: number;
    value: number;
    // label: string;
};

const THEME: VictoryThemeDefinition = {
    ...VictoryTheme.material,
    axis: {
        style: {
            ...VictoryTheme.material.axis?.style,
            grid: {
                strokeWidth: 0,
            },
        },
    },
};
const BAR_WIDTH = 12;
const brands = ['sezane', 'octobre'];

export default function Analytics() {
    const [selectedBrand, setSelectedBrand] = useState<'sezane' | 'octobre'>('sezane');
    const intl = useIntl();
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
    const { data } = useQuery(['analytics', selectedBrand], () =>
        ecommerceApiClient.getAnalytics({ brand: selectedBrand })
    );
    const pageViews: Point[] = [];
    const sessions: Point[] = [];

    data?.data?.rows?.forEach(item => {
        const hour = item.dimensionValues![0].value;
        if (hour === '(other)') {
            return;
        }

        pageViews.push({
            hour: Number(hour),
            value: Number(item.metricValues![0].value),
            // label: intl.formatMessage(
            //     { id: 'dashboard.analytics.tooltip.page_views' },
            //     { hour, value: item.metricValues![0].value }
            // ),
        });
        sessions.push({
            hour: Number(hour),
            value: Number(item.metricValues![1].value),
            // label: intl.formatMessage(
            //     { id: 'dashboard.analytics.tooltip.sessions' },
            //     { hour, value: item.metricValues![1].value }
            // ),
        });
    });
    const maxima = [pageViews, sessions].map(dataset => Math.max(...dataset.map(d => d.value)));

    return (
        <Case size="sm" color="primary" className="u-h(100)" innerClassName="u-h(100)">
            <div className="u-d(flex) u-jc(space-between) u-ai(center)">
                <a
                    href={data?.data.url}
                    className="u-pt(xs) u-pb(xs) u-h3 u-tt(uppercase) u-fw(bolder) u-c(info)"
                    style={{ textDecoration: 'none' }}
                >
                    <FormattedMessage id="dashboard.analytics.title" />
                </a>
                <SegmentedRadioButtons
                    className="c-segmented-control--lg"
                    listClassName="u-mh(30p)"
                    buttons={zipObject(
                        brands,
                        brands.map(brand => (
                            <RadioInput
                                key={brand}
                                id={`analytics-${brand}`}
                                name={brand}
                                label={`dashboard.turnover.brands.${brand}`}
                                value={brand}
                                checked={selectedBrand === brand}
                                onChange={e => setSelectedBrand(e.currentTarget.value)}
                                segmented
                            />
                        ))
                    )}
                />
            </div>
            <div ref={ref} className="u-h(100)">
                <VictoryChart
                    height={height}
                    width={width}
                    padding={{ top: 20, bottom: 35, left: 70, right: 60 }}
                    theme={THEME}
                    containerComponent={
                        <VictoryVoronoiContainer
                            labels={({ datum }) =>
                                intl.formatMessage(
                                    {
                                        id:
                                            datum.childName === 'sessions'
                                                ? 'dashboard.analytics.tooltip.sessions'
                                                : 'dashboard.analytics.tooltip.page_views',
                                    },
                                    { hour: datum.hour, value: datum.value }
                                )
                            }
                        />
                    }
                >
                    <VictoryLegend
                        x={width * 0.7}
                        y={0}
                        data={[
                            {
                                name: intl.formatMessage({ id: 'dashboard.analytics.page_views' }),
                                symbol: { fill: 'orange', type: 'square' },
                            },
                            {
                                name: intl.formatMessage({ id: 'dashboard.analytics.sessions' }),
                                symbol: { fill: 'gold', type: 'square' },
                            },
                        ]}
                    />
                    <VictoryAxis tickFormat={hour => `${hour}h`} />
                    <VictoryAxis
                        dependentAxis
                        style={{ axis: { stroke: 'orange' } }}
                        tickValues={[0.25, 0.5, 0.75, 1]}
                        tickFormat={t => `${intl.formatNumber(t * maxima[0], { maximumFractionDigits: 0 })}`}
                    />
                    <VictoryAxis
                        dependentAxis
                        style={{ axis: { stroke: 'gold' } }}
                        tickValues={[0.25, 0.5, 0.75, 1]}
                        tickFormat={t => `${intl.formatNumber(t * maxima[1], { maximumFractionDigits: 0 })}`}
                        orientation="right"
                    />
                    <VictoryGroup offset={BAR_WIDTH}>
                        <VictoryBar
                            name="pageViews"
                            data={pageViews}
                            x="hour"
                            y={datum => datum.value / maxima[0]}
                            style={{
                                data: { fill: 'orange' },
                            }}
                            alignment="start"
                            barWidth={BAR_WIDTH}
                        />
                        <VictoryLine
                            name="sessions"
                            data={sessions}
                            x="hour"
                            y={datum => datum.value / maxima[1]}
                            style={{
                                data: { stroke: 'gold', strokeWidth: 3, width: '100%' },
                            }}
                        />
                    </VictoryGroup>
                </VictoryChart>
            </div>
        </Case>
    );
}
