import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Icon, MenuCategory, MenuItem, Sidebar } from 'sezane-components';

import { setMenuFavorites as setMenuFavoritesAction } from '@actions/ui';
import menu from '@config/menu';
import routesNames from '@config/routesNames';
import { useRightsContext } from '@contexts/RightsContext';
import { useAcl } from '@utils/acl';
import { isOmsRoute, useAclOms } from '@utils/acl/useAclOms';
import { useAppDispatch, useAppSelector } from '@utils/hooks/redux';
import FavoriteWrapper from './FavoriteWrapper';

interface FavoritesSectionProps extends MenuCategory {
    childrens: MenuItem[];
}

const Menu = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(state => state.notifications);
    const favorites = useAppSelector(state => state.ui.menuFavorites || []);
    const intl = useIntl();
    const { isGranted, isPathAllowed } = useAcl();
    const { isPathAllowedOms } = useAclOms();
    const { isLoadingOmsRights } = useRightsContext();
    const setFavorites = (menuFavorites: MenuItem[]) => dispatch(setMenuFavoritesAction(menuFavorites));
    const favoritesSection: FavoritesSectionProps = { title: 'sidebar.favorites', icon: 'star', childrens: favorites };

    const menuWithAclCheck = useMemo(
        () =>
            menu
                .map(section => ({
                    ...section,
                    childrens: section.childrens?.filter(item => {
                        // @ts-expect-error
                        if (item.accessRight) {
                            // @ts-expect-error
                            return isGranted(item.accessRight);
                        }
                        if (item.route) {
                            return isOmsRoute(item.route) ? isPathAllowedOms(item.route) : isPathAllowed(item.route);
                        }
                        return false;
                    }),
                }))
                .filter(section => (section.childrens ? section.childrens?.length > 0 : true)),
        [menu, isPathAllowed, isPathAllowedOms, isLoadingOmsRights]
    );

    const handleFavorite = useCallback(
        (item: MenuItem) => {
            favorites.some(f => f.title === item.title)
                ? setFavorites(favorites.filter(f => f.title !== item.title))
                : setFavorites([...favorites, item]);
        },
        [favorites]
    );
    const displayedMenu = useMemo(
        () =>
            favoritesSection.childrens?.length > 0
                ? [...menuWithAclCheck.slice(0, 1), favoritesSection, ...menuWithAclCheck.slice(1)]
                : menuWithAclCheck,
        [favorites, menuWithAclCheck]
    );
    return (
        <Sidebar
            expanded
            menu={displayedMenu}
            renderItem={item => {
                return item.route ? (
                    <FavoriteWrapper
                        item={item}
                        handleFavorite={handleFavorite}
                        selected={favorites.some(fav => fav.title === item.title)}
                    >
                        <NavLink
                            to={{
                                pathname: item.route ? item.route : routesNames.DASHBOARD,
                                search: item.search,
                            }}
                            className="c-nav__link c-nav__link--secondary"
                        >
                            {intl.formatMessage({ id: item.title })}
                            {item.notifications && notifications[item.notifications] ? (
                                <span className="c-notification u-ml(xxs)">{notifications[item.notifications]}</span>
                            ) : null}
                            {item.icon && <Icon className="c-icon--monospace c-nav__icon u-ml(xxs)" icon={item.icon} />}
                        </NavLink>
                    </FavoriteWrapper>
                ) : (
                    <FavoriteWrapper item={item} handleFavorite={handleFavorite}>
                        <a
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                            className="c-nav__link c-nav__link--secondary"
                        >
                            {intl.formatMessage({ id: item.title })}
                            {item.icon && (
                                <Icon className="c-icon--monospace c-nav__icon" icon={item.icon} style={{ top: 0 }} />
                            )}
                        </a>
                    </FavoriteWrapper>
                );
            }}
            renderCategory={category => {
                if (category.route) {
                    return (
                        <NavLink
                            to={{
                                pathname: category.route,
                            }}
                            className="c-nav__link c-nav__link--primary"
                        >
                            {intl.formatMessage({ id: category.title })}
                        </NavLink>
                    );
                }

                return false;
            }}
            notifications={notifications}
        />
    );
};

export default Menu;
