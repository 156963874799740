import { RootState } from '@reducers';
import { Middleware } from 'redux';

import { TYPES as CATALOG_PRODUCT_TYPES } from '@actions/catalogProducts/get';
import { TYPES as SHIPPING_MODES_TYPES } from '@actions/oms/shippingModes/get';
import { TYPES as MOBILE_CONTENTS } from '@actions/mobileContents/get';
import { CatalogProducts, OMS, MobileContentsMobileContentRead } from '@types';

export const dataFixer: Middleware<{}, RootState> = store => next => action => {
    // We need to fix the data for the catalog products PIM data since empty values are serialized as array instead of object
    if (action.type === CATALOG_PRODUCT_TYPES.GET_SUCCESS) {
        const product: CatalogProducts = action.payload.entities['catalog-product'][action.payload.result];

        if (product) {
            product.variants?.forEach(variant => {
                if (variant?.pimWrapper && Array.isArray((variant?.pimWrapper as any).crossedOutPrices)) {
                    (variant.pimWrapper as any).crossedOutPrices = {};
                }
                if (variant?.selectedStoreVariant && Array.isArray(variant?.selectedStoreVariant.translations)) {
                    variant.selectedStoreVariant.translations = {};
                }
            });
        }
    }

    if (action.type === SHIPPING_MODES_TYPES.GET_SUCCESS) {
        const shippingMode: OMS.ShippingMode = action.payload.entities['shipping-mode'][action.payload.result];

        if (shippingMode) {
            shippingMode.shippingModeAttributesGroups?.forEach(shippingModeAttributesGroup => {
                if (Array.isArray(shippingModeAttributesGroup.translations)) {
                    shippingModeAttributesGroup.translations = {};
                }
            });
        }
    }

    if (action.type === MOBILE_CONTENTS.GET_SUCCESS) {
        const mobileContent: MobileContentsMobileContentRead =
            action.payload.entities['mobile-content'][action.payload.result];

        if (mobileContent) {
            mobileContent.mobileContentVersions?.forEach(mobileContentVersion => {
                mobileContentVersion.slides?.forEach(slide => {
                    if (Array.isArray(slide.translations)) {
                        slide.translations = {};
                    }
                });
            });
        }
    }

    return next(action);
};

export default dataFixer;
